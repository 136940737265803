import { Lock, LockOpen } from '@mui/icons-material';
import styles from './SelectRestricted.module.scss';
import { useState } from 'react';

export const SelectRestricted = ({ value, onChange }) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const chooseValue = (value) => {
    onChange && onChange(value);
    setExpanded(false);
  };

  return (
    <div
      className={styles.select}
      data-restricted={value ? 'restricted' : ''}
      data-expanded={expanded ? 'expanded' : ''}
    >
      <div className={styles.input} onClick={toggleExpanded}>
        <div className={styles.icon}>{value ? <Lock /> : <LockOpen />}</div>
        <div className={styles.title}>
          {value ? 'Закрытое мероприятие' : 'Общедоступное мероприятие'}
        </div>
      </div>
      <div className={styles.options}>
        <div
          className={styles.option}
          data-restricted="restricted"
          onClick={() => chooseValue(true)}
        >
          <div className={styles.icon}>
            <Lock />
          </div>
          <div className={styles.details}>
            <div className={styles.title}>Закрытое мероприятие</div>
            <div className={styles.description}>
              Мероприятие доступно только для резидентов клуба
            </div>
          </div>
        </div>
        <div className={styles.option} onClick={() => chooseValue(false)}>
          <div className={styles.icon}>
            <LockOpen />
          </div>
          <div className={styles.details}>
            <div className={styles.title}>Общедоступное мероприятие</div>
            <div className={styles.description}>
              Мероприятие опубликуется в календаре событий платформы и будет
              видно другим пользователям
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
