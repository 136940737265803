import { useState } from 'react';
import styles from './SimpleTable.module.scss';
import { Button } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

const LIMIT = 5;

export const SimpleTable = ({ headers, rows }) => {
  const [expanded, setExpanded] = useState(false);
  const isExpandable = rows.length > LIMIT;

  const expand = () => {
    setExpanded(true);
  };

  const getRows = () => {
    return expanded ? rows : rows.slice(0, LIMIT);
  };

  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr>
            {headers.map((title) => (
              <th key={title}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getRows().map((row) => (
            <tr key={row[0] + row[1]}>
              {row.map((value) => (
                <td key={value}>{value}</td>
              ))}
            </tr>
          ))}
          {!rows.length && (
            <tr>
              <td colSpan={2}>Ни одного события не найдено.</td>
            </tr>
          )}
          {!expanded && isExpandable && (
            <tr>
              <td colSpan={2}>
                <Button
                  onClick={expand}
                  variant="text"
                  startIcon={<KeyboardArrowDown />}
                  color="primary"
                  sx={{ color: '#42A5F5' }}
                >
                  Показать еще
                </Button>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
