import { each } from 'lodash';
import { utils, writeFile } from 'sheetjs-style';

const TH_NAMES = `abcdefghigklmnopqrstuvwxyz`
  .toUpperCase()
  .split('')
  .map((letter) => `${letter}1`);

export const useDownloadExcel = () => {
  return (headers = [], rows = [], name = 'table', colWidths = null) => {
    const code = `
      <table>
        <thead>
          <tr>
            ${headers.map((v) => `<th><strong>${v}</strong></th>`).join('')}
          </tr>
        </thead>
        <tbody>
          ${rows
            .map(
              (row) => `
            <tr>
              ${row.map((v) => `<td>${v}</td>`).join('')}
            </tr>
          `
            )
            .join('')}
        </tbody>
      </table>
    `;
    const wrapper = document.createElement('div');
    wrapper.innerHTML = code;
    const wb = utils.table_to_book(wrapper.children[0]);

    const sheet = wb.Sheets.Sheet1;

    sheet['!cols'] = colWidths
      ? colWidths.map((width) => ({ wch: width }))
      : Array(headers.length).fill({ wch: 30 });

    const headerStyle = {
      font: { bold: true }, // Red bold text
      fill: { fgColor: { rgb: 'dddddd' } }, // Yellow background
    };

    TH_NAMES.forEach((TH_NAME) => {
      const cell = sheet[TH_NAME];
      if (!cell) return;
      cell.s = headerStyle;
    });

    writeFile(wb, `${name}.xlsx`);
  };
};
