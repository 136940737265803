import { useState } from 'react';
import styles from './Section.module.scss';
import { KeyboardArrowDown, KeyboardArrowRight } from '@mui/icons-material';
import { SelectItem } from '@ui/SelectItem/SelectItem';

export const Section = ({ title, color, items, isExpanded, onCheck }) => {
  const [expanded, setExpanded] = useState(false);

  const isExp = expanded || isExpanded;

  return (
    <div className={styles.section} data-expanded={isExp ? 'expanded' : ''}>
      <div className={styles.header} onClick={() => setExpanded(!expanded)}>
        <div className={styles.expandIcon}>
          {isExp ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </div>
        <div className={styles.color} style={{ background: color }}></div>
        <div className={styles.title}>{title}</div>
      </div>
      {isExp && (
        <div className={styles.items}>
          {items.map((item) => (
            <SelectItem
              key={item.title}
              title={item.title}
              checked={false}
              onCheck={() => onCheck(item.title)}
              padded={true}
            />
          ))}
        </div>
      )}
    </div>
  );
};
