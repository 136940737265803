import { SectionHeader } from '../../ResidentModal/SectionHeader/SectionHeader';
import styles from './Waiting.module.scss';

export const Waiting = () => {
  return (
    <div className={styles.waiting}>
      <SectionHeader
        title="Этот раздел для верификации резидентов"
        description="Чтобы получить доступ к ресурсам вашего сообщества, резидент должен указать его у себя в профиле. 
Проверьте данные пользователей и подключите ваших резидентов к сообществу на платформе."
      />
    </div>
  );
};
