import { Close } from '@mui/icons-material';
import styles from './AppTag.module.scss';

export const AppTag = ({
  label,
  onRemove,
  onClick,
  marginless,
  isInactive,
}) => {
  const tagClicked = (ev) => {
    ev.stopPropagation();
    onRemove && onRemove();
    onClick && onClick(ev);
  };

  return (
    <div
      className={styles.tag}
      onClick={tagClicked}
      data-marginless={marginless ? 'marginless' : ''}
      data-inactive={isInactive ? 'inactive' : ''}
    >
      {onRemove && (
        <div className={styles.button}>
          <Close />
        </div>
      )}
      <div className={styles.label}>{label}</div>
    </div>
  );
};
