import axios from 'axios';

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {},
  withCredentials: true,
  credentials: 'include',
  sameSite: 'none',
  secure: true,
});
