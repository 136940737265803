import { useContext, useEffect, useState } from 'react';
import { ComingSoon } from '../ComingSoon/ComingSoon';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import styles from './Events.module.scss';
import { api } from '@lib/api';
import { ResidentContext } from '../../../context/ResidentContext';
import { CircularProgress, Tooltip } from '@mui/material';
import { Input } from '@ui/Input/Input';
import {
  CardGiftcard,
  CreditCard,
  FilterList,
  Search,
  Sort,
} from '@mui/icons-material';
import { Dropdown } from '@ui/Dropdown/Dropdown';

export const Events = ({ isEmpty }) => {
  const { data } = useContext(ResidentContext);
  const [events, setEvents] = useState([]);
  const [search, setSearch] = useState('');
  const [payment, setPayment] = useState('all');
  const [sort, setSort] = useState('new');
  const [isLoading, setIsLoading] = useState(true);

  const PAYMENT_TITLES = {
    all: 'Оплата',
    free: 'Бесплатные',
    paid: 'Оплачено',
    unpaid: 'Не оплачено',
  };

  const fetchEvents = async () => {
    if (isEmpty) return;
    setIsLoading(true);
    const response = await api.get(`/dashboard/club/events/`, {
      params: {
        userId: data.userId,
        search,
        payment,
        sort,
      },
    });
    setEvents(response.data);
    setIsLoading(false);
  };

  const paymentChanged = (newValue) => {
    setPayment(newValue);
  };

  const icons = {
    free: (
      <Tooltip title="Бесплатное мероприятие">
        <CardGiftcard />
      </Tooltip>
    ),
    unpaid: (
      <Tooltip title="Не оплачено">
        <CreditCard />
      </Tooltip>
    ),
    paid: (
      <Tooltip title="Оплачено">
        <CreditCard />
      </Tooltip>
    ),
  };

  const filterEvents = () => {
    if (payment === 'all') return events;
    return events.filter((event) => event.payment === payment);
  };

  useEffect(() => {
    fetchEvents();
  }, [search, payment, sort]);

  const filteredEvents = filterEvents();

  return (
    <div className={styles.events}>
      <SectionHeader
        title="Мероприятия"
        description="Статистика посещенных мероприятий сообщества"
      />
      {isEmpty ? (
        <ComingSoon />
      ) : (
        <>
          <div className={styles.search}>
            <Input
              placeholder="Поиск"
              icon={Search}
              value={search}
              onChange={(ev) => setSearch(ev.target.value)}
              style={{ width: '200px' }}
            />
          </div>
          <div className={styles.filters}>
            <div className={styles.payment}>
              <Dropdown
                title={payment === 'all' ? 'Оплата' : PAYMENT_TITLES[payment]}
                value={payment}
                onChange={paymentChanged}
                icon={CreditCard}
                options={[
                  { title: 'Все', value: 'all' },
                  { title: 'Бесплатные', value: 'free' },
                  { title: 'Оплачено', value: 'paid' },
                  { title: 'Не оплачено', value: 'unpaid' },
                ]}
              />
            </div>
            <div className={styles.sort}>
              <Dropdown
                icon={FilterList}
                title={sort === 'new' ? 'Сначала новые' : 'Сначала старые'}
                value={sort}
                onChange={setSort}
                options={[
                  { title: 'Сначала новые', value: 'new' },
                  { title: 'Сначала старые', value: 'old' },
                ]}
              />
            </div>
          </div>
          <div className={styles.list}>
            {/* {isLoading && <CircularProgress />} */}
            {filteredEvents.map((event) => (
              <div className={styles.event} key={event.id}>
                <div className={styles.header}>
                  <div className={styles.title}>{event.title}</div>
                  <div className={styles.dates}>{event.dates}</div>
                </div>
                <div className={styles.footer}>
                  <div className={styles.comment}>
                    Комментарий: {event.comment}
                  </div>
                  <div className={styles.icon} data-status={event.payment}>
                    {icons[event.payment]}
                  </div>
                </div>
              </div>
            ))}
            {!isLoading && !filteredEvents.length && (
              <div className={styles.empty}>
                Ни одного мероприятия не найдено.
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
