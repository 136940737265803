import { useEffect, useState } from 'react';
import { AppSelect } from '../AppSelect/AppSelect';
import { api } from '@lib/api';

export const UniversitySelect = ({
  value,
  onChange,
  includeId,
  cityId,
  error = false,
  disabled,
}) => {
  const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const changed = (items) => {
    setSelected(items);
  };

  const fetchDefaultOptions = async () => {
    const options = await fetchOptions('');
    setDefaultOptions(options);
  };

  const fetchOptions = async (q = '') => {
    setIsLoading(true);
    const response = await api.get(`/profile/educations/universities/`, {
      params: {
        // q,
        city_id: cityId,
      },
    });
    setIsLoading(false);
    return response.data.universities.map((val) => ({
      label: val.split('|')[0],
      value: val.split('|')[1],
    }));
  };

  useEffect(() => {
    fetchDefaultOptions();
    setSelected(null);
  }, [cityId]);

  useEffect(() => {
    if (value) {
      setSelected({ label: value.split('|')[0], value: value.split('|')[1] });
    }
  }, []);

  useEffect(() => {
    if (!selected) {
      onChange(null);
      return;
    }
    onChange(`${selected.label}|${selected.value}`);
  }, [selected]);

  return (
    <AppSelect
      isAsync
      value={selected}
      onChange={changed}
      placeholder={'Выберите университет'}
      defaultOptions={defaultOptions}
      loadOptions={fetchOptions}
      isLoading={isLoading}
      isClearable={true}
      error={error}
      isDisabled={disabled}
    />
  );
};
