import { useEffect, useState } from 'react';
import { AppSelect } from '../AppSelect/AppSelect';
import { api } from '@lib/api';

export const FacultySelect = ({ value, onChange, universityId, disabled }) => {
  // const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const changed = (item) => {
    if (!item) return onChange(null);
    onChange && onChange(`${item.label}|${item.value}`);
  };

  const fetchDefaultOptions = async () => {
    const options = await fetchOptions('');
    setDefaultOptions(options);
  };

  const fetchOptions = async (q = '') => {
    setIsLoading(true);
    const response = await api.get(`/profile/educations/faculties/`, {
      params: {
        // q,
        university_id: universityId,
      },
    });
    setIsLoading(false);
    return response.data.faculties.map((val) => ({
      label: val.split('|')[0],
      value: val.split('|')[1],
    }));
  };

  useEffect(() => {
    fetchDefaultOptions();
  }, [universityId]);

  return (
    <AppSelect
      isAsync
      value={
        value
          ? { label: value?.split('|')[0], value: value?.split('|')[1] }
          : null
      }
      onChange={changed}
      placeholder={'Выберите факультет'}
      defaultOptions={defaultOptions}
      loadOptions={fetchOptions}
      isLoading={isLoading}
      isClearable={true}
      isDisabled={disabled}
    />
  );
};
