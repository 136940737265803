import { Field } from '@ui/Field/Field';
import { TextInput } from '@ui/TextInput/TextInput';
import styles from './Personal.module.scss';
import { FormControl } from '@mui/material';
import { useContext, useState } from 'react';
import { ResidentContext } from '../../../../context/ResidentContext';
import { CitySelect } from '@ui/CitySelect/CitySelect';
import { DateInput } from '@ui/DateInput/DateInput';
import { ContactsSelector } from '../../../../../../components/ui/ContactsSelector/ContactsSelector';
import { ValidationContext } from '../../../../context/ValidationContext';
import { FieldError } from '../../../../../../components/ui/FieldError/FieldError';
import { ResidentAvatar } from '../../../ResidentAvatar/ResidentAvatar';

export const Personal = () => {
  const { data, update } = useContext(ResidentContext);
  const { errors, touched, touch } = useContext(ValidationContext);
  const [visitingError, setVisitingError] = useState(false);

  const common = data.common;

  const updateField = (name, value) => {
    update(name, value);
  };

  return (
    <div className={styles.personal}>
      <div className={styles.avatar}>
        <ResidentAvatar avatar={data.avatar} />
      </div>
      <div className={styles.name}>
        <section>
          <Field label="Имя">
            <FormControl fullWidth>
              <TextInput
                value={common.about.firstName}
                error={touched.firstName && !!errors.firstName}
                onBlur={() => touch('firstName')}
                onChange={(ev) =>
                  updateField('common.about.firstName', ev.target.value)
                }
                disabled={!!data.userId}
              />
              {touched.firstName && !!errors.firstName && (
                <FieldError>{errors.firstName}</FieldError>
              )}
            </FormControl>
          </Field>
        </section>
        <section>
          <Field label="Фамилия">
            <FormControl fullWidth>
              <TextInput
                value={common.about.lastName}
                onBlur={() => touch('lastName')}
                error={touched.lastName && !!errors.lastName}
                onChange={(ev) =>
                  updateField('common.about.lastName', ev.target.value)
                }
                disabled={!!data.userId}
              />
              {touched.lastName && !!errors.lastName && (
                <FieldError>{errors.lastName}</FieldError>
              )}
            </FormControl>
          </Field>
        </section>
      </div>
      <div className={styles.details}>
        <section>
          <Field label="Дата рождения">
            <div className={styles.bdate}>
              <DateInput
                max="current"
                value={common.personal.bdate}
                onChange={(ev) =>
                  updateField('common.personal.bdate', ev.target.value)
                }
                onBlur={() => touch('bdate')}
                disabled={!!data.userId}
                error={touched.bdate && !!errors.bdate}
              />
            </div>
            {touched.bdate && !!errors.bdate && (
              <FieldError>{errors.bdate}</FieldError>
            )}
          </Field>
        </section>
        <section>
          <Field label="Город проживания">
            <CitySelect
              value={common.personal.city}
              onChange={(val) => updateField('common.personal.city', val)}
              disabled={!!data.userId}
            />
          </Field>
        </section>
      </div>

      <Field label="Бывает в городах">
        <CitySelect
          isMultiple
          value={common.personal.visiting}
          onChange={(val) => {
            if (val.length <= 5) {
              setVisitingError(false);
              updateField('common.personal.visiting', val);
            } else {
              setVisitingError(true);
            }
          }}
          disabled={!!data.userId}
        />
        {visitingError && (
          <FieldError>Максимальное количество городов — 5</FieldError>
        )}
      </Field>

      <Field label="О себе">
        <FormControl fullWidth>
          <TextInput
            value={common.about.about}
            onBlur={() => touch('about')}
            error={touched.about && !!errors.about}
            onChange={(ev) =>
              updateField('common.about.about', ev.target.value)
            }
            disabled={!!data.userId}
          />
          {touched.about && !!errors.about && (
            <FieldError>{errors.about}</FieldError>
          )}
        </FormControl>
      </Field>

      <Field label="Телефон">
        <FormControl fullWidth>
          <ContactsSelector
            values={data.common.contacts.phones}
            onChange={(values) => {
              touch('phones');
              update('common.contacts.phones', values);
            }}
            error={touched.phones && !!errors.phones}
            lockFirst={!!data.userId}
            type="phone"
            validate={(val) => {
              return /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/.test(
                val
              );
            }}
          />
          {touched.phones && !!errors.phones && (
            <FieldError>{errors.phones}</FieldError>
          )}
        </FormControl>
      </Field>
      <Field label="Почта">
        <ContactsSelector
          values={data.common.contacts.emails}
          onChange={(values) => {
            touch('emails');
            update('common.contacts.emails', values);
          }}
          lockFirst={!!data.userId}
          type="email"
          error={touched.emails && !!errors.emails}
          validate={(val) => {
            return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              val
            );
          }}
        />
        {touched.emails && !!errors.emails && (
          <FieldError>{errors.emails}</FieldError>
        )}
      </Field>
      <Field label="Телеграм">
        <ContactsSelector
          values={data.common.contacts.telegrams}
          onChange={(values) => update('common.contacts.telegrams', values)}
          prefix="@"
          lockFirst={!!data.userId}
          type="telegram"
          validate={(val) => {
            return /^(?=\w{5,32}\b)[a-zA-Z0-9]+(?:_[a-zA-Z0-9]+)*$/.test(val);
          }}
        />
      </Field>
      <Field label="Сайт">
        <ContactsSelector
          values={data.common.contacts.websites}
          onChange={(values) => {
            touch('websites');
            update('common.contacts.websites', values);
          }}
          type="email"
          lockFirst={!!data.userId}
          error={touched.websites && !!errors.websites}
          validate={(website) => {
            return !(
              !/^(https?:\/\/)?[-a-zA-Zа-яА-Я0-9@:%._+~#=]{1,256}\.[a-zA-Zа-яА-Я0-9()]{1,6}([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/i.test(
                website
              ) || website.length > 78
            );
          }}
        />
        {touched.websites && !!errors.websites && (
          <FieldError>{errors.websites}</FieldError>
        )}
      </Field>
    </div>
  );
};
