import { useContext } from 'react';
import styles from './Hello.module.scss';
import { SurveyContext } from '../../context/SurveyContext';
import { useSelector } from 'react-redux';
import { Field } from '@ui/Field/Field';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { DateInput } from '@ui/DateInput/DateInput';
import { TextInput } from '@ui/TextInput/TextInput';

export const Hello = () => {
  const { data, update } = useContext(SurveyContext);
  const { user } = useSelector((state) => state.auth);
  const { community } = useSelector((state) => state.community);

  return (
    <div className={styles.section}>
      <div className="bubble">
        <p>
          Добрый день, {data.firstName} {data.lastName}!
        </p>
        <p>
          Меня зовут {user.name} я интервьюер от бизнес-сообщества «
          {community.title}» в котором вы резидент.{' '}
        </p>
        <p>
          Запускается новый проект, который позволит решить множество
          бизнес-задач в одно касание, и чтобы он был максимально полезным, мне
          нужно пообщаться с Вами. Нам необходимо ответить на ряд вопросов,
          которые я задам. Это займет не более 15-20 минут.{' '}
        </p>
      </div>
      <div className="bubble">
        <Field label="12. Вам удобно сейчас говорить?">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              <FormControlLabel
                value="yes"
                control={<Radio checked={data.confirm === true} />}
                label="Да"
                onClick={() => update('confirm', true)}
              />
              <FormControlLabel
                value="no"
                control={<Radio checked={data.confirm === false} />}
                label="Нет"
                onClick={() => update('confirm', false)}
              />
            </RadioGroup>
          </FormControl>
        </Field>
      </div>
      {data.confirm === false && (
        <div className="bubble">
          <Field label="13. Когда вам лучше перезвонить?">
            <FormControl fullWidth>
              <DateInput
                value={data.callDate}
                onChange={(ev) => update('callDate', ev.target.value)}
              />
            </FormControl>
          </Field>
          <Field label="14. Время">
            <FormControl fullWidth>
              <TextInput
                value={data.callTime}
                onChange={(ev) => update('callTime', ev.target.value)}
              />
            </FormControl>
          </Field>
        </div>
      )}
    </div>
  );
};
