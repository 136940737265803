import { matchSorter } from 'match-sorter';
import { ResourceSection } from '@ui/ResourceSection/ResourceSection';
import styles from './TreeResource.module.scss';
import { cloneDeep } from 'lodash';
import { FooterButton } from '@ui/FooterButton/FooterButton';
import { useState } from 'react';
import { EditSectionModal } from '../../EditTreeDict/EditSectionModal/EditSectionModal';
import { ToolbarButton } from '@ui/ToolbarButton/ToolbarButton';
import { Delete } from '@mui/icons-material';
import { RemoveConfirmation } from '../../RemoveConfirmation/RemoveConfirmation';

export const TreeResource = ({ type, sections, onUpdate, search }) => {
  const [editSection, setEditSection] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  const [checked, setChecked] = useState([]);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const saveSection = (data) => {
    const ss = [...sections];
    const index = ss.findIndex(
      (section) => section.title === editSection.title
    );
    if (index < 0) return;
    ss.splice(index, 1, { ...editSection, ...data });
    onUpdate(ss);
    setEditSection(null);
    setIsAdd(false);
  };

  const addSection = (data) => {
    onUpdate([...sections, { ...data, items: [] }]);
    setIsAdd(false);
    setEditSection(null);
  };

  const updateSection = (section, data) => {
    const items = [...sections];
    const index = items.indexOf(section);
    if (index < 0) return;
    items.splice(index, 1, { ...section, ...data });
  };

  const getSections = () => {
    if (!search) return sections;
    const items = sections
      .map((section) => {
        let s = cloneDeep(section);
        const items = matchSorter(s.items, search, { keys: ['title'] });
        s.items = items;
        return s;
      })
      .filter((section) => !!section.items.length);
    return items;
  };

  const updateItems = (section, items) => {
    const updatingSections = [...sections];
    const index = updatingSections.indexOf(section);
    if (index < 0) return;
    updatingSections.splice(index, 1, { ...section, items });
    onUpdate(updatingSections);
  };

  const itemChecked = (item) => {
    setChecked((prev) => {
      const items = [...prev];
      const index = items.indexOf(item);
      if (index > -1) {
        items.splice(index, 1);
      } else {
        items.push(item);
      }
      return items;
    });
  };

  const removeItems = () => {
    setShowRemoveConfirmation(false);
    const updatingSections = sections.map((section) => {
      const data = { ...section };
      data.items = data.items.filter((item) => !checked.includes(item));
      return data;
    });
    onUpdate(updatingSections);
    setChecked([]);
  };

  const removeSection = (section) => {
    onUpdate(sections.filter((sec) => sec.title !== section.title));
  };

  return (
    <>
      <div className={styles.resource}>
        {!!checked.length && (
          <div className={styles.toolbar}>
            <ToolbarButton
              icon={<Delete />}
              onClick={() => setShowRemoveConfirmation(true)}
            >
              Удалить из справочника
            </ToolbarButton>
          </div>
        )}
        <div className={styles.list}>
          {getSections().map((section) => (
            <ResourceSection
              key={section.title}
              isExpanded={!!search}
              color={section.color}
              title={section.title}
              onUpdate={(data) => updateSection(section, data)}
              onItems={(newItems) => updateItems(section, newItems)}
              items={section.items}
              onEdit={() => setEditSection(section)}
              onCheck={itemChecked}
              checked={checked}
              onRemove={() => removeSection(section)}
            />
          ))}
        </div>
        <div className={styles.add}>
          <div className={styles.button}>
            <FooterButton
              title="Добавить раздел"
              type="add"
              onClick={() => setIsAdd(true)}
            />
          </div>
        </div>
        {(editSection || isAdd) && (
          <EditSectionModal
            section={editSection}
            type={type}
            onSave={isAdd ? addSection : saveSection}
            onCancel={() => {
              setEditSection(null);
              setIsAdd(false);
            }}
            onUpdate={(items) => updateItems(editSection, items)}
          />
        )}
      </div>
      {showRemoveConfirmation && (
        <RemoveConfirmation
          type="spheres"
          onDecline={() => setShowRemoveConfirmation(false)}
          onAccept={removeItems}
        />
      )}
    </>
  );
};
