import { useState } from 'react';
import styles from './SidebarItem.module.scss';

export const SidebarItem = ({
  leftIcon = null,
  rightIcon = null,
  title = '',
  isContainer = false,
  isSub = false,
  isActive = false,
  onClick,
  children,
}) => {
  const [expanded, setExpanded] = useState(false);

  const LeftIcon = leftIcon;
  const RightIcon = rightIcon;

  const headerClicked = () => {
    onClick && onClick();
    if (isContainer) {
      setExpanded((prev) => !prev);
    }
  };

  return (
    <div
      className={styles.item}
      data-active={isActive ? 'active' : ''}
      data-sub={isSub ? 'sub' : ''}
      data-expanded={expanded ? 'expanded' : 'collapsed'}
    >
      <div className={styles.header} onClick={headerClicked}>
        {LeftIcon && (
          <div className={styles.leftIcon}>
            <LeftIcon
              fontSize={isSub ? 'small' : 'medium'}
              color={isActive || isSub || expanded ? 'primary' : 'disabled'}
            />
          </div>
        )}
        <div className={styles.title}>{title}</div>
        {RightIcon && (
          <div className={styles.rightIcon}>
            <RightIcon fontSize={isSub ? 'small' : 'medium'} color="disabled" />
          </div>
        )}
      </div>
      <div className={styles.subItems}>{children}</div>
    </div>
  );
};
