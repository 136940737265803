import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    progress: false,
    spheres: [],
    competences: [],
    lobbies: [],
    fields: [],
  },
  reducers: {
    setProgress(state, action) {
      state.progress = action.payload;
    },
    setSpheres(state, action) {
      state.spheres = action.payload;
    },
    setCompetences(state, action) {
      state.competences = action.payload;
    },
    setLobbies(state, action) {
      state.lobbies = action.payload;
    },
    setFields(state, action) {
      state.fields = action.payload;
    },
  },
});

export const startProgress = () => {
  return async (dispatch) => {
    dispatch(commonSlice.actions.setProgress(true));
    setTimeout(() => {
      dispatch(commonSlice.actions.setProgress(false));
    }, 500);
  };
};

export const fetchDicts = () => async (dispatch) => {
  const response = await api.get(`/dashboard/dicts/`);
  dispatch(
    commonSlice.actions.setSpheres(
      response.data.dicts.spheres.spheres.map((item) => item.title)
    )
  );
  dispatch(
    commonSlice.actions.setCompetences(response.data.dicts.competences.sections)
  );
  dispatch(
    commonSlice.actions.setLobbies(response.data.dicts.lobbies.sections)
  );
};

export const fetchFields = () => async (dispatch) => {
  const response = await api.get(`/profile/job-fields/`);
  dispatch(commonSlice.actions.setFields(response.data));
};
