import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import omit from 'lodash/omit';
import { startProgress } from '@store/common';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    events: [],
    filters: {
      search: '',
      sortBy: 'new',
      city: '',
      format: '',
      begin: '',
      end: '',
    },
    page: 1,
    limit: 20,
    total: 0,
    loading: false,
    event: null,
  },
  reducers: {
    setFilters(state, action) {
      state.filters = action.payload;
    },
    setEvents(state, action) {
      state.events = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setLimit(state, action) {
      state.limit = action.payload;
    },
    setTotal(state, action) {
      state.total = action.payload;
    },
    setLoading(state, action) {
      state.loading = !!action.payload;
    },
    setEvent(state, action) {
      state.event = action.payload;
    },
  },
});

export const updateEvent = (data) => {
  return async (dispatch, getState) => {
    const state = getState();
    const response = await api.put(
      `/dashboard/calendar/events/${state.calendar.event.id}`,
      data
    );
    const event = response.data.event;
    dispatch(calendarSlice.actions.setEvent(event));
    const events = state.calendar.events.map((e) => {
      if (event.id !== e.id) return e;
      return {
        ...event,
      };
    });
    dispatch(calendarSlice.actions.setEvents(events));
  };
};

export const fetchEvents = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(calendarSlice.actions.setLoading(true));
    const filters = state.calendar.filters;
    const response = await api.get(`/dashboard/calendar/events/`, {
      params: {
        status: filters.status,
        search: filters.search || null,
        sortBy: filters.sortBy || null,
        city: filters.city !== 'all' ? filters.city : null,
        format: filters.format !== 'all' ? filters.format : null,
        begin: filters.begin || null,
        end: filters.end || null,
        page: state.calendar.page,
        limit: state.calendar.limit,
      },
    });
    dispatch(calendarSlice.actions.setEvents(response.data.events));
    dispatch(calendarSlice.actions.setTotal(response.data.total));
    dispatch(calendarSlice.actions.setLoading(false));
  };
};

export const syncEvents = (accessToken) => {
  return async (dispatch, getState) => {
    const state = getState();
    const filters = state.calendar.filters;
    dispatch(calendarSlice.actions.setLoading(true));
    const params = {
      status: filters.status,
      search: filters.search || null,
      sortBy: filters.sortBy || null,
      city: filters.city !== 'all' ? filters.city : null,
      format: filters.format !== 'all' ? filters.format : null,
      begin: filters.begin || null,
      end: filters.end || null,
      page: state.calendar.page + 1,
      limit: state.calendar.limit,
    };
    const response = await api.post(`/dashboard/calendar/sync-events/`, {
      accessToken,
      fetchDto: params,
    });
    const data = response.data;
    let events = state.calendar.events.map((event) => ({ ...event }));
    for (const item of data.created) {
      events.unshift(item);
    }
    for (const item of data.updated) {
      const event = events.find((ev) => ev.id === item.id);
      if (!event) {
        events.unshift(item);
        continue;
      }
      Object.assign(event, omit(item, 'id'));
    }
    for (const item of data.removed) {
      events = events.filter((event) => event.id !== item.id);
    }
    dispatch(calendarSlice.actions.setEvents(events));
    dispatch(calendarSlice.actions.setLoading(false));
  };
};

export const nextPage = () => {
  return async (dispatch, getState) => {
    const state = getState();
    dispatch(calendarSlice.actions.setLoading(true));
    const filters = state.calendar.filters;
    const params = {
      status: filters.status,
      search: filters.search || null,
      sortBy: filters.sortBy || null,
      city: filters.city !== 'all' ? filters.city : null,
      format: filters.format !== 'all' ? filters.format : null,
      begin: filters.begin || null,
      end: filters.end || null,
      page: state.calendar.page + 1,
      limit: state.calendar.limit,
    };
    const response = await api.get(`/dashboard/calendar/events/`, {
      params,
    });
    dispatch(calendarSlice.actions.setPage(response.data.page));
    dispatch(
      calendarSlice.actions.setEvents([
        ...state.calendar.events,
        ...response.data.events,
      ])
    );
    dispatch(calendarSlice.actions.setTotal(response.data.total));
    dispatch(calendarSlice.actions.setLoading(false));
  };
};

export const fetchEvent = (eventId) => {
  return async (dispatch) => {
    dispatch(startProgress());
    const response = await api.get(`/dashboard/calendar/events/${eventId}`);
    const event = response.data.event;
    dispatch(calendarSlice.actions.setEvent(event));
  };
};
