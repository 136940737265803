import { useEffect, useState } from 'react';
import styles from './ManagementTable.module.scss';
import { CheckBox } from '@ui/CheckBox/CheckBox';
import { Link } from 'react-router-dom';
import { sortBy } from 'lodash';

export const ManagementTable = ({ tab, residents, onCheck }) => {
  const [checked, setChecked] = useState([]);
  const [checkAll, setCheckAll] = useState(false);

  const toggleChecked = (resident) => {
    setChecked((prev) => {
      const items = [...prev];
      const index = items.indexOf(resident);
      if (index > -1) {
        items.splice(index, 1);
      } else {
        items.push(resident);
      }
      return items;
    });
  };

  const getResidents = () => {
    const items = sortBy(residents, 'cardId').reverse();
    return items;
  };

  useEffect(() => {
    onCheck(checked);
  }, [checked]);

  useEffect(() => {
    if (checkAll) {
      setChecked([...residents]);
    } else {
      setChecked([]);
    }
  }, [checkAll]);

  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr>
            <th>
              <CheckBox
                checked={checkAll}
                onChange={() => setCheckAll((prev) => !prev)}
              />
            </th>
            <th>Имя Фамилия</th>
            <th>Почта</th>
            <th>Телефон</th>
            <th>Телеграм</th>
          </tr>
        </thead>
        <tbody>
          {getResidents().map((resident) => (
            <tr key={resident.cardId}>
              <td>
                <CheckBox
                  checked={checked.includes(resident)}
                  onChange={() => toggleChecked(resident)}
                />
              </td>
              <td>
                <Link
                  to={`/club/residents/${
                    resident.cardId
                  }/?redirectTo=${encodeURIComponent(
                    `/club/residents/management/#${tab}`
                  )}`}
                >
                  {resident.name}
                </Link>
              </td>
              <td>{resident.email}</td>
              <td>{resident.phone}</td>
              <td>{resident.telegram || 'Не указано'}</td>
            </tr>
          ))}
          {!residents.length && (
            <tr>
              <td colSpan={5}>Ни одного резидента не найдено.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
