import { SidebarTab } from '../SidebarTab/SidebarTab';
import styles from './SidebarTabs.module.scss';

export const SidebarTabs = ({
  tabs,
  value,
  onChange,
  isSub,
  borderless = false,
}) => {
  return (
    <div className={styles.tabs}>
      {tabs.map((tab) => (
        <SidebarTab
          isSub={isSub}
          key={tab.name}
          title={tab.title}
          icon={tab.icon}
          count={tab.count}
          isActive={value === tab.name ? 'active' : ''}
          isExpanded={tab.isExpanded}
          items={tab.items}
          onClick={(name) => onChange(name || tab.name)}
          value={value}
          borderless={borderless}
          isInvalid={tab.isInvalid}
        />
      ))}
    </div>
  );
};
