import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '../../../store/common';

export const quickLinksSlice = createSlice({
  name: 'quickLinks',
  initialState: {
    links: [],
  },
  reducers: {
    setLinks(state, action) {
      state.links = action.payload;
    },
    addLink(state, action) {
      state.links.push(action.payload);
    },
  },
});

export const fetchQuickLinks = () => async (dispatch) => {
  const response = await api.get('/dashboard/quick-links');
  dispatch(quickLinksSlice.actions.setLinks(response.data));
};

export const createQuickLink = (title, url) => async (dispatch) => {
  const response = await api.post('/dashboard/quick-links', {
    title,
    url,
  });
  dispatch(quickLinksSlice.actions.addLink(response.data));
};

export const deleteQuickLink = (linkId) => async (dispatch) => {
  await api.delete(`/dashboard/quick-links/${linkId}`);
  dispatch(fetchQuickLinks());
};
