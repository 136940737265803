import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import styles from './GoogleOptionsModal.module.scss';
import {
  CalendarToday,
  Close,
  Login,
  Logout,
  Person,
} from '@mui/icons-material';
import { useContext, useEffect, useState } from 'react';
import { api } from '@lib/calendar';
import { useDispatch, useSelector } from 'react-redux';
import { updateOptions } from '../../../auth/store/auth';
import { GoogleUserContext } from '@context/GoogleUserContext';
import { SidebarTabs } from '@ui/SidebarTabs/SidebarTabs';
import { GoogleCalendar } from './GoogleCalendar/GoogleCalendar';
import { GoogleAuth } from './GoogleAuth/GoogleAuth';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../../hooks/usePageTitle';

export const GoogleOptionsModal = () => {
  const [tab, setTab] = useState('auth');
  const [tabs, setTabs] = useState([
    { name: 'auth', title: 'Аккаунт', icon: Person },
  ]);
  const { user, login, logout, credentials } = useContext(GoogleUserContext);
  const navigate = useNavigate();
  const pageTitle = usePageTitle();

  pageTitle('Управление календарем Google');

  const modalClosed = () => {
    navigate('/calendar/');
  };

  useEffect(() => {
    if (!user) {
      setTab('auth');
      setTabs([{ name: 'auth', title: 'Аккаунт', icon: Person }]);
    } else {
      setTab('calendar');
      setTabs([
        { name: 'calendar', title: 'Календарь', icon: CalendarToday },
        { name: 'auth', title: 'Аккаунт', icon: Person },
      ]);
    }
  }, [user]);

  return (
    <Dialog open={true} className="wide-dialog">
      <DialogTitle>
        Управление календарем Google
        <IconButton
          aria-label="close"
          onClick={modalClosed}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '750px' }} className="paddingless">
        <div className={styles.modal}>
          <div className={styles.tabs}>
            <SidebarTabs tabs={tabs} value={tab} onChange={setTab} />
          </div>
          <div className={styles.content}>
            {tab === 'calendar' && <GoogleCalendar onClose={modalClosed} />}
            {tab === 'auth' && <GoogleAuth />}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
