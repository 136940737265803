import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import AsyncSelect from 'react-select/async';

import styles from './AppSelect.module.scss';
import { selectStyles } from './select-styles';

export const AppSelect = ({
  isAsync = false,
  isCreatable = false,
  menuPortal = null,
  overlay = 'overlay',
  noOptionsMessage = 'Вариантов не найдено',
  error = false,
  ...props
}) => {
  const selectProps = {
    ...props,
    styles: selectStyles({ error }),
    className: styles.selectComponent,
    noOptionsMessage: () => noOptionsMessage,
  };

  if (overlay) {
    menuPortal = 'overlay';
  }

  if (menuPortal) {
    selectProps.menuPortalTarget = document.getElementById(menuPortal);
  }

  return (
    <div className={styles.select}>
      {isCreatable ? (
        <CreatableSelect {...selectProps} />
      ) : isAsync ? (
        <AsyncSelect {...selectProps} />
      ) : (
        <Select {...selectProps} />
      )}
    </div>
  );
};
