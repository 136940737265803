import styles from './About.module.scss';

export const About = () => {
  return (
    <div className={styles.section}>
      <div className="bubble">
        Сейчас готовится к запуску цифровая бизнес платформа Лидерпорт.
      </div>
      <div className="bubble">
        <p>
          На текущий момент на площадке формируются 10 сервисов, сейчас я их
          перечислю:
        </p>
        <ol>
          <li>Краудфандинг и краундинвестинг, </li>
          <li>Цифровой двойник производственных процессов</li>
          <li>Тендер интеграция, </li>
          <li>Суперсервис “Школа практики предпринимательства”,</li>
          <li>Подбор верифицированного партнера, </li>
          <li>Маркетплейс кадровых услуг, </li>
          <li>Маркетплейс менторов,</li>
          <li>Ресурсная карта, </li>
          <li>Календарь событий, </li>
          <li>Системные инициативы.</li>
        </ol>
      </div>
      <div className="bubble">
        Сейчас мы собираем данные для сервиса «Ресурсная карта», который
        является самым востребованным и актуальным сервисом Клуба. Ресурсная
        карта – это по своей сути оцифрованный нетворкинг, который в удобной
        форме предоставит новые возможности и компетенции т.е. это сервис,
        позволяющий Вам эффективно использовать все ресурсы всех членов клуба
      </div>
    </div>
  );
};
