import styles from './Input.module.scss';

export const Input = ({
  icon,
  type = 'text',
  name = '',
  placeholder,
  value,
  onChange,
  ...props
}) => {
  const Icon = icon;

  return (
    <div className={styles.input}>
      {Icon && (
        <div className={styles.icon}>
          <Icon fontSize="small" color="disabled" />
        </div>
      )}
      <div className={styles.element}>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          {...props}
        />
      </div>
    </div>
  );
};
