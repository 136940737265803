import { Add, Error } from '@mui/icons-material';
import styles from './AddMoreButton.module.scss';
import { createPortal } from 'react-dom';
import { useEffect, useRef, useState } from 'react';
import { AppTooltip } from '../../../../../../../components/ui/AppTooltip/AppTooltip';

export const AddMoreButton = ({ onClick }) => {
  return (
    <div className={styles.add} onClick={onClick}>
      <AppTooltip
        className={styles.info}
        text={`Дополнительный актуальный запрос не отобразится в профиле
              пользователя и будет виден только вам`}
      >
        <Error />
      </AppTooltip>
      <div className={styles.label}>Добавить еще</div>
      <div className={styles.plus}>
        <Add />
      </div>
    </div>
  );
};
