import { EditProject } from '@ui/EditProject/EditProject';
import { useContext, useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import styles from './Projects.module.scss';
import { ResidentContext } from '../../../../context/ResidentContext';
import { ValidationContext } from '../../../../context/ValidationContext';
import { MAX_RESIDENT_PROJECTS } from '../../../../../../data/constants';
import { AddMoreFooter } from '../../../../../../components/ui/AddMoreFooter/AddMoreFooter';
import { negativeUid } from '../../../../../../util/util';

export const Projects = () => {
  const { data, update } = useContext(ResidentContext);
  const { errors, touched } = useContext(ValidationContext);
  const [projects, setProjects] = useState(
    data?.common?.projects?.length ? data?.common?.projects : []
  );

  const canCreate = projects.length < MAX_RESIDENT_PROJECTS;

  const addProject = () => {
    if (data.userId) return;
    setProjects((prev) => [
      ...prev,
      {
        id: negativeUid(),
      },
    ]);
  };

  const updateProject = (project, data) => {
    if (data.userId) return;
    setProjects((prev) => {
      const items = [...prev];
      const index = items.indexOf(project);
      items.splice(index, 1, { ...data, id: project.id });
      return items;
    });
  };

  const removeProject = (project) => {
    if (data.userId) return;
    setProjects((prev) => {
      const items = [...prev];
      const index = items.indexOf(project);
      items.splice(index, 1);
      return items;
    });
  };

  const hasMore = () => {
    return projects.length > 1;
  };

  const getErrors = (i) => {
    const err = {};
    const prefix = `projects.${i}.`;
    Object.keys(errors)
      .filter((key) => key.startsWith(prefix))
      .forEach((key) => {
        err[key.slice(prefix.length)] = errors[key];
      });
    return err;
  };

  useEffect(() => {
    update('common.projects', projects);
  }, [projects]);

  useEffect(() => {
    if (!projects.length) addProject();
  }, []);

  return (
    <div className={styles.projects}>
      <div className={styles.list}>
        {projects.map((project, i) => (
          <div className={styles.item} key={project.id}>
            <div className={styles.select}>
              <EditProject
                data={project}
                onChange={(data) => updateProject(project, data)}
                errors={getErrors(i)}
                formSubmitted={touched.submit}
                disabled={!!data.userId}
              />
            </div>
            {hasMore() && !data.userId && (
              <div className={styles.remove}>
                <IconButton onClick={() => removeProject(project)}>
                  <Delete />
                </IconButton>
              </div>
            )}
          </div>
        ))}
      </div>
      {canCreate && !data.userId && <AddMoreFooter onClick={addProject} />}
    </div>
  );
};
