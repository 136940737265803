import { FormControl } from '@mui/material';
import styles from './Profile.module.scss';
import { Field } from '@ui/Field/Field';
import { TextInput } from '@ui/TextInput/TextInput';
import { CitySelect } from '@ui/CitySelect/CitySelect';
import { DateInput } from '@ui/DateInput/DateInput';
import ReactInputMask from 'react-input-mask';
import { useContext } from 'react';
import { SurveyContext } from '../../context/SurveyContext';
import { SpheresSelector } from '@features/club/components/SpheresSelector/SpheresSelector';
import moment from 'moment';

export const Profile = () => {
  const { data, update } = useContext(SurveyContext);

  const date = moment().format('DD.MM.YYYY');

  return (
    <div className={styles.section}>
      <Field label="1. Имя">
        <FormControl fullWidth>
          <TextInput
            value={data.firstName}
            onChange={(ev) => update('firstName', ev.target.value)}
          />
        </FormControl>
      </Field>
      <Field label="2. Фамилия">
        <FormControl fullWidth>
          <TextInput
            value={data.lastName}
            onChange={(ev) => update('lastName', ev.target.value)}
          />
        </FormControl>
      </Field>
      <Field label="3. Дата рождения">
        <FormControl fullWidth>
          <DateInput
            value={data.bdate}
            onChange={(ev) => update('bdate', ev.target.value)}
          />
        </FormControl>
      </Field>
      <Field label="4. Город проживания">
        <FormControl fullWidth>
          <CitySelect
            value={data.city}
            onChange={(city) => update('city', city)}
          />
        </FormControl>
      </Field>
      <Field label="5. Телефон">
        <FormControl fullWidth>
          <ReactInputMask
            mask="+7 (999) 999-99-99"
            disabled={false}
            maskChar=" "
            value={data.phone}
            onChange={(ev) => update('phone', ev.target.value)}
          >
            {() => <TextInput />}
          </ReactInputMask>
        </FormControl>
      </Field>
      <Field label="6. Почта">
        <FormControl fullWidth>
          <TextInput
            value={data.email}
            onChange={(ev) => update('email', ev.target.value)}
          />
        </FormControl>
      </Field>
      <Field label="7. Телеграм">
        <FormControl fullWidth>
          <TextInput
            value={data.telegram}
            onChange={(ev) => update('telegram', ev.target.value)}
          />
        </FormControl>
      </Field>
      <Field label="8. Должность">
        <FormControl fullWidth>
          <TextInput
            value={data.position}
            onChange={(ev) => update('position', ev.target.value)}
          />
        </FormControl>
      </Field>
      <Field label="9. Название компании">
        <FormControl fullWidth>
          <TextInput
            value={data.company}
            onChange={(ev) => update('company', ev.target.value)}
          />
        </FormControl>
      </Field>
      <Field label="10. Отрасль">
        <FormControl fullWidth>
          <SpheresSelector
            value={data.field}
            isMultiple={false}
            onChange={(sel) => update('field', sel)}
          />
        </FormControl>
      </Field>
      <Field label="11. Дата интервью">
        <FormControl fullWidth>
          <DateInput
            value={data.interviewDate || date}
            onChange={(ev) => update('interviewDate', ev.target.value)}
          />
        </FormControl>
      </Field>
    </div>
  );
};
