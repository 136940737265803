import { useEffect, useRef, useState } from 'react';
import { AppTag } from '../../AppTag/AppTag';
import styles from './Input.module.scss';
import { KeyboardArrowDown } from '@mui/icons-material';

export const Input = ({
  tags,
  onRemove,
  onClick,
  value,
  onChange,
  onPopTag,
  onCreate,
  focus,
  hideInput,
}) => {
  const inputRef = useRef();
  const [deleted, setDeleted] = useState(false);

  const inputClicked = (ev) => {
    ev.stopPropagation();
    onClick(ev);
    if (hideInput) return;
    inputRef.current.focus();
  };

  const inputKeyUp = (ev) => {
    if (hideInput) return;
    if (ev.which === 13) {
      onCreate && onCreate();
    }
    if (ev.which === 8 && !value) {
      if (deleted) {
        onPopTag && onPopTag();
      } else {
        setDeleted(true);
      }
    } else {
      setDeleted(false);
    }
  };

  useEffect(() => {
    if (hideInput) return;
    inputRef.current.focus();
  }, [focus]);

  return (
    <div
      className={styles.input + ' editable-tag-list__input'}
      onClick={inputClicked}
    >
      <div className={styles.tags}>
        {tags.map((tag) => (
          <AppTag label={tag} key={tag} onRemove={() => onRemove(tag)} />
        ))}
        {!hideInput && (
          <div className={styles.text}>
            <input
              type="text"
              onClick={inputClicked}
              ref={inputRef}
              value={value}
              onChange={onChange}
              onKeyUp={inputKeyUp}
              maxLength={30}
            />
          </div>
        )}
      </div>
      {!hideInput && (
        <div className={styles.icon}>
          <KeyboardArrowDown />
        </div>
      )}
    </div>
  );
};
