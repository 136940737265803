import { Button, MenuItem, Select, TextField } from '@mui/material';
import styles from './EventInfo.module.scss';
import moment from 'moment';
import { SelectCity } from '@ui/SelectCity/SelectCity';
import { useEffect, useState } from 'react';
import { TextInput } from '@ui/TextInput/TextInput';
import { SelectRestricted } from '../SelectRestricted/SelectRestricted';
import { Google } from '@mui/icons-material';
import { isEqual, pick } from 'lodash';

export const EventInfo = ({ event, onClose, onUpdate }) => {
  const [city, setCity] = useState(
    event.city
      ? {
          label: event.city,
          value: event.city,
        }
      : null
  );
  const [format, setFormat] = useState(event.isOnline ? 'Онлайн' : 'Оффлайн');
  const [type, setType] = useState(event.type || '');
  const [restricted, setRestricted] = useState(!!event.restricted);
  const [conditions, setConditions] = useState(event.conditions || 'unknown');
  const [price, setPrice] = useState(event.price || 100);
  const [link, setLink] = useState(event.link);

  const start = 'с ' + moment(event.start).format('DD.MM.YYYY');
  const finish = 'по ' + moment(event.finish).format('DD.MM.YYYY');

  const openEditEvent = () => {
    // window.open(event.editLink, '_blank');
  };

  useEffect(() => {
    // onUpdate &&
    //   onUpdate({
    //     city: city.value,
    //     format,
    //     type,
    //     restricted,
    //     conditions,
    //     price,
    //     link,
    //   });
    const oldData = pick(event, [
      'city',
      'format',
      'type',
      'restricted',
      'conditions',
      'price',
      'link',
    ]);
    const newData = {
      city: city.value,
      format,
      type,
      restricted,
      conditions,
      price,
      link,
    };
    if (isEqual(oldData, newData)) return;
    onUpdate(newData);
  }, [city, format, type, restricted, conditions, price, link]);

  return (
    <div className={styles.event}>
      <div className={styles.section}>
        <div className={styles.header}>
          <div className={styles.title}>Данные из Google-календаря</div>
          <div className={styles.description}>
            Название, дата и краткое описание события редактируется в вашем
            Google-календаре. Изменения синхронизируются с платформой
            автоматически
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.title}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled
              fullWidth
              value={event.title}
              placeholder="Название события"
            />
          </div>
          <div className={styles.location}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              disabled
              fullWidth
              value={event.place}
              placeholder="Место проведения"
            />
          </div>
          <div className={styles.dates}>
            <section>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                fullWidth
                value={start}
              />
            </section>
            <section>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled
                fullWidth
                value={finish}
              />
            </section>
          </div>
          <div className={styles.description}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              multiline
              disabled
              fullWidth
              value={event.description}
              rows={5}
              placeholder="Описание"
            />
          </div>
          <div className={styles.editButton}>
            <Button
              startIcon={<Google />}
              color="primary"
              variant="text"
              size="small"
              onClick={openEditEvent}
            >
              Редактировать
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.header}>
          <div className={styles.title}>Дополнительная информация</div>
          <div className={styles.description}>
            Укажите важные детали о предстоящем событии
          </div>
        </div>
        <div className={styles.form}>
          <div className={styles.city}>
            <SelectCity value={city} onChange={setCity} />
          </div>
          <div className={styles.format}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={format}
              onChange={(ev) => setFormat(ev.target.value)}
              fullWidth
            >
              <MenuItem value={'unknown'}>Формат</MenuItem>
              <MenuItem value={'Онлайн'}>Онлайн</MenuItem>
              <MenuItem value={'Оффлайн'}>Оффлайн</MenuItem>
            </Select>
          </div>
          <div className={styles.type}>
            <div className={styles.input}>
              <TextInput
                id="outlined-basic"
                variant="outlined"
                fullWidth
                placeholder="Тип события"
                value={type}
                onChange={(ev) => setType(ev.target.value)}
              />
            </div>
            <div className={styles.description}>
              Укажите тип мероприятия. Например, это может быть митап,
              бизнес‑завтрак
            </div>
          </div>
          <div className={styles.restricted}>
            <SelectRestricted value={restricted} onChange={setRestricted} />
          </div>
          <div className={styles.conditions}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={conditions}
              onChange={(ev) => setConditions(ev.target.value)}
              fullWidth
            >
              <MenuItem value={'unknown'}>Условия участия</MenuItem>
              <MenuItem value={'free'}>Свободный вход</MenuItem>
              <MenuItem value={'register-free'}>
                Предварительная регистрация, бесплатно
              </MenuItem>
              <MenuItem value={'register-pay'}>
                Предварительная регистрация, платно
              </MenuItem>
            </Select>
          </div>
          {conditions === 'register-pay' && (
            <div className={styles.conditions}>
              <div className={styles.input}>
                <TextInput
                  id="outlined-basic"
                  variant="outlined"
                  fullWidth
                  placeholder="Стоимость участия"
                  value={price}
                  onChange={(ev) => setPrice(ev.target.value)}
                />
              </div>
              <div className={styles.description}>
                Укажите стоимость участия в рублях
              </div>
            </div>
          )}
          <div className={styles.link}>
            <div className={styles.input}>
              <TextInput
                id="outlined-basic"
                variant="outlined"
                fullWidth
                placeholder="Ссылка"
                value={link}
                onChange={(ev) => setLink(ev.target.value)}
              />
            </div>
            <div className={styles.description}>
              Добавьте ссылку, если это внешнее событие. Например, ПМЭФ
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
