import { useSelector } from 'react-redux';
import styles from './Fields.module.scss';
import { Field } from '@ui/Field/Field';
import { CheckItems } from '@ui/CheckItems/CheckItems';
import { useContext } from 'react';
import { SurveyContext } from '@features/survey/context/SurveyContext';
import { AppSelect } from '@ui/AppSelect/AppSelect';
import { FormControl } from '@mui/material';
import { TextInput } from '@ui/TextInput/TextInput';
import { useGroupItems } from '../../../../hooks/useGroupSpheres';

export const Fields = () => {
  const { data, update } = useContext(SurveyContext);
  const { spheres } = useSelector((state) => state.common);
  const { regular, other } = useGroupItems(
    spheres,
    data.spheres?.filter((sphere) => !!sphere) || []
  );

  const [regularItems, otherItems] = [regular, other].map((items) =>
    items.map((item) => ({ label: item, value: item }))
  );

  const spheresChanged = (items, extraItems) => {
    update('spheres', [...items.map((item) => item.value), ...extraItems]);
  };

  const spheresOptions = spheres.map((val) => ({ label: val, value: val }));

  return (
    <div className={styles.section}>
      <div className={styles.mainFields}>
        <div className="bubble">
          15. Начну с перечня популярных экономических отраслей, а Вы,
          пожалуйста, подтвердите те из них, в которых Вы разбираетесь или
          имеете компетенции:
        </div>
        <CheckItems
          options={spheresOptions}
          checked={regular}
          onChange={(items) =>
            spheresChanged(
              items.map((item) => ({ label: item, value: item })),
              other
            )
          }
        />
      </div>

      <Field label="Другие отрасли">
        <AppSelect
          isMulti
          isCreatable
          placeholder="Введите название другой отрасли"
          formatCreateLabel={(val) => `Добавить отрасль «${val}»`}
          noOptionsMessage="Введите название отрасли"
          value={otherItems}
          isClearable={true}
          onChange={(items) => spheresChanged(items, regular)}
        />
      </Field>

      <Field label="16. Дополнительные комментарии респондента">
        <FormControl fullWidth>
          <TextInput
            value={data.spheresComment}
            onChange={(ev) => update('spheresComment', ev.target.value)}
          />
        </FormControl>
      </Field>

      <div className="bubble">
        17. Спасибо за ответ. Вы выбрали следующие отрасли (перечислить
        выбранные). Скажите пожалуйста, какую отрасль из перечисленных, вы бы
        отметили как основную?
      </div>
      <CheckItems
        options={[...regularItems, ...otherItems]}
        isSingle
        checked={[data.mainField]}
        onChange={(vals) => {
          update('mainField', vals[0]);
        }}
      />
    </div>
  );
};
