import { Heading } from '../../layouts/Heading/Heading';
import styles from './Index.module.scss';
import { CalendarToday, Group, LocalFireDepartment } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Page } from '../../layouts/Page/Page';
import { LinkCard } from '@ui/LinkCard/LinkCard';
import { startProgress } from '../../store/common';
import { Navigate } from 'react-router-dom';
import { usePageTitle } from '../../hooks/usePageTitle';
import { useEffect } from 'react';

export const IndexPage = () => {
  const community = useSelector((state) => state.community.community);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const pageTitle = usePageTitle();

  const systemClicked = async () => {
    dispatch(startProgress());
  };

  useEffect(() => {
    pageTitle();
  }, []);

  if (!user.isManager && user.isInterviewer) {
    return <Navigate to="/survey/" />;
  }

  return (
    <Page transparent={true}>
      <div className={styles.index}>
        <Heading level={1} title={`${community.title} — Панель управления`} />
        <Heading level={3} title="Сервисы платформы Лидерпорт" />
        <div className={styles.section}>
          <LinkCard icon={Group} title="Ресурсная карта" url="/club/" />
          <LinkCard
            icon={CalendarToday}
            title="Календарь событий"
            url="/calendar/"
          />
        </div>
        {/* <Heading level={3} title={`${community.title} — сервисы`} />
        <div className={styles.section}>
          Ни одного сервиса не добавлено.
          <LinkCard
            icon={LocalFireDepartment}
            title="Сервисы сообщества"
            onClick={systemClicked}
          />
        </div> */}
      </div>
    </Page>
  );
};
