import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import styles from './ResourcesModal.module.scss';
import {
  Business,
  BusinessCenter,
  Close,
  Insights,
  PieChart,
  Search,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { SidebarTabs } from '@ui/SidebarTabs/SidebarTabs';
import { EditPlain } from './EditPlain/EditPlain';
import { EditTree } from './EditTree/EditTree';
import { Input } from '@ui/Input/Input';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { api } from '@lib/api';
import { AppButton } from '@ui/AppButton/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDicts as fetchDictsAction } from '@store/common';
import { ConfirmModal } from '@ui/ConfirmModal/ConfirmModal';
import { usePageTitle } from '../../../../hooks/usePageTitle';
import { TopLevelSelect } from '../TopLevelSelect/TopLevelSelect';
import { capitalize } from 'lodash';
import { LobbiesDict } from './LobbiesDict/LobbiesDict';

export const ResourcesModal = () => {
  const params = useParams();
  const [tab, setTab] = useState(params.type);
  const [innerTab, setInnerTab] = useState(0);
  const [search, setSearch] = useState('');
  const [dicts, setDicts] = useState({
    spheres: [],
    otherSpheres: [],
    competences: [],
    lobbies: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [isClearConfirm, setIsClearConfirm] = useState(false);
  const dispatch = useDispatch();
  const { options } = useSelector((state) => state.auth);
  const pageTitle = usePageTitle();
  const [moveType, setMoveType] = useState(null);
  const [movePosition, setMovePosition] = useState(null);

  pageTitle('Справочники');

  const tabs = [
    {
      name: 'spheres',
      icon: BusinessCenter,
      title: 'Сферы',
      count: options?.brand?.newSpheres?.length,
    },
    {
      name: 'competences',
      icon: PieChart,
      title: 'Навыки',
      count: options?.brand?.newCompetences?.length,
    },
    {
      name: 'lobbies',
      icon: Insights,
      title: 'Контакты',
      count: options?.brand?.newLobbies?.length,
    },
  ];

  const fetchDicts = async (withoutLoading = false) => {
    !withoutLoading && setIsLoading(true);
    const response = await api.get('/dashboard/dicts/');
    setDicts(response.data.dicts);
    setIsLoading(false);
  };

  const clearDict = async () => {
    setIsClearConfirm(false);
    setIsLoading(true);
    await api.delete(`/dashboard/dicts/`);
    await fetchDicts();
  };

  const modalClosed = () => {
    navigate('/club/');
    pageTitle('Ресурсная карта');
  };

  const saveDict = async () => {
    setIsLoading(true);
    await api.put(`/dashboard/dicts/`, dicts);
    setIsLoading(false);
    dispatch(fetchDictsAction());
    navigate('/club/');
  };

  const spheresUpdated = (items, isOther = false) => {
    if (isOther) {
      setDicts((prev) => ({
        ...prev,
        otherSpheres: items,
      }));
      return;
    }
    setDicts((prev) => ({
      ...prev,
      spheres: {
        spheres: items,
      },
    }));
  };

  const lobbiesUpdated = (items, isOther = false) => {
    if (isOther) {
      setDicts((prev) => ({
        ...prev,
        otherLobbies: items,
      }));
      return;
    }
    setDicts((prev) => ({
      ...prev,
      lobbies: {
        lobbies: items,
      },
    }));
  };

  const moveOther = (item) => {
    removeOther(item);
    setDicts((prev) => ({
      ...prev,
      spheres: {
        spheres: [...prev.spheres.spheres, { title: item.title }],
      },
    }));
  };

  const removeOther = (item) => {
    setDicts((prev) => ({
      ...prev,
      otherSpheres: prev.otherSpheres.filter(
        (sphere) => sphere.title !== item.title
      ),
    }));
  };

  const updateCompetenceSection = (sections) => {
    setDicts((prev) => ({
      ...prev,
      competences: {
        sections: sections,
      },
    }));
  };

  const updateLobbySection = (sections) => {
    setDicts((prev) => ({
      ...prev,
      lobbies: {
        sections: sections,
      },
    }));
  };

  const moveCompetence = (item) => {
    setMovePosition(item.title);
    setMoveType('competences');
  };

  const moveLobby = (item) => {
    setMovePosition(item.title);
    setMoveType('lobbies');
  };

  const moveTreePosition = (type, item, section) => {
    setMoveType(null);
    setMovePosition(null);
    const label = `other${capitalize(type)}`;
    setDicts((prev) => {
      const newDicts = {
        ...prev,
        [label]: prev[label].filter((position) => item !== position.title),
      };
      const sections = [...prev[type].sections];
      const index = sections.findIndex((sec) => sec.title === section);
      const sec = { ...sections[index] };
      const items = [
        ...sec.items,
        {
          title: item,
        },
      ];
      sec.items = items;
      sections.splice(index, 1, sec);
      newDicts[type].sections = sections;
      return newDicts;
    });
  };

  const count =
    tab === 'spheres'
      ? options?.brand?.newSpheres?.length
      : tab === 'competences'
      ? options?.brand?.newCompetences?.length
      : options?.brand?.newLobbies?.length;

  useEffect(() => {
    const type = tab;
    if (!type) return;
    navigate(`/club/dicts/${type}/`);
  }, [tab]);

  const updateLobbies = (items) => {
    setDicts((prev) => ({
      ...prev,
      lobbies: { lobbies: items },
    }));
  };

  useEffect(() => {
    fetchDicts();
  }, []);

  useEffect(() => {
    setInnerTab(0);
  }, [tab]);

  return (
    <>
      <Dialog open={true} onClose={modalClosed} className="wide-dialog">
        <DialogTitle>
          Справочники
          <IconButton
            aria-label="close"
            onClick={modalClosed}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ width: '800px', overflowX: 'hidden' }}
          className="paddingless"
        >
          <div
            className={styles.modal}
            style={{ display: isLoading ? 'block' : 'flex' }}
          >
            {isLoading && (
              <Box sx={{ textAlign: 'center', padding: '50px' }}>
                <CircularProgress />
              </Box>
            )}
            {!isLoading && (
              <>
                <div className={styles.tabs}>
                  <SidebarTabs tabs={tabs} value={tab} onChange={setTab} />
                </div>
                <div className={styles.content}>
                  {tab !== 'lobbies' && (
                    <div className={styles.innerTabs}>
                      <Tabs
                        value={innerTab}
                        onChange={(ev, newValue) => setInnerTab(newValue)}
                      >
                        <Tab label="Справочник сообщества" />
                        <Tab
                          label={
                            <div className={styles.otherTab}>
                              <strong>Добавлено пользователями</strong>
                              {!!count &&
                                ((tab === 'spheres' &&
                                  options?.brand?.newSpheres?.length) ||
                                  (tab === 'competences' &&
                                    options?.brand?.newCompetences?.length) ||
                                  (tab === 'lobbies' &&
                                    options?.brand?.newLobbies?.length)) && (
                                  <span className={styles.count}>
                                    <span>{count}</span>
                                  </span>
                                )}
                            </div>
                          }
                        />
                      </Tabs>
                    </div>
                  )}
                  <div className={styles.search}>
                    <Input
                      placeholder="Поиск"
                      icon={Search}
                      value={search}
                      onChange={(ev) => setSearch(ev.target.value)}
                    />
                  </div>
                  {tab === 'spheres' && (
                    <EditPlain
                      innerTab={innerTab}
                      items={dicts.spheres.spheres}
                      type="spheres"
                      onUpdate={spheresUpdated}
                      search={search}
                      other={dicts.otherSpheres}
                      onOtherRemove={removeOther}
                      onOtherMove={moveOther}
                      onOtherUpdate={() => fetchDicts(true)}
                    />
                  )}
                  {tab === 'competences' && (
                    <EditTree
                      type="competences"
                      innerTab={innerTab}
                      sections={dicts.competences.sections}
                      other={dicts.otherCompetences}
                      onUpdate={updateCompetenceSection}
                      search={search}
                      onMove={moveCompetence}
                      onOtherUpdate={() => fetchDicts(true)}
                    />
                  )}
                  {tab === 'lobbies' && (
                    <LobbiesDict
                      items={dicts.lobbies.lobbies}
                      search={search}
                      onUpdate={updateLobbies}
                    />
                    // <EditPlain
                    //   innerTab={innerTab}
                    //   items={dicts.lobbies.lobbies}
                    //   type="lobbies"
                    //   onUpdate={lobbiesUpdated}
                    //   search={search}
                    //   other={dicts.otherLobbies}
                    //   onOtherRemove={removeOther}
                    //   onOtherMove={moveOther}
                    //   onOtherUpdate={() => fetchDicts(true)}
                    // />
                  )}
                </div>
              </>
            )}
          </div>
          <div className={styles.footer}>
            <div className={styles.clear}>
              <Button
                variant="text"
                color="error"
                onClick={() => setIsClearConfirm(true)}
              >
                Очистить справочник
              </Button>
            </div>
            <div className={styles.actions}>
              <AppButton
                color="secondary"
                variant="contained"
                onClick={modalClosed}
              >
                Отмена
              </AppButton>
              &nbsp;
              <AppButton onClick={saveDict} color="primary" variant="contained">
                Сохранить
              </AppButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      {isClearConfirm && (
        <ConfirmModal
          title="Очистка справочника"
          description="Вы действительно хотите полностью очистить справочник ресурсов?"
          onDecline={() => setIsClearConfirm(false)}
          onAccept={clearDict}
        />
      )}
      {moveType && (
        <TopLevelSelect
          type={moveType}
          onCancel={() => setMoveType(null)}
          onSelect={(section) =>
            moveTreePosition(moveType, movePosition, section)
          }
        />
      )}
    </>
  );
};
