import { useEffect, useRef } from 'react';
import styles from './ContactInput.module.scss';

export const ContactInput = ({
  padding = null,
  error = false,
  blurred,
  ...props
}) => {
  const inputRef = useRef();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <input
      data-error={error ? 'error' : ''}
      ref={inputRef}
      className={styles.input}
      data-padding={padding || ''}
      onBlur={blurred}
      {...props}
    />
  );
};
