import { CheckItem } from '../CheckItem/CheckItem';
import styles from './CheckItems.module.scss';

export const CheckItems = ({
  options,
  checked,
  onChange,
  isSingle = false,
  isBlock = false,
}) => {
  const optionChecked = (option) => {
    if (isSingle) {
      onChange([option.value]);
      return;
    }
    if (checked.includes(option.value)) {
      onChange(checked.filter((val) => val !== option.value));
    } else {
      onChange([...checked, option.value].filter((val) => !!val));
    }
  };

  return (
    <div className={styles.items}>
      {options.map((option) => (
        <CheckItem
          isBlock={isBlock}
          key={option.value}
          title={option.label}
          checked={checked.includes(option.value)}
          onCheck={() => optionChecked(option)}
        >
          {option.label}
        </CheckItem>
      ))}
    </div>
  );
};
