import { Add, KeyboardArrowDown } from '@mui/icons-material';
import { AppTag } from '../AppTag/AppTag';
import styles from './ContactsSelector.module.scss';
import { useState } from 'react';
import { AddContact } from './AddContact/AddContact';
import { EmailInput } from './EmailInput/EmailInput';
import { PhoneInput } from './PhoneInput/PhoneInput';
import { TelegramInput } from './TelegramInput/TelegramInput';
import { WebsiteInput } from './WebsiteInput/WebsiteInput';

export const ContactsSelector = ({
  values,
  onChange,
  lockFirst = false,
  prefix = '',
  error,
  type = 'email',
  validate = () => true,
}) => {
  const [showAdd, setShowAdd] = useState(false);

  const removeValue = (value) => {
    if (lockFirst && values[0] === value) return;
    onChange && onChange(values.filter((val) => val !== value));
  };

  const containerClicked = () => {
    if (values.length >= 5) return;
    setShowAdd(true);
  };

  const getInputComponent = () => {
    if (type === 'email') {
      return EmailInput;
    }
    if (type === 'phone') {
      return PhoneInput;
    }
    if (type === 'telegram') {
      return TelegramInput;
    }
    if (type === 'website') {
      return WebsiteInput;
    }
  };

  const addValue = (value) => {
    onChange([...values, value]);
    setShowAdd(false);
  };

  const cancelAdd = () => {
    setShowAdd(false);
  };

  const InputComponent = getInputComponent();

  return (
    <div className={styles.selector} data-error={error ? 'error' : ''}>
      <div className={styles.container} onClick={containerClicked}>
        <div className={styles.tags}>
          <div className={styles.items}>
            {values.map((value, i) => (
              <AppTag
                isInactive={lockFirst && values[0] === value}
                label={`${prefix}${value}`}
                onRemove={() => removeValue(value)}
                key={value}
              />
            ))}
          </div>
          <div className={styles.add}>
            {showAdd && (
              <AddContact
                values={values}
                component={InputComponent}
                onAdd={addValue}
                onCancel={cancelAdd}
                validate={validate}
              />
            )}
          </div>
        </div>
        {values.length < 5 && (
          <div className={styles.icon}>
            <Add />
          </div>
        )}
      </div>
    </div>
  );
};
