import { range } from 'lodash';
import { AppSelect } from '../AppSelect/AppSelect';

import styles from './MonthPicker.module.scss';
import { useEffect, useState } from 'react';

const MONTH_OPTIONS = [
  {
    label: 'Январь',
    value: 1,
  },
  {
    label: 'Февраль',
    value: 2,
  },
  {
    label: 'Март',
    value: 3,
  },
  {
    label: 'Апрель',
    value: 4,
  },
  {
    label: 'Май',
    value: 5,
  },
  {
    label: 'Июнь',
    value: 6,
  },
  {
    label: 'Июль',
    value: 7,
  },
  {
    label: 'Август',
    value: 8,
  },
  {
    label: 'Сентябрь',
    value: 9,
  },
  {
    label: 'Октябрь',
    value: 10,
  },
  {
    label: 'Ноябрь',
    value: 11,
  },
  {
    label: 'Декабрь',
    value: 12,
  },
];
const YEAR_OPTIONS = range(1950, new Date().getFullYear() + 1)
  .map((year) => ({
    label: year,
    value: year,
  }))
  .reverse();

export const MonthPicker = ({ value, disabled, onChange, error }) => {
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);

  useEffect(() => {
    if (!month || !year) {
      onChange && onChange(null);
      return;
    }
    onChange && onChange(`${month.value}-${year.value}`);
  }, [month, year]);

  useEffect(() => {
    if (!value) return;
    const [month, year] = value.split('-');
    const monthOption = MONTH_OPTIONS.find((item) => item.value === +month);
    const yearOption = YEAR_OPTIONS.find((item) => item.value === +year);
    setMonth(monthOption);
    setYear(yearOption);
  }, [value]);

  return (
    <div className={styles.picker}>
      <div className={styles.month}>
        <AppSelect
          options={MONTH_OPTIONS}
          placeholder="Месяц"
          value={month}
          onChange={setMonth}
          isDisabled={disabled}
          error={error}
        />
      </div>
      <div className={styles.year}>
        <AppSelect
          options={YEAR_OPTIONS}
          placeholder="Год"
          value={year}
          onChange={setYear}
          isDisabled={disabled}
          error={error}
        />
      </div>
    </div>
  );
};
