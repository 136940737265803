import styles from './Progress.module.scss';

const LEVELS = [
  {
    title: 'Новичок',
    amount: 0,
  },
  {
    title: 'Приятель',
    amount: 500,
  },
  {
    title: 'Соратник',
    amount: 3000,
  },
  {
    title: 'Партнер',
    amount: 5000,
  },
];

function getLevelTitles(amount) {
  let from, to;
  LEVELS.forEach((level, i) => {
    if (amount >= level.amount) {
      from = level;
      to = LEVELS[i + 1];
    }
  });
  return [from, to || null];
}

export const Progress = ({ amount }) => {
  const [from, to] = getLevelTitles(amount);
  const percent = to ? Math.round((amount / to.amount) * 100) : 100;

  return (
    <div className={styles.progress}>
      <div className={styles.titles}>
        <div className={styles.from}>{from.title}</div>
        <div className={styles.to}>{to?.title}</div>
      </div>
      <div className={styles.slider}>
        <div className={styles.filled} style={{ width: `${percent}%` }}></div>
      </div>
      <div className={styles.amount}>
        {amount}
        {to ? `/${to.amount}` : ''}
      </div>
    </div>
  );
};
