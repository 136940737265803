import { intersection, union, uniq } from 'lodash';
import { useSelector } from 'react-redux';
import { filterStrings } from '../util/filter';
import { FilterDrama } from '@mui/icons-material';

export const useGroupItems = (dict = [], items = []) => {
  const [regular, other] = [
    intersection(uniq(filterStrings(dict)), uniq(filterStrings(items))),
    uniq(items).filter((item) => !dict.includes(item)),
  ];
  return { regular, other };
};
