import { Button } from '@mui/material';
import styles from './AppAlert.module.scss';

export const AppAlert = ({
  color,
  icon,
  title,
  description,
  buttonTitle,
  onButtonClick,
}) => {
  const Icon = icon;

  const buttonClicked = () => {
    onButtonClick && onButtonClick();
  };

  return (
    <div className={styles.alert} data-color={color}>
      {Icon && (
        <div className={styles.icon}>
          <Icon color={color} fontSize="medium" />
        </div>
      )}
      <div className={styles.details}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      {buttonTitle && (
        <div className={styles.button}>
          <Button color={color} onClick={buttonClicked} variant="contained">
            {buttonTitle}
          </Button>
        </div>
      )}
    </div>
  );
};
