import { Breadcrumbs } from '@ui/Breadcrumbs/Breadcrumbs';
import styles from './Page.module.scss';

export const Page = ({
  transparent = false,
  children,
  breadcrumbs = [],
  footer,
}) => {
  return (
    <div
      className={styles.page}
      data-transparent={transparent ? 'transparent' : ''}
    >
      <div className={styles.breadcrumbs}>
        <Breadcrumbs items={breadcrumbs} />
      </div>
      <div className={styles.content}>{children}</div>
      <div className={styles.footer}>{footer}</div>
    </div>
  );
};
