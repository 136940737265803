import { useEffect, useState } from 'react';
import styles from './CalendarFilters.module.scss';
import { Grid, MenuItem, Select, Tab, Tabs } from '@mui/material';
import { FilterAlt, Search, SortByAlpha } from '@mui/icons-material';
import { Input } from '@ui/Input/Input';
import { DropdownButton } from '@ui/DropdownButton/DropdownButton';
import { Dropdown } from '@ui/Dropdown/Dropdown';
import { DatePicker } from '@ui/DatePicker/DatePicker';
import { api } from '@lib/api';
import { AppButton } from '@ui/AppButton/AppButton';

export const CalendarFilters = ({ filters, onChange }) => {
  const [tab, setTab] = useState(0);
  const [status, setStatus] = useState(() => filters.status || 'published');
  const [search, setSearch] = useState(() => filters.search || '');
  const [sortBy, setSortBy] = useState(() => filters.sortBy || 'new');
  const [city, setCity] = useState(() => filters.city || 'all');
  const [format, setFormat] = useState(() => filters.format || 'all');
  const [begin, setBegin] = useState(() => filters.begin || '');
  const [end, setEnd] = useState(() => filters.end || '');
  const [expanded, setExpanded] = useState(false);
  const [cities, setCities] = useState([]);

  const tabChanged = (ev, newValue) => {
    const status = ['published', 'waiting', 'archived'][newValue];
    setTab(newValue);
    setStatus(status);
  };

  const fetchCities = async () => {
    const response = await api.get(`/dashboard/calendar/cities-filters/`);
    setCities(response.data);
  };

  const resetFilters = () => {
    setBegin('');
    setEnd('');
    setStatus('published');
    setSearch('');
    setSortBy('new');
    setCity('all');
    setFormat('all');
  };

  useEffect(() => {
    fetchCities();
  }, []);

  useEffect(() => {
    onChange &&
      onChange({
        status,
        search,
        sortBy,
        city,
        format,
        begin,
        end,
      });
  }, [status, search, sortBy, city, format, begin, end]);

  return (
    <div className={styles.filters}>
      <Grid container spacing={2} sx={{ width: '824px' }}>
        {/* <Grid item xs={12}>
          <Tabs value={tab} onChange={tabChanged} className={styles.tabs}>
            <Tab label="Опубликованы" className={styles.tabs} />
            <Tab label="Ожидают публикации" className={styles.tabs} />
            <Tab label="Архив" className={styles.tabs} />
          </Tabs>
        </Grid> */}
        <Grid item xs={12}>
          <Input
            placeholder="Поиск по событиям"
            icon={Search}
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          />
        </Grid>
        <Grid item xs={3}>
          <DropdownButton
            icon={FilterAlt}
            title="Фильтры"
            expanded={expanded}
            onChange={setExpanded}
          />
        </Grid>
        <Grid item xs={3}>
          <Dropdown
            icon={SortByAlpha}
            title={{ new: 'Сначала новые', old: 'Сначала старые' }[sortBy]}
            value={sortBy}
            onChange={(value) => setSortBy(value)}
            options={[
              {
                title: 'Сначала новые',
                value: 'new',
              },
              {
                title: 'Сначала старые',
                value: 'old',
              },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <div
            style={{
              position: 'relative',
              bottom: '1.5px',
              right: '15px',
              transform: 'scale(0.9, 0.9)',
            }}
          >
            <AppButton size="small" onClick={resetFilters}>
              Сбросить фильтры
            </AppButton>
          </div>
        </Grid>
        {expanded && (
          <>
            <Grid item xs={3}>
              <Select
                value={city}
                onChange={(ev) => setCity(ev.target.value)}
                size="small"
                className={styles.select}
              >
                <MenuItem value={'all'}>Все города</MenuItem>
                {cities.map((city) => (
                  <MenuItem value={city.title} key={city.title}>
                    {city.title || 'Без названия'} ({city.count})
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={3}>
              <Select
                value={format}
                onChange={(ev) => setFormat(ev.target.value)}
                size="small"
                className={styles.select}
              >
                <MenuItem value={'all'}>Формат</MenuItem>
                <MenuItem value={'online'}>Онлайн</MenuItem>
                <MenuItem value={'offline'}>Оффлайн</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                value={begin}
                onChange={setBegin}
                placeholder={'Начало'}
              />
            </Grid>
            <Grid item xs={3}>
              <DatePicker
                value={end}
                onChange={setEnd}
                placeholder={'Окончание'}
              />
            </Grid>
          </>
        )}
      </Grid>
    </div>
  );
};
