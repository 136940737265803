import { useEffect, useState } from 'react';
import styles from './EditableTagList.module.scss';
import { Input } from './Input/Input';
import { Options } from './Options/Options';
import { matchSorter } from 'match-sorter';

export const EditableTagList = ({
  selected,
  onChange,
  options,
  onOptionChange,
  onOptionRemove,
  onOptionCreate,
  max = 100000000,
}) => {
  const [tags, setTags] = useState([...selected]);
  const [showOptions, setShowOptions] = useState(false);
  const [search, setSearch] = useState('');
  const [heightShift, setHeightShift] = useState(0);
  const [focus, setFocus] = useState(0);

  const removeTag = (tag) => {
    setTags((prev) => {
      return prev.filter((item) => item !== tag);
    });
    setFocus((prev) => prev + 1);
  };

  const inputFocused = (ev) => {
    ev.stopPropagation();
    setShowOptions((prev) => !prev);
  };

  const hideOptions = () => {
    setShowOptions(false);
  };

  const optionClicked = (option) => {
    setTags((prev) => [...prev, option]);
    setFocus((prev) => prev + 1);
    setSearch('');
  };

  const filterOptions = () => {
    return matchSorter(
      options.filter((option) => !tags.includes(option)),
      search
    );
  };

  const popTag = () => {
    setTags((prev) => [...prev.slice(0, -1)]);
  };

  const optionChanged = (oldValue, newValue) => {
    onOptionChange && onOptionChange(oldValue, newValue);
  };

  const optionRemoved = (option) => {
    onOptionRemove && onOptionRemove(option);
  };

  const createOption = (option) => {
    if (options.includes(option)) return;
    setSearch('');
    onOptionCreate && onOptionCreate(option);
    setTags((prev) => [...prev, option]);
    setFocus((prev) => prev + 1);
  };

  const updateInputHeight = () => {
    const elem = document.querySelector('.editable-tag-list__input');
    const b = elem.getBoundingClientRect();
    const shift = b.height - 84;
    setHeightShift(shift);
  };

  useEffect(() => {
    document.addEventListener('click', hideOptions);
    return () => {
      document.removeEventListener('click', hideOptions);
    };
  }, []);

  useEffect(() => {
    updateInputHeight();
    onChange && onChange(tags);
  }, [tags]);

  useEffect(() => {
    setShowOptions(true);
  }, [search]);

  return (
    <div className={styles.list}>
      <div className={styles.input}>
        <Input
          tags={tags}
          onRemove={removeTag}
          onClick={inputFocused}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
          onPopTag={popTag}
          onCreate={() => createOption(search)}
          focus={focus}
          hideInput={tags.length >= max}
        />
      </div>
      {showOptions && tags.length < max && (
        <div className={styles.options}>
          <Options
            options={filterOptions()}
            allOptions={options}
            onClick={optionClicked}
            onOptionChange={optionChanged}
            onOptionRemove={optionRemoved}
            search={search}
            onOptionCreate={createOption}
            heightShift={heightShift}
            disableCreate={options.includes(search)}
          />
        </div>
      )}
    </div>
  );
};
