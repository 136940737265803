import { useSelector } from 'react-redux';
import styles from './Logo.module.scss';
import { COMMUNITY_LOGO_URLS } from '@data/constants';

export const Logo = () => {
  const community = useSelector((state) => state.community.community);

  const logoUrl =
    COMMUNITY_LOGO_URLS[community?.title] || COMMUNITY_LOGO_URLS.default;

  return (
    <a href={process.env.REACT_APP_UNAUTH_REDIRECT} className={styles.logo}>
      <img src={logoUrl} alt="Community logo" />
    </a>
  );
};
