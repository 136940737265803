import { useContext, useState } from 'react';
import { ComingSoon } from '../ComingSoon/ComingSoon';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import styles from './Karma.module.scss';
import { ResidentContext } from '../../../context/ResidentContext';
import { Progress } from './Progress/Progress';
import { AddKarmaForm } from './AddKarmaForm/AddKarmaForm';
import { SuccessModal } from './SuccessModal/SuccessModal';
import { api } from '@lib/api';

export const Karma = ({ isEmpty }) => {
  const { data: resident, update: updateResident } =
    useContext(ResidentContext);
  const [showSuccess, setShowSuccess] = useState(false);

  const addKarma = async (data) => {
    const response = await api.post(`/dashboard/club/add-karma`, {
      userId: resident.userId,
      amount: data.amount,
      description: data.description,
    });
    if (!response.data.success) {
      return;
    }
    updateResident('karma', response.data.total);
    setShowSuccess(true);
  };

  const successClosed = () => {
    setShowSuccess(false);
  };

  return (
    <>
      <div className={styles.karma}>
        <SectionHeader
          title="Рейтинг кармы"
          description="Система бонусов для резидентов сообщества"
        />
        {isEmpty && <ComingSoon />}
        {!isEmpty && (
          <>
            <div className={styles.progress}>
              <Progress amount={resident.karma} />
            </div>
            <div className={styles.add}>
              <AddKarmaForm onSubmit={addKarma} />
            </div>
          </>
        )}
      </div>
      {showSuccess && <SuccessModal onClose={successClosed} />}
    </>
  );
};
