import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { fetchCommunity } from '../../../store/community';
import { startProgress } from '../../../store/common';

const CALENDAR_UID = 'TEST';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    profile: null,
    options: null,
    isImpersonate: false,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    setProfile(state, action) {
      state.profile = action.payload;
    },
    setIsImpersonate(state, action) {
      state.isImpersonate = action.payload;
    },
    setOptions(state, action) {
      state.options = action.payload;
    },
  },
});

export const fetchUser = () => {
  return async (dispatch) => {
    dispatch(startProgress());
    const response = await api.get(`/v2/users/me/`);
    const optionsResponse = await api.get(`/dashboard/options/`);
    dispatch(authSlice.actions.setUser(response.data));
    dispatch(authSlice.actions.setIsImpersonate(false));
    dispatch(authSlice.actions.setOptions(optionsResponse.data));
    dispatch(fetchCommunity());
  };
};

export const updateOptions = (data) => {
  return async (dispatch) => {
    dispatch(startProgress);
    const response = await api.put('/dashboard/options/', data);
    dispatch(
      authSlice.actions.setOptions({
        ...response.data,
        calendarUid: CALENDAR_UID,
      })
    );
  };
};

export const fetchProfile = () => async (dispatch) => {
  const response = await api.get(`/profile/current/`);
  dispatch(authSlice.actions.setProfile(response.data.profile));
};
