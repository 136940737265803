import { useEffect, useState } from 'react';
import { Field } from '../Field/Field';
import { TextInput } from '../TextInput/TextInput';

import styles from './EditProject.module.scss';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import { MonthPicker } from '../MonthPicker/MonthPicker';
import { CheckBox } from '../CheckBox/CheckBox';
import { FieldError } from '../FieldError/FieldError';
import InputAdornment from '@mui/material/InputAdornment';

export const EditProject = ({
  data = {},
  onChange,
  errors,
  formSubmitted,
  disabled,
}) => {
  const [name, setName] = useState(data.name || '');
  const [startYear, setStartYear] = useState(data.startYear || null);
  const [finishYear, setFinishYear] = useState(data.finishYear || null);
  const [untilNow, setUntilNow] = useState(data.untilNow || false);
  const [link, setLink] = useState(data.link || '');
  const [description, setDescription] = useState(data.description || '');
  const [nameTouched, setNameTouched] = useState(false);

  const isError = nameTouched && !name?.trim();

  useEffect(() => {
    onChange &&
      onChange({
        name,
        startYear,
        finishYear,
        untilNow,
        link,
        description,
      });
  }, [name, startYear, finishYear, untilNow, link, description]);

  return (
    <div className={styles.edit}>
      <Field label="Проект">
        <FormControl fullWidth>
          <TextInput
            value={name}
            disabled={disabled}
            onChange={(ev) => setName(ev.target.value)}
            onBlur={() => setNameTouched(true)}
            error={(nameTouched && isError) || (formSubmitted && errors.name)}
          />
          {((nameTouched && isError) || (formSubmitted && errors.name)) && (
            <FieldError>Укажите название</FieldError>
          )}
        </FormControl>
      </Field>
      <div className={styles.dates}>
        <div className={styles.inputs}>
          <div className={styles.label}>с</div>
          <div className={styles.month}>
            <MonthPicker
              disabled={disabled}
              value={startYear}
              onChange={setStartYear}
              error={formSubmitted && errors.period}
            />
          </div>
          <div className={styles.line}></div>
          <div className={styles.label}>по</div>
          <div className={styles.year}>
            <MonthPicker
              value={finishYear}
              onChange={setFinishYear}
              disabled={untilNow || disabled}
              error={formSubmitted && errors.period}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <div className={styles.error}>
            {formSubmitted && errors.period && (
              <section>
                <FieldError>{errors.period}</FieldError>
              </section>
            )}
          </div>
          <div className={styles.now}>
            <FormControlLabel
              control={
                <CheckBox
                  disabled={disabled}
                  checked={untilNow}
                  onChange={(ev) => setUntilNow(ev.target.checked)}
                />
              }
              label="По настоящее время"
            />
          </div>
        </div>
      </div>
      <Field label="Ссылка на проект">
        <FormControl fullWidth>
          <TextInput
            value={link}
            onChange={(ev) => setLink(ev.target.value)}
            error={formSubmitted && errors.link}
            disabled={disabled}
          // InputProps={{
          //   startAdornment: (
          //     <InputAdornment position="start">https://</InputAdornment>
          //   ),
          // }}
          />
          {formSubmitted && errors.link && (
            <FieldError>{errors.link}</FieldError>
          )}
        </FormControl>
      </Field>
      <Field label="Краткое описание">
        <FormControl fullWidth>
          <TextInput
            value={description}
            disabled={disabled}
            onChange={(ev) => setDescription(ev.target.value)}
          />
        </FormControl>
      </Field>
    </div>
  );
};
