import { Add, Group, Person, Search, Settings } from '@mui/icons-material';
import { Page } from '../../layouts/Page/Page';
import { Heading } from '../../layouts/Heading/Heading';
import { AppButton } from '@ui/AppButton/AppButton';
import { Input } from '@ui/Input/Input';
import { useEffect, useState } from 'react';

import styles from './Residents.module.scss';
import { ViewSwitch } from '../../features/club/components/ViewSwitch/ViewSwitch';
import { Tab, Tabs } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchInitCards, fetchMoreCards } from '../../features/club/store/club';
import { ResidentsCards } from '../../features/club/components/ResidentsCards/ResidentsCards';
import { ResidentsTable } from '../../features/club/components/ResidentsTable/ResidentsTable';
import { ResidentModal } from '../../features/club/components/ResidentModal/ResidentModal';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../hooks/usePageTitle';

export const ResidentsPage = () => {
  const [search, setSearch] = useState('');
  const [view, setView] = useState(
    () => localStorage.getItem('cards-view') || 'cards'
  );
  const [tab, setTab] = useState(0);
  const [isBottom, setIsBottom] = useState(false);
  const [isArchive, setIsArchive] = useState(false);
  const navigate = useNavigate();
  const { management } = useSelector((state) => state.club);
  const managementCount =
    management.toInvite.length + management.toVerify.length;

  const { total, page, cards, limit } = useSelector((state) => state.club);
  const dispatch = useDispatch();
  const pageTitle = usePageTitle();

  pageTitle('Резиденты');

  const fetchCards = () => {
    dispatch(fetchInitCards(search, isArchive));
  };

  const onWindowScroll = (ev) => {
    const d = document.body.offsetHeight - window.innerHeight - window.scrollY;
    if (d > 200) {
      setIsBottom(false);
    } else {
      setIsBottom(true);
    }
  };

  const bottomChanged = () => {
    if (!isBottom) return;
    if (total < page * limit) return;
    dispatch(fetchMoreCards(search, isArchive));
  };

  const cardClicked = (card) => {
    navigate(`/club/residents/${card.id}/`);
  };

  useEffect(() => {
    fetchCards();
    window.addEventListener('scroll', onWindowScroll);
    return () => {
      window.removeEventListener('scroll', onWindowScroll);
    };
  }, []);

  useEffect(() => {
    bottomChanged();
  }, [isBottom]);

  useEffect(() => {
    if (tab === 0) {
      setIsArchive(false);
    } else {
      setIsArchive(true);
    }
  }, [tab]);

  useEffect(() => {
    fetchCards();
  }, [isArchive, search]);

  useEffect(() => {
    localStorage.setItem('cards-view', view);
  }, [view]);

  useEffect(() => {
    onWindowScroll();
  }, []);

  return (
    <Page
      breadcrumbs={[
        {
          title: 'Ресурсная карта',
          icon: Group,
          url: '/club/',
        },
        {
          title: 'Карточки резидентов',
          icon: Person,
          url: '/club/residents/',
        },
      ]}
    >
      <div className={styles.residents}>
        <div className={styles.heading}>
          <Heading icon={Person} title="Карточки резидентов">
            <Link to="/club/residents/management/">
              <AppButton startIcon={<Settings />} color="secondary">
                <div className={styles.management}>
                  <div className={styles.title}>Управление резидентами</div>
                  {!!managementCount && (
                    <div className={styles.count}>
                      <span>{managementCount}</span>
                    </div>
                  )}
                </div>
              </AppButton>
            </Link>
            &nbsp;
            <Link to="/club/residents/add/">
              <AppButton startIcon={<Add />} color="primary">
                Добавить резидента
              </AppButton>
            </Link>
          </Heading>
        </div>
        <div className={styles.search}>
          <Input
            placeholder="Поиск по резидентам"
            icon={Search}
            value={search}
            onChange={(ev) => setSearch(ev.target.value)}
          />
        </div>
        <div className={styles.switch}>
          <ViewSwitch view={view} onChange={setView} />
        </div>
        <div className={styles.tabs}>
          <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)}>
            <Tab label="Активные" />
            <Tab label="Архив" />
          </Tabs>
        </div>
        <div className={styles.list}>
          {view === 'cards' && (
            <ResidentsCards cards={cards} onClick={cardClicked} />
          )}
          {view === 'table' && (
            <ResidentsTable cards={cards} onClick={cardClicked} />
          )}
        </div>
      </div>
      <Outlet />
    </Page>
  );
};
