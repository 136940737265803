import { useDispatch, useSelector } from 'react-redux';
import styles from './SurveyTable.module.scss';
import { useEffect, useState } from 'react';
import { fetchItems } from '../../store/survey';
import { Box, Tab, Tabs } from '@mui/material';
import { Check, HourglassBottom } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { formatPhone } from '../../../../util/format';
import moment from 'moment';

export const SurveyTable = () => {
  const dispatch = useDispatch();
  const { items } = useSelector((state) => state.survey);
  const [tab, setTab] = useState(0);

  const getInterviewDatetime = (item) => {
    if (!item.interviewRequest && !item.callDate)
      return `Дата и время интервью не назначены`;
    // Ожидает звонка
    // {item.callDate ? ` ${item.callDate} в ${item.callTime}` : ''}
    const method = item.interviewRequest
      ? item.interviewRequest.method
      : 'call';
    const date = (
      item.callDate
        ? moment(item.callDate, 'DD.MM.YYYY')
        : moment(item.interviewRequest?.date, 'YYYY-MM-DD')
    ).format('DD MMMM YYYY');
    const time = item.callTime || `${item.interviewRequest?.time}:00`;
    return `Способ: ${
      {
        call: 'звонок',
        telegram: 'телеграм',
        whatsapp: 'WhatsApp',
      }[method]
    }, ${date} в ${time}`;
  };

  const sortItems = (items) => {
    return items.sort((a, b) => {
      if (a.callDate && b.callDate) {
        const aDate = moment(a.callDate, 'DD.MM.YYYY');
        const bDate = moment(b.callDate, 'DD.MM.YYYY');
        return aDate.toDate().getTime() > bDate.toDate().getTime() ? 1 : -1;
      }
      return a.callDate && !b.callDate ? -1 : 1;
    });
  };

  const getStatus = (item) => {
    if (!item.isSurvey) {
      return (
        <div className={styles.status}>
          <div className={styles.icon}>
            <Check color="success" fontSize="small" />
          </div>
          <div className={styles.title}>Завершено</div>
        </div>
      );
    }

    return (
      <div className={styles.status}>
        <div className={styles.icon}>
          <HourglassBottom color="warning" fontSize="small" />
        </div>
        <div className={styles.title}>{getInterviewDatetime(item)}</div>
      </div>
    );
  };

  const waitingItems = items.filter((item) => item.isSurvey);
  const readyItems = items.filter((item) => !item.isSurvey);

  useEffect(() => {
    dispatch(fetchItems());
  }, []);

  return (
    <div className={styles.survey}>
      <Box marginBottom={2}>
        <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)}>
          <Tab label={`Ожидают опроса (${waitingItems.length})`} />
          <Tab label={`Опрос завершен (${readyItems.length})`} />
        </Tabs>
      </Box>
      <div className={styles.items}>
        {sortItems(tab === 0 ? waitingItems : readyItems).map((item) => (
          <NavLink to={`/survey/${item.id}/`} key={item.id}>
            <div className={styles.item} key={item.id}>
              <div className={styles.id}>#{item.id}</div>
              <div className={styles.name}>
                {item.firstName} {item.lastName}
              </div>
              <div className={styles.phone}>{formatPhone(item.phone)}</div>
              <div className={styles.email}>{item.email}</div>
              {getStatus(item)}
            </div>
          </NavLink>
        ))}
      </div>
    </div>
  );
};
