import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './SelectResourcesModal.module.scss';
import { Close } from '@mui/icons-material';
import { AppButton } from '@ui/AppButton/AppButton';
import { PlainDict } from './PlainDict/PlainDict';
import { TreeDict } from './TreeDict/TreeDict';
import { Selected } from './Selected/Selected';
import { useSelector } from 'react-redux';
import { useContext, useState } from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { CloseConfirmation } from '../../../../components/ui/CloseConfirmation/CloseConfirmation';
import { ResidentContext } from '../../context/ResidentContext';

export const SelectResourcesModal = ({ selected, onCancel, onSave, type }) => {
  const { canAddResource } = useContext(ResidentContext);
  const [items, setItems] = useState(() => [...selected]);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const title = {
    spheres: 'Сферы деятельности',
    competences: 'Компетенции',
    lobbies: 'Лобби-интересы',
  }[type];
  const dict = useSelector((state) => state.common[type]);
  const [initData, setInitData] = useState(cloneDeep(selected));
  const isFull = !canAddResource(type, items);

  const saveItems = () => {
    onSave(items);
  };

  const itemSelected = (title) => {
    if (isFull) return;
    setItems((prev) => {
      const items = [...prev];
      items.push(title);
      return items;
    });
  };

  const itemUnselected = (title) => {
    setItems((prev) => {
      return prev.filter((itemTitle) => itemTitle !== title);
    });
  };

  const closeWindow = () => {
    const isChanged = !isEqual(initData, items);
    if (!isChanged) {
      onCancel();
      return;
    }
    setShowCloseConfirmation(true);
  };

  return (
    <>
      <Dialog open={true} onClose={closeWindow} className="wide-dialog">
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={closeWindow}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ width: '800px', overflowX: 'hidden' }}
          className="paddingless"
        >
          <div className={styles.modal}>
            <div className={styles.dict}>
              <div className={styles.header}>Справочник</div>
              {type === 'spheres' && (
                <PlainDict
                  type={type}
                  selected={items}
                  dict={dict}
                  onCheck={itemSelected}
                />
              )}
              {['competences', 'lobbies'].includes(type) && (
                <TreeDict
                  type={type}
                  selected={items}
                  dict={dict}
                  onCheck={itemSelected}
                />
              )}
            </div>
            <div className={styles.selected}>
              <div className={styles.header}>Выбрано</div>
              <Selected selected={items} type={type} onCheck={itemUnselected} />
            </div>
          </div>
        </DialogContent>
        <DialogActions className={styles.footer}>
          <AppButton color="secondary" onClick={onCancel}>
            Отмена
          </AppButton>
          &nbsp;
          <AppButton color="primary" onClick={saveItems}>
            Сохранить изменения
          </AppButton>
        </DialogActions>
      </Dialog>
      {showCloseConfirmation && (
        <CloseConfirmation
          title="Редактирование ресурсов"
          onSave={saveItems}
          onClear={onCancel}
          onClose={() => setShowCloseConfirmation(false)}
        />
      )}
    </>
  );
};
