import plural from 'ooi/plural';

export const formatRrule = (str) => {
  const WEEKDAYS = [, 'MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];
  const WEEKDAY_TITLES = [, 'пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

  if (!str) return null;
  if (str.includes('FREQ=DAILY')) {
    if (str.includes('INTERVAL=')) {
      const interval = +str.split('INTERVAL=')[1].split(';')[0];
      return `Каждые ${interval} ${plural(interval, ['день', 'дня', 'дней'])}`;
    } else {
      return 'Ежедневно';
    }
  }
  if (str.includes('FREQ=MONTHLY')) {
    let label = 'Ежемесячно';
    if (str.includes('INTERVAL=')) {
      const interval = +str.split('INTERVAL=')[1].split(';')[0];
      label = `Каждые ${interval} ${plural(interval, [
        'месяц',
        'месяца',
        'месяцев',
      ])}`;
    }
    if (!str.includes('BYDAY=')) {
      return label;
    }
    const byday = str.split('BYDAY=')[1].split(';')[0];
    const index = byday.slice(0, 1);
    const day = byday.slice(1);
    const weekday = WEEKDAY_TITLES[WEEKDAYS.indexOf(day)];
    return `${label}, ${index} ${weekday}`;
  }
  if (str.includes('FREQ=WEEKLY')) {
    let label = 'Еженедельно';
    if (str.includes('INTERVAL=')) {
      const interval = +str.split('INTERVAL=')[1].split(';')[0];
      label = `Каждые ${interval} ${plural(interval, [
        'неделя',
        'недели',
        'недель',
      ])}`;
    }
    const byday = str.split('BYDAY=')[1]?.split(';')[0] || null;
    if (byday.split(',').length === 5) return `${label} по будням`;
    if (!byday) return label;
    const weekdays = byday
      .split(',')
      .map((d) => WEEKDAYS.indexOf(d))
      .sort()
      .map((index) => WEEKDAY_TITLES[index]);
    return `${label} по ${weekdays.join(',')}`;
  }
  if (str.includes('FREQ=YEARLY')) {
    let label = 'Ежегодно';
    if (str.includes('INTERVAL=')) {
      const interval = +str.split('INTERVAL=')[1].split(';')[0];
      label = `Каждые ${interval} ${plural(interval, ['год', 'года', 'лет'])}`;
    }
    return label;
  }
  return null;
};
