import { CalendarFilters } from '../CalendarFilters/CalendarFilters';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { EventCard } from '../EventCard/EventCard';
import { api } from '@lib/api';
import { Heading } from '@layouts/Heading/Heading';
import {
  calendarSlice,
  fetchEvents,
  nextPage,
} from '@features/calendar/store/calendar';

import styles from './Events.module.scss';
import { Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { EventModal } from '../EventModal/EventModal';

export const Events = ({ children }) => {
  const filters = useSelector((state) => state.calendar.filters);
  const events = useSelector((state) => state.calendar.events);
  const page = useSelector((state) => state.calendar.page);
  const limit = useSelector((state) => state.calendar.limit);
  const total = useSelector((state) => state.calendar.total);
  const loading = useSelector((state) => state.calendar.loading);
  const [bottom, setBottom] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onBottom = () => {
    const pagesCount = Math.ceil(total / limit);
    if (page >= pagesCount) return;
    dispatch(calendarSlice.actions.setLoading(true));
    dispatch(nextPage());
  };

  const onWheel = () => {
    if (loading) return;
    const d = document.body.offsetHeight - window.innerHeight - window.scrollY;
    if (d > 200) {
      setBottom(false);
    } else {
      setBottom(true);
    }
  };

  const eventClicked = (event) => {
    navigate(`/calendar/${event.id}/`);
  };

  const filtersChanged = (filters) => {
    dispatch(calendarSlice.actions.setFilters(filters));
  };

  useEffect(() => {
    if (!bottom) {
      return;
    }
    onBottom();
  }, [bottom]);

  useEffect(() => {
    dispatch(calendarSlice.actions.setPage(1));
    dispatch(fetchEvents());
  }, [filters]);

  useEffect(() => {
    fetchEvents();
    window.addEventListener('wheel', onWheel);
    return () => {
      window.removeEventListener('wheel', onWheel);
    };
  }, []);

  return (
    <div className={styles.events}>
      <Heading title="События" level={2} />
      {/* <Box
          sx={{
            fontSize: '12px',
            color: 'red',
            fontFamily: 'monospace',
            outline: '1px solid red',
            padding: '20px',
            marginBottom: '20px',
          }}
        >
          {JSON.stringify(filters)}
        </Box> */}
      <Box sx={{ marginBottom: 3 }}>
        <CalendarFilters filters={filters} onChange={filtersChanged} />
      </Box>
      {!!children && <Box sx={{ marginBottom: 3 }}>{children}</Box>}
      <div className={styles.items}>
        {events.map((event) => (
          <EventCard
            key={event.id}
            onClick={() => eventClicked(event)}
            event={event}
          />
        ))}
      </div>
      <Outlet />
    </div>
  );
};
