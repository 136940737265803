import { Search } from '@mui/icons-material';
import { SelectItem } from '@ui/SelectItem/SelectItem';
import styles from './PlainDict.module.scss';
import { Input } from '@ui/Input/Input';
import { useState } from 'react';
import { matchSorter } from 'match-sorter';

export const PlainDict = ({ selected, dict, onCheck, type }) => {
  const [search, setSearch] = useState('');
  const emptyLabel = {
    spheres: 'Не найдено ни одной сферы деятельности.',
    competences: 'Не найдено ни одной компетенции.',
    lobbies: 'Не найдено ни одного лобби-интереса.',
  }[type];

  const getDictItems = () => {
    return matchSorter(dict, search).filter(
      (title) => !selected.includes(title)
    );
  };

  const items = getDictItems();

  return (
    <div className={styles.plain}>
      <div className={styles.search}>
        <Input
          placeholder="Поиск"
          icon={Search}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
      </div>
      <div className={styles.items}>
        {items.map((title) => (
          <SelectItem
            key={title}
            title={title}
            checked={false}
            onCheck={() => onCheck(title)}
          />
        ))}
        {!items.length && <div className={styles.empty}>{emptyLabel}</div>}
      </div>
    </div>
  );
};
