import {
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowRight,
} from '@mui/icons-material';
import styles from './ResourceSection.module.scss';
import { ResourceItem } from '../ResourceItem/ResourceItem';
import { useState } from 'react';
import { IconButton } from '@mui/material';
import { AddMore } from '../../../features/club/components/AddMore/AddMore';

export const ResourceSection = ({
  color,
  title,
  items,
  onUpdate,
  isExpanded,
  onRemove,
  onEdit,
  onItems,
  onCheck,
  checked,
}) => {
  const [expanded, setExpanded] = useState(false);

  const isExp = expanded || isExpanded;

  const addItem = (title) => {
    onItems([...items, { title }]);
  };

  const updateItem = (item, newTitle) => {
    const is = [...items];
    const index = is.indexOf(item);
    if (index < 0) return;
    is.splice(index, 1, { ...item, title: newTitle });
    onItems(is);
  };

  const removeItem = (item) => {
    const is = [...items];
    const index = is.indexOf(item);
    if (index < 0) return;
    is.splice(index, 1);
    onItems(is);
  };

  const removeClicked = (ev) => {
    ev.stopPropagation();
    onRemove && onRemove();
  };

  return (
    <div className={styles.section} data-expanded={isExp ? 'expanded' : ''}>
      <div className={styles.header} onClick={() => setExpanded(!expanded)}>
        <div className={styles.expandIcon}>
          {isExp ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
        </div>
        <div className={styles.color} style={{ background: color }}></div>
        <div className={styles.title}>{title}</div>
        <div className={styles.editIcon}>
          <IconButton size="small" onClick={onEdit}>
            <Edit fontSize="small" />
          </IconButton>
          &nbsp;
          <IconButton size="small" onClick={removeClicked}>
            <Delete fontSize="small" />
          </IconButton>
        </div>
      </div>
      {isExp && (
        <>
          <div className={styles.items}>
            {items.map((item) => (
              <ResourceItem
                key={item.title}
                title={item.title}
                checked={checked.includes(item)}
                onCheck={() => onCheck(item)}
                isPadded
                onUpdate={(newTitle) => updateItem(item, newTitle)}
                onRemove={() => removeItem(item)}
              />
            ))}
            <div className={styles.footer}>
              <AddMore
                onAdd={addItem}
                exclude={items.map((item) => item.title)}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};
