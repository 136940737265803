import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfile, fetchUser } from './features/auth/store/auth';
import { Layout } from './layouts/Layout/Layout';
import { Route, Routes } from 'react-router-dom';
import { IndexPage } from './pages/Index/Index';
import { CalendarPage } from './pages/Calendar/Calendar';
import { ClubPage } from './pages/Club/Club';
import { createCustomTheme } from './theme';
import { ThemeProvider } from '@mui/material';
import { SupportPage } from './pages/Support/Support';
import { ResidentsPage } from './pages/Residents/Residents';

import styles from './App.module.scss';
import { syncEvents } from './features/calendar/store/calendar';
import { GoogleUserContext } from './context/GoogleUserContext';
import { EventModal } from './features/calendar/components/EventModal/EventModal';
import { GoogleOptionsModal } from './features/calendar/components/GoogleOptionsModal/GoogleOptionsModal';
import { fetchDicts, fetchFields } from './store/common';
import { ResidentModal } from './features/club/components/ResidentModal/ResidentModal';
import { ResourcesModal } from './features/dicts/components/ResourcesModal/ResourcesModal';
import { ManagementModal } from './features/club/components/ManagementModal/ManagementModal';
import { fetchManagement } from './features/club/store/club';
import { Survey } from './pages/Survey/Survey';
import { SurveyItemPage } from './pages/SurveyItem/SurveyItem';
import { fetchQuickLinks } from './features/quick-links/store/quick-links';
import { AddResidentModal } from './features/club/components/AddResidentModal/AddResidentModal';
import { InviteExistingModal } from './features/club/components/InviteExistingModal/InviteExistingModal';
import { InviteNewModal } from './features/club/components/InviteModal/InviteModal';

export const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const community = useSelector((state) => state.community.community);
  const { user: googleUser, credentials } = useContext(GoogleUserContext);
  const [intervalId, setIntervalId] = useState();
  const options = useSelector((state) => state.auth.options);

  const theme = createCustomTheme({
    theme: 'light',
    direction: 'ltr',
    responsiveFontSizes: 'true',
  });

  const startPolling = () => {
    if (!googleUser || !credentials) return;
    dispatch(syncEvents(credentials.access_token));
    setIntervalId(
      setInterval(() => {
        if (!googleUser || !credentials) return;
        dispatch(syncEvents(credentials.access_token));
      }, 5000)
    );
  };

  const stopPolling = () => {
    clearInterval(intervalId);
  };

  useEffect(() => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  }, []);

  useEffect(() => {
    if (!googleUser || !credentials || !credentials?.access_token) return;
    dispatch(syncEvents(credentials.access_token));
  }, [options]);

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    if (googleUser) {
      startPolling();
    } else {
      stopPolling();
    }
    return stopPolling;
  }, [googleUser]);

  useEffect(() => {
    dispatch(fetchDicts());
    dispatch(fetchManagement());
    dispatch(fetchQuickLinks());
    dispatch(fetchFields());
    dispatch(fetchProfile());
  }, []);

  if ((user && !user.isManager && !user.isInterviewer) || community === -1) {
    // window.open(process.env.REACT_APP_UNAUTH_REDIRECT, '_top');
  }

  if (!user || !community) {
    return null;
  }

  return (
    <div className={styles.app}>
      <ThemeProvider theme={theme}>
        <Layout>
          <Routes>
            <Route path="/" element={<IndexPage />} />
            <Route path="support" element={<SupportPage />} />
            {user.isManager && (
              <>
                <Route path="calendar" element={<CalendarPage />}>
                  <Route path=":eventId" element={<EventModal />} />
                  <Route path="options" element={<GoogleOptionsModal />} />
                </Route>
                <Route path="club" element={<ClubPage />}>
                  <Route path="dicts/:type" element={<ResourcesModal />} />
                </Route>
                <Route path="club/residents" element={<ResidentsPage />}>
                  <Route path="add" element={<AddResidentModal />} />
                  <Route path=":cardId" element={<ResidentModal />} />
                  <Route path="management" element={<ManagementModal />} />
                  <Route
                    path="invite/:cardId"
                    element={<InviteNewModal />}
                  ></Route>
                  <Route
                    path="invite-existing/:cardId"
                    element={<InviteExistingModal />}
                  ></Route>
                </Route>
              </>
            )}
            {user.isInterviewer && (
              <>
                <Route path="survey" element={<Survey />}></Route>
                <Route
                  path="survey/:cardId"
                  element={<SurveyItemPage />}
                ></Route>
              </>
            )}
          </Routes>
        </Layout>
      </ThemeProvider>
    </div>
  );
};
