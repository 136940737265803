import { Email, Person, Phone, Quiz, Telegram } from '@mui/icons-material';
import styles from './ResidentCard.module.scss';
import { sample } from 'lodash';
import { ACTUAL_REQUESTS } from '../../data/actual-requests';
import { Tooltip } from '@mui/material';
import { ResidentAvatar } from '../ResidentAvatar/ResidentAvatar';

const IS_DEMO = false;

export const ResidentCard = ({ card, onClick }) => {
  const getActualRequest = () => {
    const val = card.actualRequest;
    if (val.length >= 50) {
      return val.slice(0, 50) + '...';
    }
    return val;
  };

  return (
    <div
      className={styles.card}
      onClick={onClick}
      data-user={card.hasUser ? 'user' : ''}
      data-archive={card.isArchive ? 'archive' : ''}
    >
      {/* <div className={styles.id}>#{card.id}</div> */}
      <div className={styles.header}>
        {/* <div className={styles.icon}>
          <Person />
        </div> */}
        {card.isSurvey && (
          <div className={styles.survey}>
            <Tooltip title="Ожидает опроса">
              <Quiz fontSize="medium" color="disabled" />
            </Tooltip>
          </div>
        )}
        <div className={styles.avatar}>
          <ResidentAvatar avatar={card.avatar} />
        </div>
        <div className={styles.name}>
          <div>{card.firstName}</div>
          <div>{card.lastName}</div>
        </div>
      </div>
      <div className={styles.contact}>
        <div className={styles.icon}>
          <Email />
        </div>
        <div className={styles.value}>{card.email}</div>
      </div>
      <div className={styles.contact}>
        <div className={styles.icon}>
          <Phone />
        </div>
        <div className={styles.value}>{card.phone}</div>
      </div>
      {card.telegram && (
        <div className={styles.contact}>
          <div className={styles.icon}>
            <Telegram />
          </div>
          <div className={styles.value}>
            <a
              target="_blank"
              href={`https://t.me/${card.telegram}`}
            >{`https://t.me/${card.telegram}`}</a>
          </div>
        </div>
      )}
      {card.actualRequest && (
        <div className={styles.actualRequest}>
          <strong>Актуальный запрос:</strong> <span>{getActualRequest()}</span>
        </div>
      )}
    </div>
  );
};
