import { configureStore } from '@reduxjs/toolkit';

import { commonSlice } from './common';
import { authSlice } from '@features/auth/store/auth';
import { calendarSlice } from '@features/calendar/store/calendar';
import { clubSlice } from '@features/club/store/club';
import { communitySlice } from './community';
import { surveySlice } from '../features/survey/store/survey';
import { quickLinksSlice } from '../features/quick-links/store/quick-links';

export const store = configureStore({
  reducer: {
    common: commonSlice.reducer,
    auth: authSlice.reducer,
    calendar: calendarSlice.reducer,
    club: clubSlice.reducer,
    survey: surveySlice.reducer,
    community: communitySlice.reducer,
    quickLinks: quickLinksSlice.reducer,
  },
});
