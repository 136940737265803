import { PlainDict } from '../PlainDict/PlainDict';
import { OtherResources } from '../OtherResources/OtherResources';
import styles from './EditPlain.module.scss';

export const EditPlain = ({
  innerTab,
  items,
  type,
  onUpdate,
  onOtherUpdate,
  search,
  other,
  onOtherRemove,
  onOtherMove,
}) => {
  return (
    <div className={styles.edit}>
      {innerTab === 0 && (
        <PlainDict items={items} onUpdate={onUpdate} search={search} />
      )}
      {innerTab === 1 && (
        <OtherResources
          type={type}
          search={search}
          items={other}
          onRemove={onOtherRemove}
          onMove={onOtherMove}
          onUpdate={onOtherUpdate}
        />
      )}
    </div>
  );
};
