import { SurveyNav } from '../SurveyNav/SurveyNav';
import styles from './SurveyItem.module.scss';
import { SURVEY_SECTIONS } from '../../data/survey-sections';
import { SurveySection } from '../SurveySection/SurveySection';
import { useEffect, useRef, useState } from 'react';
import { SurveyProvider } from '../../context/SurveyContext';
import set from 'lodash/set';
import { AppButton } from '@ui/AppButton/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItem } from '../../store/survey';
import { Message } from '@mui/icons-material';
import { api } from '@lib/api';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../../../hooks/usePageTitle';

const TOP_SCROLL = 138;

export const SurveyItem = ({ cardId }) => {
  const [data, setData] = useState({});
  const navRef = useRef();
  const dispatch = useDispatch();
  const { item } = useSelector((state) => state.survey);
  const navigate = useNavigate();
  const pageTitle = usePageTitle();

  const navChanged = (name) => {
    const section = SURVEY_SECTIONS.find((section) => section.name === name);
    if (section && section.needConfirm) {
      update('confirm', true);
    }
    setTimeout(() => {
      const elem = document.querySelector(`[data-survey-section="${name}"]`);
      elem.scrollIntoView({
        behavior: 'smooth',
      });
    }, 0);
  };

  const onScroll = () => {
    if (!navRef.current) return;
    const top = window.scrollY;
    if (top < 136) {
      navRef.current.style.marginTop = '0';
      return;
    }
    navRef.current.style.marginTop = `${top - TOP_SCROLL}px`;
  };

  const update = (path, value) => {
    setData((prev) => {
      const data = { ...prev };
      set(data, path, value);
      return data;
    });
  };

  const save = async () => {
    const response = await api.post(`/dashboard/survey/item/`, data);
    navigate(`/survey/`);
  };

  useEffect(() => {
    if (!item) return;
    setData(item);
  }, [item]);

  useEffect(() => {
    dispatch(fetchItem(cardId));
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    if (!item) return;
    pageTitle(`Анкета ${item.firstName} ${item.lastName}`);
  }, [item]);

  const tabs = [...SURVEY_SECTIONS];

  return (
    <SurveyProvider value={{ data, update }}>
      <div className={styles.item}>
        <div className={styles.nav} ref={navRef}>
          <SurveyNav tabs={tabs} onChange={navChanged} />
        </div>
        <div className={styles.sections}>
          {SURVEY_SECTIONS.map(
            (section, index) =>
              (typeof data.confirm !== 'boolean' ||
                !section.needConfirm ||
                data.confirm) && (
                <SurveySection
                  key={section.name}
                  index={index + 1}
                  name={section.name}
                  icon={section.icon}
                  title={section.title}
                  component={section.component}
                />
              )
          )}
        </div>
      </div>
      <div className={styles.footer}>
        <AppButton onClick={save} startIcon={<Message />}>
          Отправить
        </AppButton>
      </div>
    </SurveyProvider>
  );
};
