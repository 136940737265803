import { useSelector } from 'react-redux';
import { AppSelect } from '@ui/AppSelect/AppSelect';

export const SpheresSelector = ({
  value = [],
  onChange,
  isMultiple = true,
}) => {
  const { spheres } = useSelector((state) => state.common);

  const defaultOptions = (isMultiple ? value : [value]).map((title) => ({
    label: title,
    value: title,
  }));

  const changed = (items) => {
    onChange(isMultiple ? items.map((item) => item.value) : items.value);
  };

  return (
    <AppSelect
      placeholder={`Выберите сфер${isMultiple ? 'ы' : 'у'} деятельности`}
      options={spheres.map((title) => ({ label: title, value: title }))}
      value={defaultOptions}
      onChange={changed}
      isMulti={isMultiple}
    />
  );
};
