import { useContext, useState } from 'react';
import styles from './DictResource.module.scss';
import { ResidentContext } from '../../../../context/ResidentContext';
import { SelectResourcesModal } from '../../../SelectResourcesModal/SelectResourcesModal';
import { useItemColor } from '../../../../hooks/useItemColor';
import { matchSorter } from 'match-sorter';
import { uniq } from 'lodash';

export const DictResource = ({ type, search }) => {
  const { data, update } = useContext(ResidentContext);
  const [showEdit, setShowEdit] = useState(false);
  const hasColor = ['competences', 'lobbies'].includes(type);
  const itemColor = useItemColor(type);

  const items = (data?.resources || {})[type]?.regular || [];

  const itemsChanged = (newItems) => {
    update(`resources.${type}.regular`, newItems);
    setShowEdit(false);
  };

  const getItems = () => {
    return uniq(items);
  };

  return (
    <div className={styles.dict}>
      <div className={styles.items}>
        {getItems().map((title) => (
          <div className={styles.item} key={title}>
            {hasColor && (
              <div
                className={styles.color}
                style={{ background: itemColor(title) }}
              ></div>
            )}
            <div className={styles.title}>{title}</div>
          </div>
        ))}
      </div>
      <div className={styles.button}>
        <button onClick={() => setShowEdit(true)}>Изменить</button>
      </div>
      {showEdit && (
        <SelectResourcesModal
          selected={items}
          onCancel={() => setShowEdit(false)}
          onSave={itemsChanged}
          type={type}
        />
      )}
    </div>
  );
};
