import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { AppButton } from '@ui/AppButton/AppButton';
import styles from './CloseConfirmation.module.scss';
import { Close } from '@mui/icons-material';

export const CloseConfirmation = ({
  title = 'Сохранение данных',
  onSave,
  onClear,
  onClose,
}) => {
  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div className={styles.close}>Вы внесли изменения. Сохранить?</div>
      </DialogContent>
      <DialogActions>
        <AppButton onClick={onSave} color="primary" variant="contained">
          Сохранить
        </AppButton>
        <AppButton onClick={onClear} color="secondary" variant="contained">
          Не сохранять
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};
