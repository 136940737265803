import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import axios from 'axios';

export const useGoogleAuth = (scope = process.env.REACT_APP_SCOPES) => {
  const [credentials, setCredentials] = useState(() => {
    const credentials = JSON.parse(
      localStorage.getItem('auth-login-credentials')
    );
    return credentials;
  });
  const [intervalId, setIntervalId] = useState(null);
  const [error, setError] = useState(null);

  const login = useGoogleLogin({
    scope: scope,
    onSuccess(credentials) {
      setCredentials({
        ...credentials,
        expiry: getExpiry(credentials.expires_in),
      });
    },
    onError(error) {
      setError(error);
    },
  });

  const getUserInfo = async () => {
    const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${credentials.access_token}`;
    const response = await axios.get(url);
    return response.data;
  };

  const saveCredentials = () => {
    localStorage.setItem('auth-login-credentials', JSON.stringify(credentials));
  };

  const getExpiry = (expires) => {
    const now = new Date();
    return new Date(now.getTime() + expires * 1000);
  };

  const getRemains = () => {
    const now = new Date();
    const expiry = new Date(credentials.expiry);
    const remains = expiry.getTime() - now.getTime();
    return remains;
  };

  const checkCredentials = () => {
    const remains = getRemains();
    if (remains < 0) {
      setCredentials(null);
    }
  };

  const logout = () => {
    window.gapi.auth.signOut();
    setCredentials(null);
    setError(null);
    clearInterval(intervalId);
  };

  useEffect(() => {
    saveCredentials();
    if (!credentials) {
      document.addEventListener('DOMContentLoaded', (ev) => {
        login(ev);
      });
      return;
    }
    const id = setInterval(checkCredentials, 1000);
    setIntervalId(id);
    return () => {
      clearInterval(id);
    };
  }, [credentials]);

  // useEffect(() => {
  //   login();
  // }, []);

  return {
    login,
    credentials,
    error,
    logout,
    getRemains,
    getUserInfo,
  };
};
