import styles from './Education.module.scss';
import { useContext, useEffect, useState } from 'react';
import { Delete } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { ResidentContext } from '../../../../context/ResidentContext';
import { ValidationContext } from '../../../../context/ValidationContext';
import { MAX_RESIDENT_EDUCATIONS } from '../../../../../../data/constants';
import { AddMoreFooter } from '../../../../../../components/ui/AddMoreFooter/AddMoreFooter';
import { EducationSelect } from '../../../../../../components/ui/EducationSelect/EducationSelect';
import { negativeUid } from '../../../../../../util/util';

export const Education = () => {
  const { data, update } = useContext(ResidentContext);
  const { errors, touched } = useContext(ValidationContext);
  const [educations, setEducations] = useState(data?.common?.educations || []);

  const canCreate = educations.length < MAX_RESIDENT_EDUCATIONS;

  const addEducation = () => {
    if (data.userId) return;
    setEducations((prev) => [
      ...prev,
      {
        id: negativeUid(),
      },
    ]);
  };

  const updateEducation = (education, data) => {
    if (data.userId) return;
    setEducations((prev) => {
      const items = [...prev];
      const index = items.indexOf(education);
      items.splice(index, 1, { ...data, id: education.id });
      return items;
    });
  };

  const removeEducation = (education) => {
    if (data.userId) return;
    setEducations((prev) => {
      const items = [...prev];
      const index = items.indexOf(education);
      items.splice(index, 1);
      return items;
    });
  };

  const hasMore = () => {
    return educations.length > 1;
  };

  const getErrors = (i) => {
    const err = {};
    const prefix = `educations.${i}.`;
    Object.keys(errors)
      .filter((key) => key.startsWith(prefix))
      .forEach((key) => {
        err[key.slice(prefix.length)] = errors[key];
      });
    return err;
  };

  const isDisabled = (edu) => {
    return data.disabledEducationsIds.includes(edu.id);
  };

  useEffect(() => {
    update('common.educations', educations);
  }, [educations]);

  useEffect(() => {
    if (!educations.length) addEducation();
  }, []);

  return (
    <div className={styles.education}>
      <div className={styles.list}>
        {educations.map((education, i) => (
          <div className={styles.item} key={education.id}>
            <div className={styles.select}>
              <EducationSelect
                disabled={isDisabled(education)}
                value={education}
                onChange={(data) => updateEducation(education, data)}
                errors={getErrors(i)}
                formSubmitted={touched.submit}
              />
            </div>
            {hasMore() && !data.cardId && !isDisabled(education) && (
              <div className={styles.remove}>
                <IconButton onClick={() => removeEducation(education)}>
                  <Delete />
                </IconButton>
              </div>
            )}
          </div>
        ))}
      </div>
      {canCreate && !data.userId && <AddMoreFooter onClick={addEducation} />}
    </div>
  );
};
