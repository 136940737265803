import styles from './Heading.module.scss';

export const Heading = ({ level = 1, title, icon, children }) => {
  const Icon = icon;

  return (
    <div className={styles.heading} data-level={level}>
      {Icon && (
        <div className={styles.icon}>
          <Icon color="primary" fontSize="large" />
        </div>
      )}
      <div className={styles.title}>{title}</div>
      {!!children && <div className={styles.toolbar}>{children}</div>}
    </div>
  );
};
