import { ResidentCard } from '../ResidentCard/ResidentCard';
import styles from './ResidentsCards.module.scss';

export const ResidentsCards = ({ cards, onClick }) => {
  return (
    <div className={styles.cards}>
      {!cards.length && (
        <div className={styles.empty}>Ни одной карточки не найдено.</div>
      )}
      {cards.map((card) => (
        <ResidentCard key={card.id} card={card} onClick={() => onClick(card)} />
      ))}
    </div>
  );
};
