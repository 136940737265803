import styles from './TopLevelSelect.module.scss';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogActions,
} from '@mui/material';
import { AppButton } from '@ui/AppButton/AppButton';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { RadioItem } from '../../../../components/ui/RadioItem/RadioItem';
import { useState } from 'react';

export const TopLevelSelect = ({ type, onCancel, onSelect }) => {
  const label = { competences: 'компетенции', lobbies: 'лобби-интереса' }[type];
  const sections = useSelector((state) => state.common[type]);
  const [selected, setSelected] = useState(null);

  const select = () => {
    onSelect(selected);
  };

  return (
    <Dialog open={true} onClose={onCancel} className="wide-dialog">
      <DialogTitle>
        Перемещение {label}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent
        sx={{ width: '456px', overflowX: 'hidden' }}
        className="paddingless"
      >
        <div className={styles.select}>
          <div className={styles.description}>
            Выберите раздел для перемещения {label}:
          </div>
          <div className={styles.sections}>
            {sections.map((section) => (
              <div
                className={styles.section}
                onClick={() => setSelected(section.title)}
              >
                <div className={styles.radio}>
                  <RadioItem isChecked={section.title === selected} />
                </div>
                <div
                  className={styles.color}
                  style={{ background: section.color }}
                ></div>
                <div className={styles.title}>{section.title}</div>
              </div>
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <AppButton variant="contained" color="secondary" onClick={onCancel}>
          Отмена
        </AppButton>
        &nbsp;
        <AppButton variant="contained" color="primary" onClick={select}>
          Переместить
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};
