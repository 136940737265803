import { Error } from '@mui/icons-material';
import styles from './ExcludeButton.module.scss';

export const ExcludeButton = ({ onClick, isRestore = false, onRemove }) => {
  const title = isRestore ? 'Восстановить резидента' : 'Переместить в архив';
  const description = isRestore ? (
    <>
      <p>
        Карточка резидента будет перемещена в раздел активных резидентов и будет
        отображаться в сервисе «Ресурсная карта» для других резидентов. Резидент
        получит доступ к данным сообщества на платформе.
      </p>
    </>
  ) : (
    <>
      <p>
        Карточка резидента будет перемещена в архив и не будет отображаться в
        сервисе «Ресурсная карта» для других резидентов.
      </p>
      <p>Резидент лишится доступа к данным сообщества на платформе.</p>
    </>
  );

  return (
    <>
      <div className={styles.exclude} data-restore={isRestore ? 'restore' : ''}>
        <button className={styles.button} onClick={onClick}>
          <div className={styles.icon}>
            <Error />
          </div>
          <div className={styles.title}>{title}</div>
        </button>
        {/* <div className={styles.description}>{description}</div> */}
      </div>
      {isRestore && (
        <div className={styles.exclude}>
          <button className={styles.button} onClick={onRemove}>
            <div className={styles.icon}>
              <Error />
            </div>
            <div className={styles.title}>Удалить навсегда</div>
          </button>
          {/* <div className={styles.description}>{description}</div> */}
        </div>
      )}
    </>
  );
};
