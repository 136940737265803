import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import ruLocale from 'date-fns/locale/ru';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TextField } from '@mui/material';
import moment from '@util/moment';

import styles from './DatePicker.module.scss';

export const DatePicker = ({ value, placeholder, onChange, disabled }) => {
  const dateChanged = (date) => {
    onChange && onChange(date);
  };

  return (
    <div className={styles.picker + ' datepicker'}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={ruLocale}
      >
        <DesktopDatePicker
          label={placeholder}
          // ignoreInvalidInputs={true}
          inputFormat="dd.MM.yyyy"
          value={value ? moment(value, 'DD.MM.YYYY').toDate() : null}
          onChange={(date) => dateChanged(moment(date).format('DD.MM.YYYY'))}
          className={styles.dateInput}
          error={false}
          slots={
            {
              // field: () => (
              //   <TextField
              //     sx={{ width: '100%' }}
              //     disabled={disabled}
              //     className={styles.dateInput}
              //     value={value}
              //     onChange={onChange}
              //     placeholder={placeholder}
              //   />
              // ),
            }
          }
        />
      </LocalizationProvider>
    </div>
  );
};
