import { useEffect, useRef, useState } from 'react';
import styles from './StatusSettings.module.scss';
import { Close, Delete } from '@mui/icons-material';

export const StatusSettings = ({
  tag,
  onChange,
  coords,
  onClose,
  onRemove,
  allOptions,
}) => {
  const inputRef = useRef();
  const [value, setValue] = useState(tag || '');

  const isInvalid =
    tag !== value &&
    (!value.trim() ||
      allOptions.map((option) => option.trim()).includes(value.trim()));

  const getStyle = () => {
    return {
      left: `${coords[0] + 30}px`,
      top: `${coords[1] - 13}px`,
    };
  };

  const hideSettings = () => {
    onClose && onClose();
  };

  const clicked = (ev) => {
    ev.stopPropagation();
  };

  const inputKeyUp = (ev) => {
    if (ev.which !== 13 || isInvalid) return;
    if (!value) {
      onRemove && onRemove();
    } else {
      onChange && onChange(value);
    }
    hideSettings();
  };

  useEffect(() => {
    inputRef.current.select();
    document.addEventListener('click', hideSettings);
    return () => {
      document.removeEventListener('click', hideSettings);
    };
  }, []);

  return (
    <div className={styles.settings} style={getStyle()} onClick={clicked}>
      <div className={styles.input} data-invalid={isInvalid ? 'invalid' : ''}>
        <input
          type="text"
          value={value}
          onChange={(ev) => setValue(ev.target.value)}
          onKeyUp={inputKeyUp}
          ref={inputRef}
        />
      </div>
      <div className={styles.close} onClick={onClose}>
        <Close />
      </div>
      <div className={styles.remove} onClick={() => onRemove(value)}>
        <div className={styles.icon}>
          <Delete />
        </div>
        <div className={styles.label}>Удалить</div>
      </div>
    </div>
  );
};
