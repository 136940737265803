import { Page } from '@layouts/Page/Page';
import { Heading } from '@layouts/Heading/Heading';
import {
  CalendarToday,
  Google,
  Lightbulb,
  Settings,
} from '@mui/icons-material';

import styles from './Calendar.module.scss';
import { Events } from '../../features/calendar/components/Events/Events';
import { AuthPlaceholder } from '../../features/calendar/components/AuthPlaceholder/AuthPlaceholder';
import { ImportPlaceholder } from '../../features/calendar/components/ImportPlaceholder/ImportPlaceholder';
import { GoogleUserContext } from '@context/GoogleUserContext';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppAlert } from '@ui/AppAlert/AppAlert';
import { api } from '@lib/calendar';
import { Button } from '@mui/material';
import { GoogleOptionsModal } from '@features/calendar/components/GoogleOptionsModal/GoogleOptionsModal';
import { useNavigate } from 'react-router-dom';
import { usePageTitle } from '../../hooks/usePageTitle';

export const CalendarPage = () => {
  const calendarUid = useSelector((state) => state?.auth?.options?.calendarUid);
  const {
    user: googleUser,
    login,
    logout,
    credentials,
  } = useContext(GoogleUserContext);
  const [showInfoAlert, setShowInfoAlert] = useState(
    // () => localStorage.getItem('show-info-calendar-alert') !== 'false'
    false
  );
  const [showGoogleOptions, setShowGoogleOptions] = useState(false);
  const navigate = useNavigate();
  const pageTitle = usePageTitle();

  pageTitle('Календарь событий');

  console.log('UID', calendarUid);

  const infoAlertClicked = () => {
    localStorage.setItem('show-info-calendar-alert', 'false');
    setShowInfoAlert(false);
  };

  const showEvents = async () => {
    const events = await api.getEventsForUpdate(calendarUid);
  };

  const googleOptionsClicked = () => {
    navigate(`/calendar/options/`);
  };

  useEffect(() => {
    if (!googleUser || !calendarUid) return;
    showEvents();
  }, [googleUser, calendarUid]);

  // useEffect(() => {
  //   console.log('CREDENTIALS', credentials);
  // }, [credentials]);

  return (
    <Page
      breadcrumbs={[
        {
          title: 'Календарь событий',
          icon: CalendarToday,
          url: '/calendar/',
        },
      ]}
    >
      <div className={styles.calendar}>
        <Heading icon={CalendarToday} title="Календарь событий">
          <Button
            startIcon={<Settings />}
            color="primary"
            variant="contained"
            onClick={googleOptionsClicked}
          >
            Управление календарем Google
          </Button>
        </Heading>
        {!googleUser && !calendarUid && <AuthPlaceholder />}
        {googleUser && !calendarUid && <ImportPlaceholder />}
        {calendarUid && (
          <Events>
            {calendarUid && !googleUser && (
              <AppAlert
                color="warning"
                icon={Google}
                title="Авторизация в Google"
                description="Выполните авторизацию с аккаунтом Google, чтобы возобновить импорт событий"
                buttonTitle="Войти"
                onButtonClick={login}
              />
            )}
            {calendarUid && googleUser && showInfoAlert && (
              <AppAlert
                color="warning"
                icon={Lightbulb}
                title="События уже опубликованы, но нужно уточнить детали"
                description="Внесите дополнительную информацию о типе события, оплате, формате участия в карточке события"
                buttonTitle="Хорошо"
                onButtonClick={infoAlertClicked}
              />
            )}
          </Events>
        )}
      </div>
    </Page>
  );
};
