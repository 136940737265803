import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './EventModal.module.scss';
import { Close, Google } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calendarSlice, fetchEvent, updateEvent } from '../../store/calendar';
import { EventTabs } from './EventTabs/EventTabs';
import { EventInfo } from './EventInfo/EventInfo';
import { EventParticipants } from './EventParticipants/EventParticipants';
import { EventPayment } from './EventPayment/EventPayment';
import { EventMetrics } from './EventMetrics/EventMetrics';
import { usePageTitle } from '../../../../hooks/usePageTitle';

export const EventModal = () => {
  const params = useParams();
  const navigate = useNavigate();
  const event = useSelector((state) => state.calendar.event);
  const dispatch = useDispatch();
  const [tab, setTab] = useState(window.location?.hash?.slice(1) || 'info');
  const pageTitle = usePageTitle();

  const modalClosed = () => {
    navigate('/calendar/');
    dispatch(calendarSlice.actions.setEvent(null));
  };

  const eventUpdated = (data) => {
    // dispatch(updateEvent(data));
    // modalClosed();
    dispatch(
      calendarSlice.actions.setEvent({
        ...event,
        ...data,
      })
    );
  };

  const cancelButtonClicked = () => {
    modalClosed();
  };

  const openEditEvent = () => {
    // window.open(event.editLink, '_blank');
  };

  const saveButtonClicked = () => {
    dispatch(updateEvent(event));
    modalClosed();
    dispatch(calendarSlice.actions.setEvent(null));
  };

  useEffect(() => {
    dispatch(fetchEvent(params.eventId));
  }, []);

  useEffect(() => {
    pageTitle(event?.title);
  }, [event]);

  useEffect(() => {
    window.location.hash = tab;
  }, [tab]);

  if (!event) return null;

  return (
    <Dialog open={true} onClose={modalClosed} className="wide-dialog">
      <DialogTitle>
        Информация о событии
        <IconButton
          aria-label="close"
          onClick={modalClosed}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '1052px' }} className="paddingless">
        <div className={styles.modal}>
          <div className={styles.tabs}>
            <EventTabs tab={tab} onChange={setTab} />
          </div>
          <div className={styles.content} style={{ width: '100%' }}>
            {tab === 'info' && (
              <EventInfo
                event={event}
                onClose={modalClosed}
                onUpdate={eventUpdated}
              />
            )}
            {tab === 'participants' && <EventParticipants event={event} />}
            {tab === 'payment' && <EventPayment />}
            {tab === 'metrics' && <EventMetrics />}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <div className={styles.remove}>
          <Button
            startIcon={<Google />}
            color="error"
            variant="text"
            size="small"
            onClick={openEditEvent}
          >
            Удалить
          </Button>
        </div>
        <div className={styles.cancel}>
          <Button
            color="info"
            variant="contained"
            onClick={cancelButtonClicked}
          >
            Отмена
          </Button>
        </div>
        <div className={styles.save}>
          <Button
            color="primary"
            variant="contained"
            onClick={saveButtonClicked}
          >
            Сохранить изменения
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
