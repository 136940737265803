import { useEffect, useRef, useState } from 'react';
import styles from './AppTooltip.module.scss';
import { createPortal } from 'react-dom';

export const AppTooltip = ({ text, children, className }) => {
  const elemRef = useRef();
  const [showTooltip, setShowTooltip] = useState(false);
  const [coords, setCoords] = useState([0, 0]);

  return (
    <>
      <div
        ref={elemRef}
        className={className}
        onMouseOver={() => {
          const elem = elemRef.current;
          const b = elem.getBoundingClientRect();
          setCoords([b.x - 230, b.y - (32 - b.height / 2)]);
          setTimeout(() => {
            setShowTooltip(true);
          }, 0);
        }}
        onMouseOut={() => setShowTooltip(false)}
      >
        {children}
      </div>
      {showTooltip &&
        createPortal(
          <div
            className={styles.tooltip}
            style={{ top: `${coords[1]}px`, left: `${coords[0]}px` }}
          >
            <div className={styles.text}>{text}</div>
            <div className={styles.angleContainer}>
              <div className={styles.angle}></div>
            </div>
          </div>,
          document.body
        )}
    </>
  );
};
