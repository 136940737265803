import { useSelector } from 'react-redux';

import styles from './GlobalProgress.module.scss';
import { useEffect, useState } from 'react';

export const GlobalProgress = () => {
  const progress = useSelector((state) => state.common.progress);
  const [active, setActive] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    if (progress) {
      clearTimeout(timeoutId);
      setActive(true);
    } else {
      setTimeoutId(
        setTimeout(() => {
          setActive(false);
        }, 500)
      );
    }
  }, [progress]);

  return (
    <div className={styles.progress}>
      <div className={styles.filler} data-active={active ? 'active' : ''}></div>
    </div>
  );
};
