import styles from './SelectItem.module.scss';
import { CheckBox } from '@ui/CheckBox/CheckBox';

export const SelectItem = ({ title, checked, onCheck, color, padded }) => {
  const checkboxClicked = (ev) => {
    ev.stopPropagation();
    onCheck();
  };

  return (
    <div
      className={styles.item}
      onClick={onCheck}
      data-color={color ? 'color' : ''}
      data-padded={padded ? 'padded' : ''}
    >
      <div className={styles.checkbox}>
        <CheckBox checked={checked} onChange={checkboxClicked} />
      </div>
      {color && (
        <div className={styles.color}>
          <div className={styles.circle} style={{ background: color }}></div>
        </div>
      )}
      <div className={styles.title}>{title}</div>
    </div>
  );
};
