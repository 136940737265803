import { createSlice } from '@reduxjs/toolkit';
import { api } from '@lib/api';
import { startProgress } from '../../../store/common';

export const surveySlice = createSlice({
  name: 'survey',
  initialState: {
    items: [],
    item: null,
  },
  reducers: {
    setItems(state, action) {
      state.items = action.payload;
    },
    setItem(state, action) {
      state.item = action.payload;
    },
  },
});

export const fetchItems = () => async (dispatch) => {
  const response = await api.get(`/dashboard/survey/items/`);
  dispatch(surveySlice.actions.setItems(response.data));
};

export const fetchItem = (cardId) => async (dispatch) => {
  const response = await api.get(`/dashboard/survey/item/`, {
    params: {
      cardId,
    },
  });
  dispatch(surveySlice.actions.setItem(response.data.item));
};
