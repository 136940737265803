import ReactInputMask from 'react-input-mask';
import { ContactInput } from '../ContactInput/ContactInput';

export const PhoneInput = ({ error, blurred, ...props }) => {
  return (
    <ReactInputMask
      mask="+7 999 999 99 99"
      value={props.value}
      onChange={props.onChange}
      disabled={false}
      maskChar=" "
      onBlur={blurred}
    >
      {() => <ContactInput error={error} {...props} />}
    </ReactInputMask>
  );
  return;
};
