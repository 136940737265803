import styles from './SurveySection.module.scss';

export const SurveySection = ({ name, icon, title, component, index }) => {
  const Icon = icon;
  const Component = component;

  return (
    <div className={styles.wrapper} data-survey-section={name}>
      <div className={styles.section}>
        <div className={styles.header}>
          <div className={styles.icon}>
            <Icon />
          </div>
          <div className={styles.index}>{index}.</div>
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.content}>
          <Component />
        </div>
      </div>
    </div>
  );
};
