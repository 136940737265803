import { NavLink } from 'react-router-dom';
import styles from './LinkCard.module.scss';
import { ArrowForward } from '@mui/icons-material';

export const LinkCard = ({ url, title, icon, onClick, count }) => {
  const Icon = icon;

  const cardClicked = () => {
    onClick && onClick();
  };

  const card = (
    <div className={styles.card} onClick={cardClicked}>
      <div className={styles.header}>
        <div className={styles.icon}>
          <Icon color="primary" />
        </div>
        <div className={styles.title}>{title}</div>
        {!!count && (
          <div className={styles.count}>
            <span>{count}</span>
          </div>
        )}
      </div>
      <div className={styles.footer}>
        <div className={styles.icon}>
          <ArrowForward color="primary" />
        </div>
      </div>
    </div>
  );

  return url ? <NavLink to={url}>{card}</NavLink> : card;
};
