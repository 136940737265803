import { useCallback, useEffect, useState } from 'react';
import { AppSelect } from '../AppSelect/AppSelect';
import { api } from '@lib/api';

export const CitySelect = ({
  value,
  onChange,
  isMultiple,
  includeId,
  countryId,
  name,
  isNative = false,
  error = false,
  disabled,
}) => {
  // const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const changed = (items) => {
    if (isNative) {
      onChange({
        target: {
          value: items.value,
          name: name,
        },
      });
      return;
    }
    if (!items) return onChange(null);
    onChange(
      isMultiple
        ? items.map((item) =>
            includeId ? `${item.label}|${item.value}` : item.label
          )
        : includeId
        ? `${items.label}|${items.value}`
        : items.label
    );
  };

  const fetchOptions = useCallback(
    async (q = '') => {
      setIsLoading(true);
      const response = await api.get(`/profile/places/cities/`, {
        params: {
          q,
          countryId,
        },
      });
      setIsLoading(false);
      return response.data.cities.map((val) => ({
        label: val.split('|')[0],
        value: val.split('|')[includeId ? 1 : 0],
      }));
    },
    [countryId, includeId]
  );

  const fetchDefaultOptions = useCallback(async () => {
    const options = await fetchOptions('');
    setDefaultOptions(options);
  }, [fetchOptions]);

  useEffect(() => {
    fetchDefaultOptions();
  }, [countryId, fetchDefaultOptions]);

  // useEffect(() => {
  //   if (value) {
  //     if (isMultiple) {
  //       setSelected(value.map((val) => ({ label: val, value: val })));
  //     } else {
  //       setSelected({ label: value, value: value });
  //     }
  //   }
  // }, []);

  // useEffect(() => {
  //   if (!selected) return;
  //   if (isMultiple) {
  //     onChange(
  //       selected.map((item) =>
  //         includeId ? `${item.label}|${item.value}` : item.value
  //       )
  //     );
  //   } else {
  //     if (!selected) onChange(null);
  //     onChange(
  //       includeId ? `${selected.label}|${selected.value}` : selected.value
  //     );
  //   }
  // }, [selected]);

  // useEffect(() => {
  //   setSelected(isMultiple ? [] : null);
  // }, [countryId]);

  return (
    <AppSelect
      isAsync
      value={
        isMultiple
          ? value.map((val) => ({
              label: val.split('|')[0],
              value: includeId ? val.split('|')[1] : val.split('|')[0],
            }))
          : value && {
              label: value.split('|')[0],
              value: includeId ? value.split('|')[1] : value.split('|')[0],
            }
      }
      onChange={changed}
      placeholder={isMultiple ? 'Выберите города' : 'Выберите город'}
      isMulti={isMultiple}
      defaultOptions={defaultOptions}
      loadOptions={fetchOptions}
      isLoading={isLoading}
      isClearable={true}
      error={error}
      isDisabled={disabled}
    />
  );
};
