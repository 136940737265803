import styles from './SurveyNavItem.module.scss';

export const SurveyNavItem = ({ icon, children, isActive, onClick, index }) => {
  const Icon = icon;

  return (
    <div
      className={styles.item}
      data-active={isActive ? 'active' : ''}
      onClick={onClick}
    >
      <div className={styles.icon}>
        <Icon fontSize="small" />
      </div>
      <div className={styles.index}>{index}.</div>
      <div className={styles.title}>{children}</div>
    </div>
  );
};
