import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './ExcludeConfirmation.module.scss';
import { Close, ReportProblem } from '@mui/icons-material';
import { AppButton } from '@ui/AppButton/AppButton';

export const ExcludeConfirmation = ({ onConfirm, onCancel }) => {
  return (
    <Dialog open={true} onClose={onCancel} className="wide-dialog">
      <DialogTitle>
        Переместить в архив
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '512px', overflowX: 'hidden' }}>
        <div className={styles.exclude}>
          <div className={styles.icon}>
            <ReportProblem />
          </div>
          <div className={styles.description}>
            Резидент лишится доступа к данным сообщества на платформе.
            Информация о резиденте сохранится в карточке в архиве
          </div>
        </div>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <AppButton color="error" onClick={onConfirm}>
          Переместить
        </AppButton>
        <AppButton color="primary" onClick={onCancel}>
          Отмена
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};
