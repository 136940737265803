import { matchSorter } from 'match-sorter';
import { ResourceItem } from '@ui/ResourceItem/ResourceItem';
import styles from './OtherResources.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '@lib/api';
import { authSlice } from '@features/auth/store/auth.js';

export const OtherResources = ({
  type,
  items,
  onRemove,
  onMove,
  search,
  onUpdate,
}) => {
  const { options } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const getItems = () => {
    return matchSorter(items, search, { keys: ['title'] });
  };

  const removeNew = async (item) => {
    if (
      !options?.brand[`new${type[0].toUpperCase()}${type.slice(1)}`]?.includes(
        item.title
      )
    )
      return;
    const response = await api.delete(`/dashboard/club/remove-new/`, {
      params: {
        type,
        title: item.title,
      },
    });
    dispatch(authSlice.actions.setOptions(response.data));
  };

  const itemUpdated = async (item, newTitle) => {
    await api.patch(`/dashboard/dicts/rename-other/`, {
      type,
      oldTitle: item.title,
      newTitle,
    });
    onUpdate();
  };

  return (
    <div className={styles.other}>
      <div className={styles.list}>
        {getItems().map((item) => (
          <ResourceItem
            title={item.title}
            count={item.count}
            key={item.title}
            // onRemove={() => onRemove(item)}
            onMove={() => onMove(item)}
            isNew={(options?.brand || {})[
              `new${type[0].toUpperCase()}${type.slice(1)}`
            ]?.includes(item.title)}
            onHover={() => removeNew(item)}
            onUpdate={(newTitle) => itemUpdated(item, newTitle)}
            validate={(title) => {
              if (!title || items.map((item) => item.titel).includes(title))
                return false;
              return /^[А-ЯЁа-яёA-z\d\s\-()'"]{2,80}$/.test(title);
            }}
          />
        ))}
      </div>
    </div>
  );
};
