import { Link } from 'react-router-dom';
import styles from './Breadcrumb.module.scss';

export const Breadcrumb = ({ title, icon, isLast, url }) => {
  const Icon = icon;

  return (
    <Link to={url} className={styles.item} data-last={isLast ? 'last' : ''}>
      <div className={styles.icon}>
        <Icon />
      </div>
      <div className={styles.title}>{title}</div>
    </Link>
  );
};
