import { FormControl, IconButton } from '@mui/material';
import styles from './AddMore.module.scss';
import { FooterButton } from '@ui/FooterButton/FooterButton';
import { TextInput } from '@ui/TextInput/TextInput';
import { Check, Close } from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';

export const AddMore = ({ onAdd, exclude = [], validate }) => {
  const [isAdd, setIsAdd] = useState(false);
  const inputRef = useRef();
  const [addTitle, setAddTitle] = useState('');

  const addItem = () => {
    const title = addTitle;
    setAddTitle('');
    setIsAdd(false);
    onAdd(title);
  };

  const addClicked = () => {
    setIsAdd(true);
  };

  const keyPressed = (ev) => {
    if (ev.which !== 13 || isExclude(addTitle)) return;
    addItem();
  };

  const isExclude = (title) => {
    if (validate) {
      return !validate(title);
    }
    return exclude
      .map((title) => title.toLowerCase().trim())
      .includes(title.toLowerCase().trim());
  };

  const close = () => {
    setIsAdd(false);
    setAddTitle('');
  };

  const error = isExclude(addTitle);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [inputRef]);

  return (
    <div className={styles.more}>
      {!isAdd && <FooterButton type="add" onClick={addClicked} />}
      {isAdd && (
        <div className={styles.add}>
          <div className={styles.input}>
            <FormControl fullWidth>
              <TextInput
                error={error}
                size="small"
                isFocus
                value={addTitle}
                onChange={(ev) => setAddTitle(ev.target.value)}
                onKeyPress={keyPressed}
              />
            </FormControl>
          </div>
          <div className={styles.buttons}>
            <IconButton onClick={addItem} disabled={error}>
              <Check />
            </IconButton>
            <IconButton onClick={close}>
              <Close />
            </IconButton>
          </div>
        </div>
      )}
    </div>
  );
};
