import { useState } from 'react';
import { DropdownButton } from '../DropdownButton/DropdownButton';
import styles from './Dropdown.module.scss';

export const Dropdown = ({ icon, title, options, value, onChange }) => {
  const [expanded, setExpanded] = useState(false);

  const optionClicked = (option) => {
    onChange && onChange(option.value);
    setExpanded(false);
  };

  return (
    <div className={styles.dropdown}>
      <div className={styles.button}>
        <DropdownButton
          icon={icon}
          title={title}
          expanded={expanded}
          onChange={setExpanded}
        />
      </div>
      {expanded && (
        <div className={styles.options}>
          {options.map((option) => (
            <div
              className={styles.option}
              key={option.value}
              onClick={() => optionClicked(option)}
            >
              <div
                className={styles.marker}
                data-active={value === option.value ? 'active' : ''}
              >
                <div className={styles.filler}></div>
              </div>
              <div className={styles.title}>{option.title}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
