import { SectionHeader } from '../../ResidentModal/SectionHeader/SectionHeader';
import styles from './Invite.module.scss';

export const Invite = () => {
  return (
    <div className={styles.invite}>
      <SectionHeader
        title="Этот раздел для приглашения резидентов"
        description="Мы импортировали данные вашего сообщества в базу. У резидентов уже есть цифровые карточки в административной панели. Для регистрации и верификации резидентов на платформе отправьте каждому уникальную ссылку‑приглашение."
      />
    </div>
  );
};
