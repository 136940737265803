import { GridView, TableRows } from '@mui/icons-material';

import styles from './ViewSwitch.module.scss';

export const ViewSwitch = ({ view, onChange }) => {
  return (
    <div className={styles.switch}>
      <div
        className={styles.option}
        data-active={view === 'table' ? 'active' : ''}
        onClick={() => onChange('table')}
      >
        <TableRows />
      </div>
      <div
        className={styles.option}
        data-active={view === 'cards' ? 'active' : ''}
        onClick={() => onChange('cards')}
      >
        <GridView />
      </div>
    </div>
  );
};
