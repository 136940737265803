import { GlobalProgress } from '@ui/GlobalProgress/GlobalProgress';
import { Page } from '../Page/Page';
import { Sidebar } from '../Sidebar/Sidebar';

import styles from './Layout.module.scss';

export const Layout = ({ children }) => {
  return (
    <div className={styles.layout}>
      <GlobalProgress />
      <div className={styles.body}>
        <div className={styles.sidebar}>
          <Sidebar />
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};
