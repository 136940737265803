import { Button } from '@mui/material';
import styles from './FooterButton.module.scss';
import { Add, Delete } from '@mui/icons-material';

export const FooterButton = ({ type, onClick, title = null }) => {
  const t = title || (type === 'add' ? 'Добавить еще' : 'Удалить');
  return (
    <Button
      className={styles.button}
      data-type={type}
      startIcon={type === 'add' ? <Add /> : <Delete />}
      color={type === 'add' ? 'primary' : 'error'}
      variant="text"
      onClick={onClick}
    >
      {t}
    </Button>
  );
};
