import { BarChart, CreditCard, Group, Home } from '@mui/icons-material';
import styles from './EventTabs.module.scss';
import { SidebarTabs } from '@ui/SidebarTabs/SidebarTabs';

export const EventTabs = ({ tab, onChange }) => {
  const tabs = [
    {
      name: 'info',
      icon: Home,
      title: 'Основное',
    },
    {
      name: 'participants',
      icon: Group,
      title: 'Участники',
    },
    {
      name: 'payment',
      icon: CreditCard,
      title: 'Оплата',
    },
    {
      name: 'metrics',
      icon: BarChart,
      title: 'Метрики',
    },
  ];
  return (
    <div className={styles.tabs}>
      <SidebarTabs
        tabs={tabs}
        value={tab}
        onChange={onChange}
        borderless={true}
      />
    </div>
  );
};
