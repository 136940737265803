import { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Check, Close } from '@mui/icons-material';
import { TextInput } from '@ui/TextInput/TextInput';
import { HexColorPicker } from 'react-colorful';
import { AppButton } from '@ui/AppButton/AppButton';

import styles from './EditSectionModal.module.scss';

export const EditSectionModal = ({ section, type, onSave, onCancel }) => {
  const [color, setColor] = useState(section?.color || '');
  const [title, setTitle] = useState(section?.title || '');

  const saveSection = () => {
    onSave({
      color,
      title,
    });
  };

  return (
    <Dialog open={true} onClose={onCancel} className="wide-dialog">
      <DialogTitle>
        {section ? 'Редактирование' : 'Добавление'} секции{' '}
        {type === 'competences' ? 'компетенций' : 'лобби-интересов'}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '400px', overflowX: 'hidden' }}>
        <div className={styles.edit}>
          <div className={styles.section}>
            <div className={styles.label}>Название</div>
            <div className={styles.input}>
              <TextInput
                value={title}
                onChange={(ev) => setTitle(ev.target.value)}
                size="small"
                className={styles.title}
                sx={{ width: '100%', display: 'block' }}
              />
            </div>
          </div>
          <div className={styles.section}>
            <div className={styles.label}>Цвет</div>
            <div className={styles.input}>
              <HexColorPicker color={color} onChange={setColor} />
            </div>
          </div>
          <div className={styles.save}>
            <AppButton
              size="small"
              startIcon={<Check />}
              variant="contained"
              onClick={saveSection}
            >
              Сохранить
            </AppButton>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
