import { useState } from 'react';

export const useInitGapi = (credentials = {}) => {
  const [done, setDone] = useState(false);

  async function init(credentials) {
    try {
      await load();
      await window.gapi.client.init({
        access_token: credentials.access_token,
        discoveryDocs: [process.env.REACT_APP_DISCOVERY_DOC],
        scope: process.env.REACT_APP_SCOPES,
      });
      window.gapi.client.setToken(credentials);
      setDone(true);
    } catch (err) {
      console.log(err);
    }
  }

  function load() {
    return new Promise((resolve, reject) => {
      window.gapi.load('client', () => {
        resolve();
      });
    });
  }

  return { init, done };
};
