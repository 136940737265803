import { useEffect, useState } from 'react';
import { CountrySelect } from '../CountrySelect/CountrySelect';
import { Field } from '@ui/Field/Field';
import { CitySelect } from '../CitySelect/CitySelect';
import { UniversitySelect } from '../UniversitySelect/UniversitySelect';
import { FacultySelect } from '../FacultySelect/FacultySelect';
import { ChairSelect } from '../ChairSelect/ChairSelect';
import { YearSelect } from '../YearSelect/YearSelect';
import { TextInput } from '../TextInput/TextInput';
import { FormControl } from '@mui/material';
import { FieldError } from '../FieldError/FieldError';

export const EducationSelect = ({
  value,
  onChange,
  errors,
  formSubmitted,
  disabled,
}) => {
  const [country, setCountry] = useState(value?.country || null);
  const [city, setCity] = useState(value?.city || null);
  const [university, setUniversity] = useState(value?.university || null);
  const [faculty, setFaculty] = useState(value?.faculty || null);
  const [chair, setChair] = useState(value?.chair || null);
  const [year, setYear] = useState(value?.year || null);
  const [position, setPosition] = useState(value?.position || '');

  useEffect(() => {
    onChange &&
      onChange({
        id: value?.id,
        country,
        city,
        university,
        faculty,
        chair,
        year,
        position,
      });
  }, [country, city, university, faculty, chair, year, position]);

  return (
    <>
      <Field label="Страна">
        <CountrySelect
          value={country}
          onChange={setCountry}
          error={formSubmitted && errors.country}
          disabled={disabled}
        />
        {formSubmitted && errors.country && (
          <FieldError>{errors.country}</FieldError>
        )}
      </Field>
      {country && (
        <Field label="Город">
          <CitySelect
            value={city}
            onChange={setCity}
            countryId={country.split('|')[1]}
            includeId={true}
            error={formSubmitted && errors.city}
            disabled={disabled}
          />
          {formSubmitted && errors.city && (
            <FieldError>{errors.city}</FieldError>
          )}
        </Field>
      )}
      {city && (
        <Field label="ВУЗ">
          <UniversitySelect
            value={university}
            onChange={setUniversity}
            cityId={city.split('|')[1]}
            error={formSubmitted && errors.university}
            disabled={disabled}
          />
          {formSubmitted && errors.university && (
            <FieldError>{errors.university}</FieldError>
          )}
        </Field>
      )}
      {university && (
        <Field label="Год выпуска">
          <YearSelect value={year} onChange={setYear} disabled={disabled} />
        </Field>
      )}
      {university && (
        <Field label="Факультет">
          <FacultySelect
            value={faculty}
            onChange={setFaculty}
            universityId={university.split('|')[1]}
            disabled={disabled}
          />
        </Field>
      )}
      {faculty && (
        <Field label="Кафедра">
          <ChairSelect
            value={chair}
            onChange={setChair}
            facultyId={faculty.split('|')[1]}
            disabled={disabled}
          />
        </Field>
      )}
      {faculty && (
        <Field label="Специальность">
          <FormControl fullWidth>
            <TextInput
              disabled={disabled}
              value={position}
              onChange={(ev) => setPosition(ev.target.value)}
              error={formSubmitted && errors.position}
            />
            {formSubmitted && errors.position && (
              <FieldError>{errors.position}</FieldError>
            )}
          </FormControl>
        </Field>
      )}
    </>
  );
};
