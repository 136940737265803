import { FormControl, IconButton } from '@mui/material';
import styles from './JobBlock.module.scss';
import { Field } from '@ui/Field/Field';
import { TextInput } from '@ui/TextInput/TextInput';
import { FieldSelector } from '../FieldsSelector/FieldSelector';
import { RadioItem } from '../../../../components/ui/RadioItem/RadioItem';
import { Delete } from '@mui/icons-material';
import { useState } from 'react';
import { FieldError } from '../../../../components/ui/FieldError/FieldError';

export const JobBlock = ({
  value,
  onChange,
  showDelete = true,
  errors,
  onDelete,
  formSubmitted,
  disabled,
  forbidMain,
}) => {
  const [touched, setTouched] = useState(false);

  const update = (name, val) => {
    const newValue = {
      ...value,
      [name]: val,
    };
    onChange(newValue);
  };

  const setMain = () => {
    if (forbidMain) return;
    update(`isMain`, true);
  };

  const isError = touched && !value?.place?.trim();

  return (
    <div className={styles.job} data-delete={showDelete ? 'delete' : ''}>
      <div className={styles.header} onClick={setMain}>
        <div className={styles.radio}>
          <RadioItem
            isChecked={value.isMain}
            onCheck={() => update('isMain', true)}
          />
        </div>
        <div className={styles.label}>Основное направление</div>
      </div>
      <Field label="Компания">
        <FormControl fullWidth>
          <TextInput
            value={value.place}
            onChange={(ev) => update('place', ev.target.value)}
            onBlur={() => setTouched(true)}
            error={isError || (formSubmitted && errors.place)}
            disabled={disabled}
          />
          {((touched && isError) || (formSubmitted && errors.place)) && (
            <FieldError>{errors.place || 'Укажите компанию'}</FieldError>
          )}
        </FormControl>
      </Field>
      <Field label="Должность">
        <FormControl fullWidth>
          <TextInput
            value={value.position}
            onChange={(ev) => update('position', ev.target.value)}
            error={formSubmitted && errors.position}
            disabled={disabled}
          />
          {formSubmitted && errors.position && (
            <FieldError>{errors.position}</FieldError>
          )}
        </FormControl>
      </Field>
      <Field label="Сферы деятельности">
        <FormControl fullWidth>
          <FieldSelector
            isMultiple
            value={value.fieldsIds}
            onChange={(vals) => update('fieldsIds', vals)}
            error={formSubmitted && errors.fieldsIds}
            disabled={disabled}
          />
          {formSubmitted && errors.fieldsIds && (
            <FieldError>{errors.fieldsIds}</FieldError>
          )}
        </FormControl>
      </Field>
      {!disabled && (
        <div className={styles.footer}>
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        </div>
      )}
    </div>
  );
};
