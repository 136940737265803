import { Group, MenuBook, Person } from '@mui/icons-material';
import { Page } from '../../layouts/Page/Page';
import { Heading } from '@layouts/Heading/Heading';

import styles from './Club.module.scss';
import { LinkCard } from '@ui/LinkCard/LinkCard';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { usePageTitle } from '../../hooks/usePageTitle';

export const ClubFooter = () => {
  const { brand } = useSelector((state) => state.auth.options);
  const pageTitle = usePageTitle();

  pageTitle('Ресурсная карта');

  const count =
    brand?.newSpheres?.length ||
    0 + brand?.newCompetences?.length ||
    0 + brand?.newLobbies?.length ||
    0;

  return (
    <div className={styles.footer}>
      <LinkCard icon={Person} title="Резиденты" url="/club/residents/" />
      <LinkCard
        icon={MenuBook}
        title="Справочники"
        url="/club/dicts/spheres/"
        count={count}
      />
    </div>
  );
};

export const ClubPage = () => {
  return (
    <Page
      breadcrumbs={[
        {
          title: 'Ресурсная карта',
          icon: Group,
          url: '/club/',
        },
      ]}
      footer={<ClubFooter />}
    >
      <div className={styles.club}>
        <div className={styles.heading}>
          <Heading icon={Group} title="Ресурсная карта" />
        </div>
        <div className={styles.title}>
          Управление ресурсами вашего сообщества
        </div>
        <div className={styles.description}>
          В этом разделе вы можете систематизировать данные о резидентах
          вашего сообщества. Данные синхронизированы с профилем резидента на
          платформе.
        </div>
      </div>
      <Outlet />
    </Page>
  );
};
