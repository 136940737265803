import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './ExcludedSuccess.module.scss';
import { Close } from '@mui/icons-material';

export const ExcludedSuccess = ({ onClose, isRestore }) => {
  return (
    <Dialog open={true} onClose={onClose} className="wide-dialog">
      <DialogTitle>
        {isRestore ? 'Восстановление резидентства' : 'Успешно'}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '400px', overflowX: 'hidden' }}>
        <div className={styles.success}>
          {isRestore ? 'Резидентство пользователя в сообществе восстановлено' : 'Карточка резидента в архиве'}
        </div>
      </DialogContent>
    </Dialog>
  );
};
