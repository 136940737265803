import { useSelector } from 'react-redux';
import styles from './Lobbies.module.scss';
import { useContext } from 'react';
import { SurveyContext } from '../../context/SurveyContext';
import { TreeSelector } from '../TreeSelector/TreeSelector';
import { AppSelect } from '@ui/AppSelect/AppSelect';
import { useGroupItems } from '../../../../hooks/useGroupSpheres';
import { flatten } from 'lodash';
import { Field } from '@ui/Field/Field';

export const Lobbies = () => {
  const { lobbies } = useSelector((state) => state.common);
  const { data, update } = useContext(SurveyContext);
  const { regular, other } = useGroupItems(
    flatten(
      lobbies.map((section) => section.items.map((item) => item.title))
    ).filter((item) => !!item),
    data.lobbies?.filter((item) => !!item) || []
  );

  const checked = data?.lobbies || [];

  const [regularOptions, otherOptions] = [regular, other].map((items) =>
    items.map((item) => ({ label: item, value: item }))
  );

  const updateLobbies = (items, extra) => {
    update('lobbies', [...items, ...extra]);
  };

  return (
    <div className={styles.section}>
      <div className="bubble">
        <p>
          Один из разделов сервиса Картирования это Лобби-возможности. Речь идет
          о связях, когда где-то вам или вашему соратнику нужно решить вопрос,
          который упирается в кого-то конкретно, либо в какое-то ведомство или
          иные бизнес- или госструктуры. Мы предоставляем вам возможность найти
          необходимые контакты и связи среди одноклубников. Важно учесть, что
          сервис работает в закрытом, анонимном формате, т.е. Сервис шифрует
          данные и общение с запрашиваемым происходит анонимно. Для того, чтобы
          уточнить ваши возможности, мне необходимо будет понять, в каких сферах
          на уровнях Лиц, Принимающих Решения (ЛПР), ваши контакты могли бы
          пригодиться одноклубникам? То есть, чем вы готовы поделиться с
          соратниками в закрытом формате?
        </p>

        <p>
          Сейчас я Вам задам вопросы, а затем продиктую перечни ведомств и
          организаций и если у Вас есть контакты и желание ими поделиться
          сообщите мне.
        </p>
      </div>
      <TreeSelector
        sections={lobbies}
        type="lobbies"
        checked={checked}
        onChange={(items) => update('lobbies', items)}
      />
      <br />
      <Field label="Другие лообби-интересы">
        <AppSelect
          isMulti
          isCreatable
          placeholder="Введите название лобби"
          formatCreateLabel={(val) => `Добавить лобби «${val}»`}
          noOptionsMessage="Введите название лобби"
          value={otherOptions}
          isClearable={true}
          onChange={(items) =>
            updateLobbies(
              items.map((item) => item.value),
              regular
            )
          }
        />
      </Field>
    </div>
  );
};
