// Layout ========================================================================

export const COMMUNITY_LOGO_URLS = {
  default: '/images/logo.svg',
  'Клуб Лидеров Москва': '/images/communities/leaders-club.png',
};

// Theme =========================================================================

export const THEMES = {
  LIGHT: 'light',
  DARK: 'dark',
};
export const lightTheme = (theme) => theme.palette.mode === 'light';
export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;

export const MAX_RESIDENT_JOBS = 5;
export const MAX_RESIDENT_EDUCATIONS = 5;
export const MAX_RESIDENT_PROJECTS = 5;

export const MAX_RESIDENT_STATUS_COUNT = 10;

export const MAX_EXTRA_ACTUAL_REQUESTS = 2;
