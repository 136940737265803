import { useEffect, useState } from 'react';
import styles from './AddContact.module.scss';

export const AddContact = ({
  component,
  onAdd,
  onCancel,
  values,
  validate = () => true,
}) => {
  const [value, setValue] = useState('');
  const [isError, setIsError] = useState(false);
  const [isTouched, setIsTouched] = useState(false);
  const Component = component;

  const keyPressed = (ev) => {
    if (ev.which !== 13 || isExisting) return;
    if (ev.which === 13) {
      setIsTouched(true);
      if (isError) return;
    }
    onAdd && onAdd(value);
  };

  const inputBlurred = (ev) => {
    setIsTouched(true);
    if (isError) return;
    onAdd && onAdd(value);
    // onCancel && onCancel();
  };

  useEffect(() => {
    setIsError(!validate(value));
  }, [value]);

  const isExisting = values
    .map((val) => val?.trim().toLowerCase())
    .includes(value?.trim().toLowerCase());

  return (
    <div className={styles.add}>
      <Component
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        onKeyUp={keyPressed}
        error={isExisting || (isError && isTouched)}
        blurred={inputBlurred}
      />
    </div>
  );
};
