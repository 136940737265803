import { createContext } from 'react';

export const ResidentContext = createContext();

export const ResidentProvider = ({ value = {}, children }) => {
  return (
    <ResidentContext.Provider value={value}>
      {children}
    </ResidentContext.Provider>
  );
};
