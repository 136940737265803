import { uniq } from 'lodash';
import { SelectItem } from '@ui/SelectItem/SelectItem';
import styles from './Selected.module.scss';
import { useItemColor } from '../../../hooks/useItemColor';

export const Selected = ({ selected, onCheck, type }) => {
  const items = uniq(selected);
  const itemColor = useItemColor(type);

  const emptyLabel = {
    spheres: 'Не выбрано ни одной сферы деятельности.',
    competences: 'Не выбрано ни одной компетенции.',
    lobbies: 'Не выбрано ни одного лобби-интереса.',
  }[type];

  return (
    <div className={styles.selected}>
      {items.map((title) => (
        <SelectItem
          key={title}
          title={title}
          checked={true}
          onCheck={() => onCheck(title)}
          color={itemColor(title)}
        />
      ))}
      {!items.length && <div className={styles.empty}>{emptyLabel}</div>}
    </div>
  );
};
