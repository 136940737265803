import { createContext } from 'react';

export const ValidationContext = createContext();

export const ValidationProvider = ({ value = {}, children }) => {
  return (
    <ValidationContext.Provider value={value}>
      {children}
    </ValidationContext.Provider>
  );
};
