import { FormControl } from '@mui/material';
import ReactInputMask from 'react-input-mask';
import moment from 'moment/moment';
import { TextInput } from '@ui/TextInput/TextInput';

export const DateInput = ({
  value,
  onChange,
  min = '01.01.1920',
  max = '01.01.2100',
  ...props
}) => {
  const isDateValid = () => {
    if (!/\d/.test(value)) return true;
    const reg = /\d{2}\.\d{2}\.\d{4}/;
    if (!reg.test(value)) return false;
    const [date, month, year] = value.split('.').map((val) => +val);
    const d = moment({ date, month: month - 1, year });
    const maxDate = max === 'current' ? moment() : moment(max, 'DD.MM.YYYY');
    const minDate = moment(min, 'DD.MM.YYYY');
    if (
      !d.isValid() ||
      d.toDate().getTime() > maxDate.toDate().getTime() ||
      d.toDate().getTime() < minDate.toDate().getTime()
    )
      return false;
    return true;
  };

  return (
    <FormControl fullWidth>
      <ReactInputMask
        mask="99.99.9999"
        value={value}
        onChange={onChange}
        disabled={false}
        maskChar="_"
        alwaysShowMask={true}
        {...props}
      >
        {() => (
          <TextInput
            error={!isDateValid() || props.error}
            disabled={props.disabled}
          />
        )}
      </ReactInputMask>
    </FormControl>
  );
};
