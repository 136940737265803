import {
  AccountCircle,
  Add,
  CalendarToday,
  Close,
  Dashboard,
  ExpandMore,
  Group,
  Logout,
  Poll,
  SupportAgent,
} from '@mui/icons-material';
import { Logo } from '../Logo/Logo';

import styles from './Sidebar.module.scss';
import { SidebarItem } from '../SidebarItem/SidebarItem';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { QuickLinks } from '../../features/quick-links/components/QuickLinks/QuickLinks';

export const Sidebar = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);

  const path = location.pathname;

  return (
    <div className={styles.sidebar}>
      <Logo />
      <div className={styles.items}>
        {user.isManager && (
          <>
            <NavLink to="">
              <SidebarItem
                title="Панель управления"
                leftIcon={Dashboard}
                isActive={path === '/'}
              />
            </NavLink>
            <NavLink to="/calendar/">
              <SidebarItem
                title="Календарь событий"
                leftIcon={CalendarToday}
                isActive={path.startsWith('/calendar')}
              />
            </NavLink>
            <NavLink to="/club/">
              <SidebarItem
                title="Ресурсная карта"
                leftIcon={Group}
                isActive={path.startsWith('/club')}
              />
            </NavLink>
          </>
        )}
        {user.isInterviewer && (
          <NavLink to="/survey/">
            <SidebarItem
              title="Опрос резидентов"
              leftIcon={Poll}
              isActive={path.startsWith('/survey')}
            />
          </NavLink>
        )}
        {/* <QuickLinks /> */}
        {/* <SidebarItem
          title="Быстрые ссылки"
          leftIcon={ExpandMore}
          rightIcon={Add}
          isContainer={true}
        >
          <NavLink to="/">
            <SidebarItem
              leftIcon={CalendarToday}
              title="Добавление события"
              rightIcon={Close}
              isSub={true}
            />
          </NavLink>
          <NavLink to="/calendar/">
            <SidebarItem
              leftIcon={CalendarToday}
              title="Добавление резидента"
              rightIcon={Close}
              isSub={true}
            />
          </NavLink>
          <NavLink to="/club/">
            <SidebarItem
              leftIcon={CalendarToday}
              title="Название"
              rightIcon={Close}
              isSub={true}
            />
          </NavLink>
        </SidebarItem> */}
        <div className={styles.footer}>
          <NavLink to="/support/">
            <SidebarItem
              title="Поддержка"
              leftIcon={SupportAgent}
              isActive={path.startsWith('/support')}
            />
          </NavLink>
          <a href={process.env.REACT_APP_UNAUTH_REDIRECT}>
            <SidebarItem title="Вернуться на платформу" leftIcon={Logout} />
          </a>
        </div>
      </div>
    </div>
  );
};
