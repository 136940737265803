import { Button } from '@mui/material';
import styles from './AuthPlaceholder.module.scss';
import { useContext } from 'react';
import { GoogleUserContext } from '@context/GoogleUserContext';

export const AuthPlaceholder = () => {
  const { login } = useContext(GoogleUserContext);

  return (
    <div className={styles.placeholder}>
      <div className={styles.header}>
        Этот раздел для управления календарем мероприятий вашего сообщества
      </div>
      <div className={styles.content}>
        <p>
          Чтобы начать работу с событиями, авторизуйтесь с помощью
          Google‑аккаунта, к которому привязан календарь вашего сообщества.{' '}
        </p>
        <p>
          Это нужно для синхронизации и автоматического обновления изменений,
          внесённых в Google‑календарь.
        </p>
      </div>
      <div className={styles.button}>
        <Button color="primary" variant="contained" onClick={login}>
          Авторизоваться
        </Button>
      </div>
    </div>
  );
};
