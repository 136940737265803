import { useState } from 'react';
import { ResourceItem } from '@ui/ResourceItem/ResourceItem';
import styles from './PlainDict.module.scss';
import { matchSorter } from 'match-sorter';
import { Delete } from '@mui/icons-material';
import { ToolbarButton } from '@ui/ToolbarButton/ToolbarButton';
import { AddMore } from '../../../../club/components/AddMore/AddMore';
import { RemoveConfirmation } from '../../RemoveConfirmation/RemoveConfirmation';

export const PlainDict = ({ items, onUpdate, search }) => {
  const [checked, setChecked] = useState([]);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

  const addItem = (title) => {
    onUpdate([...items, { title }]);
  };

  const itemChecked = (item) => {
    setChecked((prev) => {
      const items = [...prev];
      const index = items.indexOf(item);
      if (index > -1) {
        items.splice(index, 1);
      } else {
        items.push(item);
      }
      return items;
    });
  };

  const getItems = () => {
    if (!search || search.length < 2) return items;
    return matchSorter(items, search, {
      keys: ['title'],
    });
  };

  const removeItem = (data) => {
    onUpdate(items.filter((item) => item.title !== data.title));
  };

  const itemUpdated = (item, newTitle) => {
    const items = [...items];
    const index = items.findIndex((i) => i.title === item.title);
    if (index < 0) return;
    items.splice(index, 1, { ...item, title: newTitle });
    onUpdate(items);
  };

  const removeChecked = () => {
    setShowRemoveConfirmation(false);
    const titlesToRemove = checked.map((item) => item.title);
    onUpdate(items.filter((item) => !titlesToRemove.includes(item.title)));
    setChecked([]);
  };

  return (
    <>
      <div className={styles.dict}>
        {!!checked.length && (
          <div className={styles.remove}>
            <ToolbarButton
              icon={<Delete />}
              onClick={() => setShowRemoveConfirmation(true)}
            >
              Удалить из справочника
            </ToolbarButton>
          </div>
        )}
        <div className={styles.list}>
          {getItems().map((item) => (
            <ResourceItem
              checked={checked.includes(item)}
              onCheck={() => itemChecked(item)}
              title={item.title}
              onRemove={() => removeItem(item)}
              onUpdate={(newTitle) => itemUpdated(item, newTitle)}
              key={item.title}
            />
          ))}
        </div>
        <div className={styles.footer}>
          <AddMore onAdd={addItem} exclude={items.map((item) => item.title)} />
        </div>
      </div>
      {showRemoveConfirmation && (
        <RemoveConfirmation
          type="spheres"
          onDecline={() => setShowRemoveConfirmation(false)}
          onAccept={removeChecked}
        />
      )}
    </>
  );
};
