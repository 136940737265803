import styles from './ActualRequest.module.scss';
import { FormControl, IconButton } from '@mui/material';
import { TextInput } from '@ui/TextInput/TextInput';
import { AddMoreButton } from './AddMoreButton/AddMoreButton';
import { MAX_EXTRA_ACTUAL_REQUESTS } from '../../../../../../data/constants';
import { Field } from '../../../../../../components/ui/Field/Field';
import { Delete, Error } from '@mui/icons-material';
import { useContext, useState } from 'react';
import { ResidentContext } from '@features/club/context/ResidentContext';
import { AppTooltip } from '../../../../../../components/ui/AppTooltip/AppTooltip';

export const ActualRequest = () => {
  const { data, update } = useContext(ResidentContext);

  const items = data.actualRequests || [];

  const add = () => {
    update('actualRequests', [...items, '']);
  };

  const extraHeader = (
    <div className={styles.header}>
      <AppTooltip
        text={`Эта информация не отобразится в профиле пользователя и будет видна только вам`}
        className={styles.info}
      >
        <Error />
      </AppTooltip>
      <div className={styles.label}>Дополнительный актуальный запрос</div>
    </div>
  );

  return (
    <div className={styles.actual}>
      <div className={styles.main}>
        <FormControl fullWidth>
          <TextInput
            value={data.common.about.actualRequest}
            onChange={(ev) =>
              update('common.about.actualRequest', ev.target.value)
            }
            disabled={!!data.userId}
          />
        </FormControl>
      </div>
      <div className={styles.extra}>
        {items.map((val, i) => (
          <div className={styles.request} key={val + i}>
            <Field label={extraHeader}>
              <FormControl fullWidth className={styles.input}>
                <TextInput
                  value={val}
                  onChange={(ev) => {
                    const extra = [...items];
                    extra[i] = ev.target.value;
                    update('actualRequests', extra);
                  }}
                  autoFocus
                />
                <IconButton
                  onClick={() => {
                    const extra = [...items];
                    extra.splice(i, 1);
                    update('actualRequests', extra);
                  }}
                  className={styles.icon}
                >
                  <Delete />
                </IconButton>
              </FormControl>
            </Field>
          </div>
        ))}
      </div>
      {items.length < MAX_EXTRA_ACTUAL_REQUESTS && (
        <div className={styles.add}>
          <AddMoreButton onClick={add} />
        </div>
      )}
    </div>
  );
};
