import { useSelector } from 'react-redux';
import { AppSelect } from '@ui/AppSelect/AppSelect';
import styles from './FieldSelector.module.scss';

export const FieldSelector = ({
  value = null,
  onChange,
  isMultiple = false,
  error = false,
  disabled,
}) => {
  const { fields } = useSelector((state) => state.common);
  const options = fields.map((field) => ({
    label: field.title,
    value: field.id,
  }));

  const defaultOptions = isMultiple
    ? options.filter((option) => value.includes(option.value))
    : value
    ? [options.find((option) => option.id === value)]
    : [];

  const changed = (item) => {
    if (isMultiple) {
      onChange(item.map((i) => i.value));
      return;
    }
    onChange(item.value);
  };

  return (
    <AppSelect
      placeholder={`Выберите сферу деятельности`}
      options={options}
      value={defaultOptions}
      onChange={changed}
      isMulti={isMultiple}
      error={error}
      isDisabled={disabled}
    />
  );
};
