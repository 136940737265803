import { useGoogleLogin } from '@react-oauth/google';
import { useEffect, useState } from 'react';
import { useInitGapi } from './useInitGapi';

export const useGoogleCredentials = () => {
  const [credentials, setCredentials] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [error, setError] = useState(null);
  const { init, done } = useInitGapi();

  const getExpiry = (expires) => {
    const now = new Date();
    return new Date(now.getTime() + expires * 1000);
  };

  const saveCredentials = (credentials) => {
    localStorage.setItem('auth-login-credentials', JSON.stringify(credentials));
  };

  const getRemains = (credentials) => {
    const now = new Date();
    const expiry = new Date(credentials.expiry);
    const remains = expiry.getTime() - now.getTime();
    return remains;
  };

  const restoreCredentials = () => {
    const content = localStorage.getItem('auth-login-credentials');
    if (!content) {
      setCredentials(null);
      return;
    }
    const data = JSON.parse(content);
    if (!data) {
      setCredentials(null);
      saveCredentials(null);
      return;
    }
    const remains = getRemains(data);
    if (remains <= 3000) {
      setCredentials(null);
      saveCredentials(null);
      return;
    }
    setCredentials(data);
  };

  const login = useGoogleLogin({
    scope: process.env.REACT_APP_SCOPES,
    onSuccess(credentials) {
      const data = {
        ...credentials,
        expiry: getExpiry(credentials.expires_in),
      };
      setCredentials(data);
      saveCredentials(data);
    },
    onError(error) {
      setError(error);
    },
  });

  const checkCredentials = () => {
    if (!credentials) return;
    const remains = getRemains(credentials);
    if (remains <= 3000) {
      setCredentials(null);
      saveCredentials(null);
    }
  };

  const logout = () => {
    window?.gapi?.auth?.signOut();
    setCredentials(null);
    saveCredentials(null);
  };

  useEffect(() => {
    restoreCredentials();
  }, []);

  useEffect(() => {
    if (credentials) {
      const intervalId = setInterval(() => {
        checkCredentials();
      }, 1000);
      init(credentials);
      setIntervalId(intervalId);
      return () => clearInterval(intervalId);
    } else {
      clearInterval(intervalId);
    }
  }, [credentials]);

  return { credentials, login, logout, error, init, done };
};
