import { sortBy } from 'lodash';
import styles from './LobbiesDict.module.scss';
import { useState } from 'react';
import { matchSorter } from 'match-sorter';
import { ResourceItem } from '@ui/ResourceItem/ResourceItem';
import { AddMoreFooter } from '@ui/AddMoreFooter/AddMoreFooter';
import { ArrowDownward } from '@mui/icons-material';
import { AddMore } from '../../../../club/components/AddMore/AddMore';
import { ToolbarButton } from '@ui/ToolbarButton/ToolbarButton';
import { Delete } from '@mui/icons-material';

export const LobbiesDict = ({ items, search, onUpdate }) => {
  const [checked, setChecked] = useState([]);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const isShowAll = showAll || !!(search + '').trim().length;

  const addItem = (title) => {
    onUpdate([...items, { title, count: 0 }]);
  };

  const itemChecked = (item) => {
    setChecked((prev) => {
      const items = [...prev];
      const index = items.indexOf(item);
      if (index > -1) {
        items.splice(index, 1);
      } else {
        items.push(item);
      }
      return items;
    });
  };

  const removeItem = (data) => {
    onUpdate(items.filter((item) => item.title !== data.title));
  };

  const removeChecked = () => {
    setShowRemoveConfirmation(false);
    const titlesToRemove = checked.map((item) => item.title);
    onUpdate(items.filter((item) => !titlesToRemove.includes(item.title)));
    setChecked([]);
  };

  const itemUpdated = (item, newTitle) => {
    const newItems = [...items];
    const index = newItems.findIndex((i) => i.title === item.title);
    if (index < 0) return;
    newItems.splice(index, 1, {
      ...item,
      oldTitle: item.title,
      title: newTitle,
    });
    onUpdate(newItems);
  };

  const getFullItems = () => {
    return matchSorter(
      sortBy(
        items.filter((item) => !!item.count),
        'count'
      ).reverse(),
      search,
      { keys: ['title'] }
    );
  };

  const getEmptyItems = () => {
    if (!isShowAll) return [];
    return matchSorter(
      sortBy(
        items.filter((item) => !item.count),
        'count'
      ),
      search,
      { keys: ['title'] }
    );
  };

  const getItems = () => {
    return [...getFullItems(), ...getEmptyItems()];
  };

  return (
    <div className={styles.dict}>
      {!!checked.length && (
        <div className={styles.toolbar}>
          <ToolbarButton icon={<Delete />} onClick={removeChecked}>
            Удалить
          </ToolbarButton>
        </div>
      )}
      <div className={styles.list}>
        {getItems().map((item) => (
          <ResourceItem
            checked={checked.includes(item)}
            onCheck={() => itemChecked(item)}
            title={item.title}
            onRemove={() => removeItem(item)}
            onUpdate={(newTitle) => itemUpdated(item, newTitle)}
            key={item.title}
            count={item.count}
          />
        ))}
      </div>
      {!isShowAll && (
        <div className={styles.showAll}>
          <AddMoreFooter
            onClick={() => setShowAll(true)}
            label="Показать все"
            Icon={ArrowDownward}
          />
        </div>
      )}
      <div className={styles.addMore}>
        <AddMore onAdd={addItem} exclude={items.map((item) => item.title)} />
      </div>
    </div>
  );
};
