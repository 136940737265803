import { useContext, useEffect, useState } from 'react';
import { JobBlock } from '../../../JobBlock/JobBlock';
import styles from './Job.module.scss';
import { ResidentContext } from '../../../../context/ResidentContext';
import { ValidationContext } from '../../../../context/ValidationContext';
import { AddMoreFooter } from '../../../../../../components/ui/AddMoreFooter/AddMoreFooter';
import { negativeUid } from '../../../../../../util/util';

function getDefaultItem(isMain = false) {
  return {
    id: negativeUid(),
    position: '',
    place: '',
    fieldsIds: [],
    isMain: isMain,
  };
}

export const Job = () => {
  const { data, update } = useContext(ResidentContext);
  const { errors, touched } = useContext(ValidationContext);
  const [items, setItems] = useState(
    data.common.jobs?.length ? data.common.jobs : [getDefaultItem(true)]
  );

  const canCreate = false;

  const addJob = () => {
    setItems((prev) => [...prev, getDefaultItem()]);
  };

  const isDisabled = (job) => {
    return data.disabledJobsIds.includes(job.id);
  };

  const updateItem = (item, newValue) => {
    if (isDisabled(item)) return;
    setItems((prev) => {
      let items = [...prev];
      const index = items.indexOf(item);
      if (index < 0) return;
      if (newValue.isMain) {
        items = items.map((item) => ({ ...item, isMain: false }));
      }
      items.splice(index, 1, {
        ...item,
        ...newValue,
      });
      return items;
    });
  };

  const removeItem = (item) => {
    if (isDisabled(item)) return;
    setItems((prev) => {
      let items = [...prev];
      items = items.filter((i) => i !== item);
      if (item.isMain) {
        const lastItem = items[items.length - 1];
        items.splice(items.length - 1, 1, {
          ...lastItem,
          isMain: true,
        });
      }
      return items;
    });
  };

  const getErrors = (i) => {
    const err = {};
    const prefix = `jobs.${i}.`;
    Object.keys(errors)
      .filter((key) => key.startsWith(prefix))
      .forEach((key) => {
        err[key.slice(prefix.length)] = errors[key];
      });
    return err;
  };

  useEffect(() => {
    update('common.jobs', items);
  }, [items]);

  return (
    <div className={styles.job}>
      {items.map((item, i) => (
        <JobBlock
          key={item.id}
          value={item}
          onChange={(newValue) => updateItem(item, newValue)}
          showDelete={i > 0}
          onDelete={() => removeItem(item)}
          errors={getErrors(i)}
          formSubmitted={touched.submit}
          disabled={isDisabled(item)}
          forbidMain={!!data.userId}
        />
      ))}
      {canCreate && <AddMoreFooter onClick={addJob} />}
    </div>
  );
};
