import {
  AccountBox,
  Assistant,
  Business,
  ExpandCircleDown,
  Info,
  Insights,
  PieChart,
} from '@mui/icons-material';
import { Profile } from '../components/Profile/Profile';
import { Hello } from '../components/Hello/Hello';
import { About } from '../components/About/About';
import { Fields } from '../components/Fields/Fields';
import { Competences } from '../components/Competences/Competences';
import { Lobbies } from '..//components/Lobbies/Lobbies';
import { More } from '../components/More/More';

export const SURVEY_SECTIONS = [
  {
    name: 'profile',
    icon: AccountBox,
    title: 'Профиль',
    component: Profile,
    needConfirm: false,
  },
  {
    name: 'hello',
    icon: Assistant,
    title: 'Приветствие',
    component: Hello,
    needConfirm: false,
  },
  {
    name: 'about',
    icon: Info,
    title: 'Преамбула о проекте и сервисе',
    component: About,
    needConfirm: true,
  },
  {
    name: 'fields',
    icon: Business,
    title: 'Отрасли',
    component: Fields,
    needConfirm: true,
  },
  {
    name: 'competences',
    icon: PieChart,
    title: 'Компетенции',
    component: Competences,
    needConfirm: true,
  },
  {
    name: 'lobbies',
    icon: Insights,
    title: 'Лобби-интересы',
    component: Lobbies,
    needConfirm: true,
  },
  {
    name: 'more',
    icon: ExpandCircleDown,
    title: 'Дальнейшие инструкции',
    component: More,
    needConfirm: true,
  },
];
