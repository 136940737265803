import styles from './RadioItem.module.scss';

export const RadioItem = ({ isChecked, onCheck }) => {
  return (
    <div
      className={styles.radio}
      data-checked={isChecked ? 'checked' : ''}
      onClick={onCheck}
    >
      <div className={styles.cursor}></div>
    </div>
  );
};
