import { createContext, useEffect, useState } from 'react';
import { useGoogleUser } from '../hooks/useGoogleUser';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useInitGapi } from '../hooks/useInitGapi';
import { useGoogleCredentials } from '../hooks/useGoogleCredentials';

export const GoogleUserContext = createContext(null);

const GOOGLE_USER = {
  email: 'test@gmail.com',
};

export const GoogleUserProvider = ({ children }) => {
  const { credentials, login, logout, error, init, done } =
    useGoogleCredentials();
  const [user, setUser] = useState(GOOGLE_USER);

  console.log('CREDENTIALS', credentials);

  const getUserInfo = async () => {
    return GOOGLE_USER;
    // const url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${credentials.access_token}`;
    // const response = await axios.get(url);
    // return response.data;
  };

  const updateUserInfo = async () => {
    const info = await getUserInfo();
    setUser(info);
  };

  useEffect(() => {
    if (!credentials || !done) {
      setUser(GOOGLE_USER);
      return;
    }
    updateUserInfo();
  }, [credentials, done]);

  const value = {
    credentials,
    login,
    logout,
    error,
    init,
    done,
    user,
  };

  return (
    <GoogleUserContext.Provider value={value}>
      {children}
    </GoogleUserContext.Provider>
  );
};
