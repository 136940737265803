import styles from './More.module.scss';

export const More = () => {
  return (
    <div className={styles.section}>
      <div className="bubble">
        <p>
          У меня вопросы исчерпаны. Спасибо вам за содержательную беседу и
          развернутые ответы. Сейчас я отправлю вам на указанную почту
          уникальную ссылку-приглашение на платформу. Вы сможете быстро пройти
          регитсрацию, потому что информация в вашем профиле уже заполненна на
          основе данных этой анкеты.{' '}
        </p>
        <p>Хорошего вам дня!</p>
      </div>
    </div>
  );
};
