import { OtherResources } from '../OtherResources/OtherResources';
import { TreeResource } from '../TreeResource/TreeResource';
import styles from './EditTree.module.scss';

export const EditTree = ({
  type,
  innerTab,
  sections,
  other,
  onUpdate,
  onOtherUpdate,
  search,
  onMove,
}) => {
  return (
    <div className={styles.edit}>
      {innerTab === 0 && (
        <TreeResource
          type={type}
          sections={sections}
          onUpdate={onUpdate}
          search={search}
          onMove={onMove}
        />
      )}
      {innerTab === 1 && (
        <OtherResources
          type={type}
          items={other}
          search={search}
          onUpdate={onOtherUpdate}
          onMove={onMove}
        />
      )}
    </div>
  );
};
