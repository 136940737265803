import { Poll } from '@mui/icons-material';
import { Page } from '../../layouts/Page/Page';
import styles from './Survey.module.scss';
import { Heading } from '../../layouts/Heading/Heading';
import { SurveyTable } from '../../features/survey/components/SurveyTable/SurveyTable';
import { usePageTitle } from '../../hooks/usePageTitle';

export const Survey = () => {
  const pageTitle = usePageTitle();

  pageTitle('Опросник резидентов');

  return (
    <Page
      breadcrumbs={[
        {
          title: 'Опросник резидентов',
          icon: Poll,
          url: '/survey/',
        },
      ]}
    >
      <div className={styles.survey}>
        <Heading title={`Опросник резидентов`} />
        <SurveyTable />
      </div>
    </Page>
  );
};
