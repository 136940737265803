import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import styles from './ImportModal.module.scss';
import { Close } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { api } from '@lib/calendar';
import { useDispatch } from 'react-redux';
import { updateOptions } from '../../../auth/store/auth';

export const ImportModal = ({ onClose }) => {
  const [uid, setUid] = useState('-1');
  const [calendars, setCalendars] = useState([
    { id: '-1', summary: 'Календарь' },
  ]);
  const dispatch = useDispatch();

  const updateUid = () => {
    dispatch(
      updateOptions({
        calendarUid: uid,
      })
    );
  };

  const updateCalendars = async () => {
    const calendars = await api.calendars();
    setCalendars([{ id: '-1', summary: 'Календарь' }, ...calendars]);
  };

  useEffect(() => {
    updateCalendars();
  }, []);

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogTitle>
        Импорт календаря
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <Close color="disabled" />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent sx={{ width: '600px' }}>
        <div className={styles.modal}>
          <div className={styles.description}>
            <p>
              Прикрепите ссылку на Google-календарь вашего сообщества
              и импортируйте события.
            </p>
            <p>
              После этого, события будут доступны в админ-панели для менеджера и
              на платформе для резидентов сообщества.
            </p>
          </div>
          <div className={styles.link}>
            <a href="" target="_blank">
              Посмотреть подробную инструкцию
            </a>
          </div>
          <div className={styles.calendar}>
            <div className={styles.select}>
              <FormControl fullWidth>
                {/* <InputLabel id="demo-simple-select-label">Календарь</InputLabel> */}
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={uid}
                  // label="Age"
                  onChange={(ev) => setUid(ev.target.value)}
                  // size="small"
                >
                  {calendars.map((calendar) => (
                    <MenuItem key={calendar.id} value={calendar.id}>
                      {calendar.summary}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={styles.button}>
              <Button color="primary" variant="contained" onClick={updateUid}>
                Импортировать
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
};
