import { useContext } from 'react';
import styles from './GoogleAuth.module.scss';
import { GoogleUserContext } from '@context/GoogleUserContext';
import { Button } from '@mui/material';
import { Login, Logout } from '@mui/icons-material';

export const GoogleAuth = () => {
  const { user, login, logout } = useContext(GoogleUserContext);

  return (
    <div className={styles.auth}>
      <div className={styles.content}>
        {user ? (
          <>
            <div className={styles.email}>{user.email}</div>
            <div className={styles.button}>
              <Button
                startIcon={<Logout />}
                color="primary"
                variant="contained"
                size="small"
                onClick={logout}
              >
                Выйти
              </Button>
            </div>
          </>
        ) : (
          <Button
            startIcon={<Login />}
            color="primary"
            variant="contained"
            size="small"
            onClick={login}
          >
            Войти
          </Button>
        )}
      </div>
    </div>
  );
};
