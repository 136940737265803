import { Checkbox } from '@mui/material';
import { BlankCheckBoxIcon } from '@icons/BlankCheckBoxIcon';
import { CheckBoxIcon } from '@icons/CheckBoxIcon';

export const CheckBox = (props) => {
  return (
    <Checkbox
      {...props}
      disableRipple
      checkedIcon={<CheckBoxIcon fontSize="small" color="primary" />}
      icon={<BlankCheckBoxIcon fontSize="small" color="disabled" />}
    />
  );
};
