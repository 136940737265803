import { useContext, useEffect, useState } from 'react';
import styles from './GoogleCalendar.module.scss';
import { api } from '@lib/calendar';
import { api as axios } from '@lib/api';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleUserContext } from '@context/GoogleUserContext';
import { updateOptions } from '../../../../auth/store/auth';
import { syncEvents } from '../../../store/calendar';
import moment from 'moment/moment';
import { SimpleTable } from '@ui/SimpleTable/SimpleTable';

export const GoogleCalendar = ({ onClose }) => {
  const currentCalendarUid = useSelector(
    (state) => state.auth.options.calendarUid
  );
  const [events, setEvents] = useState([]);
  const [calendarUid, setCalendarUid] = useState(currentCalendarUid || -1);
  const [calendars, setCalendars] = useState([
    { id: '-1', summary: 'Календарь' },
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const { user, login, logout, credentials } = useContext(GoogleUserContext);
  const dispatch = useDispatch();

  const getDates = (event) => {
    const start = moment(event.start).format('DD.MM.YYYY');
    const finish = moment(event.finish).format('DD.MM.YYYY');
    if (start === finish) return start;
    return `${start} – ${finish}`;
  };

  const eventsHeaders = ['Название', 'Дата'];
  const eventsRows = events.map((event) => [event.title, getDates(event)]);

  const fetchCalendars = async () => {
    const calendars = [{ id: 'TEST', summary: 'Мой календарь' }];
    setCalendars([{ id: '-1', summary: 'Календарь' }, ...calendars]);
    setIsLoading(false);
  };

  const updateUid = () => {
    dispatch(
      updateOptions({
        calendarUid,
      })
    );
    onClose();
  };

  const fetchEvents = async () => {
    setIsLoading(true);
    // const response = await axios.get('/dashboard/calendar/sync-preview', {
    //   params: {
    //     token: credentials.access_token,
    //     uid: calendarUid,
    //   },
    // });
    const events = [
      {
        title: 'Бизнес-завтрак "Создание эффективной команды"',
        start: moment('2024-11-08'),
      },
      {
        title: 'Круглый стол "Инновации и будущее бизнеса""',
        start: moment('2024-05-22'),
      },
      {
        title: 'Мастер-класс "Финансовая стратегия для бизнес-лидеров"',
        start: moment('2024-04-14'),
      },
      {
        title: 'Встреча "Опыт успешных стартапов"',
        start: moment('2024-03-30'),
      },
      {
        title: 'Встреча с резидентом клуба: Илон Маск',
        start: moment('2024-02-12'),
      },
      {
        title: 'Семинар "Стратегии роста в цифровую эпоху"',
        start: moment('2024-02-12'),
      },
    ];
    setEvents(events);
    setIsLoading(false);
  };

  useEffect(() => {
    if (!user) return;
    fetchCalendars();
  }, [user]);

  useEffect(() => {
    if (!currentCalendarUid || currentCalendarUid === -1 || !credentials)
      return;
    dispatch(syncEvents(credentials.access_token));
  }, [currentCalendarUid]);

  useEffect(() => {
    fetchEvents();
  }, [calendarUid]);

  if (isLoading) {
    return (
      <Box sx={{ textAlign: 'center', width: '100%' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div className={styles.calendar}>
      <div className={styles.header}>Календарь</div>
      <div className={styles.input}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={calendarUid}
            // label="Age"
            onChange={(ev) => setCalendarUid(ev.target.value)}
            // size="small"
          >
            {calendars.map((calendar) => (
              <MenuItem key={calendar.id} value={calendar.id}>
                {calendar.summary}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className={styles.list}>
        <SimpleTable headers={eventsHeaders} rows={eventsRows} />
      </div>
      <div className={styles.footer}>
        {!!eventsRows.length && (
          <Button variant="contained" color="primary" onClick={updateUid}>
            Импортировать
          </Button>
        )}
      </div>
    </div>
  );
};
