import AsyncSelect from 'react-select/async';
import { api } from '@lib/api';
import { selectStyles } from '@ui/select-styles';

import { useCallback, useEffect, useState } from 'react';
import { TextInput } from '../TextInput/TextInput';

export const SelectCity = ({ value, onChange }) => {
  const [loading, setLoading] = useState(false);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const fetchCities = async (search) => {
    setLoading(true);
    const response = await api.get(`/profile/places/cities/?q=${search}`);
    const options = response.data.cities.map((val) => ({
      label: val.split('|')[0],
      value: val.split('|')[0],
    }));
    setLoading(false);
    return options;
  };

  const loadDefaultOptions = useCallback(async () => {
    const options = await fetchCities('');
    setDefaultOptions(options);
  }, []);

  useEffect(() => {
    loadDefaultOptions();
  }, [loadDefaultOptions]);

  const selectProps = {
    placeholder: 'Город',
    isClearable: false,
    isSearchable: true,
    styles: selectStyles,
    cacheOptions: true,
    defaultOptions: defaultOptions,
    loadOptions: fetchCities,
    isLoading: loading,
    onChange: onChange,
    name: 'city',
    value: value,
    isMulti: false,
    className: 'react-select react-select--capital',
    classNamePrefix: 'react-select react-select--capital',
  };

  return (
    <AsyncSelect
      menuPlacement="auto"
      {...selectProps}
      elements={{ Input: TextInput }}
    />
  );
};
