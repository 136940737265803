import { flatten } from 'lodash';
import { useSelector } from 'react-redux';

export const useItemColor = (type) => {
  const sections = useSelector((state) => state.common[type]);

  return (title) => {
    let color = null;
    sections.forEach((section) => {
      if (!section || !section.items) return;
      section.items.forEach((item) => {
        if (title.toLowerCase().trim() === item.title.toLowerCase().trim()) {
          color = section.color;
        }
      });
    });
    return color;
  };
};
