import { useSelector } from 'react-redux';
import { TreeSelector } from '../TreeSelector/TreeSelector';
import styles from './Competences.module.scss';
import { useContext } from 'react';
import { SurveyContext } from '../../context/SurveyContext';
import { Field } from '@ui/Field/Field';
import { AppSelect } from '@ui/AppSelect/AppSelect';
import { useGroupItems } from '../../../../hooks/useGroupSpheres';
import { flatten } from 'lodash';
import { ar } from 'date-fns/locale';

export const Competences = () => {
  const { competences } = useSelector((state) => state.common);
  const { data, update } = useContext(SurveyContext);
  const { regular, other } = useGroupItems(
    flatten(
      competences.map((section) => section.items.map((item) => item.title))
    ).filter((item) => !!item),
    data.competences?.filter((item) => !!item) || []
  );

  const checked = data?.competences || [];

  const [regularOptions, otherOptions] = [regular, other].map((items) =>
    items.map((item) => ({ label: item, value: item }))
  );

  const updateCompetences = (items, extra) => {
    update('competences', [...items, ...extra]);
  };

  return (
    <div className={styles.section}>
      <div className="bubble">
        Для достижения вашего уровня, требуется обладать незаурядными знаниями,
        умениями и навыками. Было бы интересно узнать о том, что вы сами бы
        обозначили, как ваши основные компетенции?
      </div>
      <TreeSelector
        sections={competences}
        type="competences"
        checked={checked}
        onChange={(items) => updateCompetences(items, other)}
      />
      <br />
      <Field label="Другие компетенции">
        <AppSelect
          isMulti
          isCreatable
          placeholder="Введите название другой компетенции"
          formatCreateLabel={(val) => `Добавить компетенцию «${val}»`}
          noOptionsMessage="Введите название компетенции"
          value={otherOptions}
          isClearable={true}
          onChange={(items) =>
            updateCompetences(
              items.map((item) => item.value),
              regular
            )
          }
        />
      </Field>
    </div>
  );
};
