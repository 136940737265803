import { Button } from '@mui/material';

import styles from './ImportPlaceholder.module.scss';
import { useState } from 'react';
import { ImportModal } from '../ImportModal/ImportModal';

export const ImportPlaceholder = () => {
  const [showImport, setShowImport] = useState(false);

  return (
    <div className={styles.placeholder}>
      <div className={styles.header}>
        Этот раздел для управления календарем мероприятий вашего сообщества
      </div>
      <div className={styles.content}>
        <p>
          Чтобы начать работу с событиями, импортируйте Google-календарь
          сообщества. Как это сделать можно{' '}
          <a href="" target="_blank">
            посмотреть здесь
          </a>
          .
        </p>
      </div>
      <div className={styles.button}>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setShowImport(true)}
        >
          Перейти к импорту
        </Button>
      </div>
      {showImport && <ImportModal onClose={() => setShowImport(false)} />}
    </div>
  );
};
