class GoogleCalendarApi {
  constructor() {}

  async calendars() {
    const response = await window.gapi.client.calendar.calendarList.list();
    return response.result.items;
  }

  async events(calendarId) {
    const response = await window.gapi.client.calendar.events.list({
      calendarId: calendarId,
      maxResults: 2500,
    });
    return response.result.items;
  }

  async getEventsForUpdate(calendarId) {
    const items = await this.events(calendarId);
    return items.map((item) => {
      const event = {
        created: item.created,
        description: item.description,
        start: item.start?.dateTime || item?.start?.date,
        finish: item.end?.dateTime || item?.end?.date,
        htmlLink: item.htmlLink,
        eventType: item.eventType,
        iCalUID: item.iCalUID,
        uid: item.id,
        location: item.location,
        organizer: item.organizer,
        recurringEventId: item.recurringEventId,
        reminders: item.reminders,
        title: item.summary,
        updated: item.updated,
        visibility: item.visibility,
      };
      return event;
    });
  }
}

export const api = new GoogleCalendarApi();
