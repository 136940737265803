import { ReportProblem } from '@mui/icons-material';
import { ConfirmModal } from '@ui/ConfirmModal/ConfirmModal';
import styles from './RemoveConfirmation.module.scss';

export const RemoveConfirmation = ({ type, ...props }) => {
  const title = {
    spheres: 'Удаление сфер деятельности',
    competences: 'Удаление компетенций',
    lobbies: 'Удаление лобби-интересов',
  }[type];
  const label = {
    spheres: 'Сферы деятельности',
    competences: 'Компетенции',
    lobbies: 'Лобби-интересы',
  }[type];
  return (
    <ConfirmModal title={title} {...props}>
      <div className={styles.remove}>
        <div className={styles.icon}>
          <ReportProblem />
        </div>
        <div className={styles.description}>
          {label} останутся в карточке пользователя на платформе и перенесутся в
          раздел «Другое».
        </div>
      </div>
    </ConfirmModal>
  );
};
