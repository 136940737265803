import styles from './ResidentsTable.module.scss';

export const ResidentsTable = ({ cards, onClick }) => {
  const getActualRequest = (card) => {
    const val = card.actualRequest;
    if (val.length >= 50) {
      return val.slice(0, 50) + '...';
    }
    return val;
  };

  return (
    <div className={styles.table}>
      <table>
        <thead>
          <tr>
            <th>Имя Фамилия</th>
            <th>Почта</th>
            <th>Телефон</th>
            <th>Телеграм</th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card) => (
            <tr onClick={() => onClick(card)} key={card.id}>
              <td>
                <div
                  className={styles.name}
                  data-user={card.hasUser ? 'user' : ''}
                >
                  {card.firstName} {card.lastName}
                </div>
                <div className={styles.actualRequest}>
                  <strong>Актуальный запрос:</strong>{' '}
                  <span>{getActualRequest(card)}</span>
                </div>
              </td>
              <td className={styles.email}>{card.email}</td>
              <td className={styles.phone}>{card.phone}</td>
              <td>
                {card.telegram && (
                  <a
                    className={styles.telegram}
                    target="_blank"
                    href={`https://t.me/${card.telegram}`} rel="noreferrer"
                  >{`https://t.me/${card.telegram}`}</a>
                )}
              </td>
            </tr>
          ))}
          {!cards.length && (
            <tr>
              <td colSpan={2}>Ни одной карточки не найдено.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};
