import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import styles from './ConfirmModal.module.scss';
import { AppButton } from '../AppButton/AppButton';

export const ConfirmModal = ({
  title = 'Подтверждение операции',
  description = '',
  onDecline,
  onAccept,
  children,
}) => {
  return (
    <Dialog
      open={true}
      onClose={onDecline}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent className={styles.modal}>
        {description}
        {children}
      </DialogContent>
      <DialogActions>
        <AppButton onClick={onDecline} color="secondary" variant="contained">
          Отмена
        </AppButton>
        <AppButton
          onClick={onAccept}
          autoFocus
          color="primary"
          variant="contained"
        >
          Подтвердить
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};
