import styles from './EventPayment.module.scss';

export const EventPayment = () => {
  return (
    <div className={styles.event}>
      <div className={styles.placeholder}>
        Здесь будет отображаться статистика оплаты события.
      </div>
    </div>
  );
};
