import { intersection } from 'lodash';
import styles from './TreeSelector.module.scss';
import { useState } from 'react';
import { CheckItems } from '@ui/CheckItems/CheckItems';

export const TreeSelector = ({ sections, type, checked, onChange }) => {
  const [items, setItems] = useState([]);
  const [selectedSection, setSelectedSection] = useState(null);

  const getCount = (section) => {
    return intersection(
      section.items.map((v) => v?.title?.toLowerCase()),
      checked.map((val) => val?.toLowerCase())
    ).length;
  };

  const getItems = () => {
    if (!selectedSection) return [];
    return selectedSection.items
      .map((item) => item.title)
      .map((val) => ({ label: val, value: val }));
  };

  return (
    <div className={styles.tree}>
      <div className={styles.sections}>
        {sections.map((section) => (
          <div
            key={section.id}
            className={styles.section}
            data-active={section === selectedSection ? 'active' : ''}
            onClick={() => setSelectedSection(section)}
          >
            <div
              className={styles.color}
              style={{ background: section.color }}
            ></div>
            <div className={styles.title}>{section.title}</div>
            <div className={styles.count}>{getCount(section)} выбрано</div>
          </div>
        ))}
      </div>
      <div className={styles.items}>
        {!!selectedSection ? (
          <CheckItems
            isBlock={true}
            options={getItems()}
            checked={checked}
            onChange={(items) => onChange(items)}
          />
        ) : (
          <div className={styles.empty}>Выберите секцию слева.</div>
        )}
      </div>
    </div>
  );
};
