import { range } from 'lodash';
import { AppSelect } from '../AppSelect/AppSelect';
import { useEffect, useState } from 'react';

const YEARS = range(1950, new Date().getFullYear()).reverse();
const OPTIONS = YEARS.map((year) => ({ label: year, value: year }));

export const YearSelect = ({ value, onChange, disabled }) => {
  const [selected, setSelected] = useState({ label: value, value: value });

  useEffect(() => {
    onChange && onChange(selected.value);
  }, [selected]);

  return (
    <AppSelect
      options={OPTIONS}
      value={selected}
      onChange={setSelected}
      placeholder="Выберите год"
      isDisabled={disabled}
    />
  );
};
