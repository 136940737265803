import { HourglassBottom } from '@mui/icons-material';
import styles from './ComingSoon.module.scss';

export const ComingSoon = () => {
  return (
    <div className={styles.soon}>
      <div className={styles.icon}>
        <HourglassBottom />
      </div>
      <div className={styles.description}>
        Информация появится после регистрации
        <br />
        резидента на платформе.
      </div>
    </div>
  );
};
