import { useContext, useState } from 'react';
import styles from './OtherResource.module.scss';
import { ResidentContext } from '../../../../context/ResidentContext';
import { ResourceItem } from '@ui/ResourceItem/ResourceItem';
import { Delete } from '@mui/icons-material';
import { ToolbarButton } from '@ui/ToolbarButton/ToolbarButton';
import { AddMore } from '../../..//AddMore/AddMore';

export const OtherResource = ({ type }) => {
  const { data, update, canAddResource } = useContext(ResidentContext);
  const [checked, setChecked] = useState([]);

  const items = (data?.resources || {})[type]?.other;

  const isFull = !canAddResource(type, []);

  const toggleChecked = (item) => {
    setChecked((prev) => {
      const items = [...prev];
      const index = items.indexOf(item);
      if (index === -1) {
        items.push(item);
      } else {
        items.splice(index, 1);
      }
      return items;
    });
  };

  const removeItem = (title) => {
    update(
      `resources.${type}.other`,
      items.filter((item) => item !== title)
    );
  };

  const itemChanged = (item, newTitle) => {
    const newItems = [...items];
    const index = newItems.indexOf(item);
    if (index === -1) return;
    newItems.splice(index, 1, newTitle);
    update(`resources.${type}.other`, newItems);
  };

  const addItem = (title) => {
    title = title[0].toUpperCase() + (title.slice(1) || '');
    update(`resources.${type}.other`, [...items, title]);
  };

  const removeChecked = () => {
    update(
      `resources.${type}.other`,
      items.filter((item) => !checked.includes(item))
    );
    setChecked([]);
  };

  return (
    <div className={styles.other}>
      {!!checked.length && (
        <div className={styles.remove}>
          <ToolbarButton icon={<Delete />} onClick={removeChecked}>
            Удалить
          </ToolbarButton>
        </div>
      )}
      <div className={styles.items}>
        {items.map((item) => (
          <ResourceItem
            key={item}
            checked={checked.includes(item)}
            onCheck={() => toggleChecked(item)}
            title={item}
            onUpdate={(newTitle) => itemChanged(item, newTitle)}
            onRemove={() => removeItem(item)}
          />
        ))}
      </div>
      {!isFull && (
        <div className={styles.footer}>
          <AddMore
            onAdd={addItem}
            validate={(title) => {
              if (
                !title ||
                items
                  .map((item) => item.toLowerCase())
                  .includes(title.toLowerCase())
              )
                return false;
              return /^[А-ЯЁа-яёA-z\d\s\-()'"]{2,80}$/.test(title);
            }}
          />
        </div>
      )}
    </div>
  );
};
