import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './SuccessModal.module.scss';
import { Close } from '@mui/icons-material';
import { AppButton } from '@ui/AppButton/AppButton';

export const SuccessModal = ({
  title = 'Успешно!',
  buttonTitle = 'Хорошо',
  description,
  onClose,
  footer = null,
}) => {
  return (
    <Dialog open={true} onClose={onClose} className="wide-dialog">
      <DialogTitle>
        {title}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '400px', overflowX: 'hidden' }}>
        <div className={styles.success}>{description}</div>
      </DialogContent>
      <DialogActions className={styles.footer}>
        {footer || (
          <AppButton color="primary" onClick={onClose}>
            {buttonTitle}
          </AppButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
