import { SurveyNavItem } from '../SurveyNavItem/SurveyNavItem';
import styles from './SurveyNav.module.scss';
import { useEffect, useState } from 'react';

export const SurveyNav = ({ onChange, tabs }) => {
  const [name, setName] = useState('profile');

  useEffect(() => {
    onChange && onChange(name);
  }, [name]);

  return (
    <div className={styles.nav}>
      {tabs.map((tab, index) => (
        <SurveyNavItem
          key={tab.name}
          icon={tab.icon}
          isActive={name === tab.name}
          onClick={() => setName(tab.name)}
          index={index + 1}
        >
          {tab.title}
        </SurveyNavItem>
      ))}
    </div>
  );
};
