import styles from './ToolbarButton.module.scss';

export const ToolbarButton = ({ icon, children, ...props }) => {
  return (
    <button className={styles.button} {...props}>
      {icon && <div className={styles.icon}>{icon}</div>}
      <div className={styles.title}>{children}</div>
    </button>
  );
};
