import styles from './SectionHeader.module.scss';

export const SectionHeader = ({ title, description, smallPadding }) => {
  return (
    <div className={styles.header} data-small={smallPadding ? 'small' : ''}>
      <div className={styles.title}>{title}</div>
      <div className={styles.description}>
        <p>{description}</p>
      </div>
    </div>
  );
};
