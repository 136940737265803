import { useState } from 'react';
import { AppSelect } from '@ui/AppSelect/AppSelect';
import { TextInput } from '@ui/TextInput/TextInput';
import styles from './AddKarmaForm.module.scss';
import { Field } from '@ui/Field/Field';
import { FormControl } from '@mui/material';
import { AppButton } from '@ui/AppButton/AppButton';

const AMOUNT_VALUES = [20, 50, 100, 200, 500];

const AMOUNT_OPTIONS = AMOUNT_VALUES.map((val) => ({ label: val, value: val }));

export const AddKarmaForm = ({ onSubmit }) => {
  const [description, setDescription] = useState('');
  const [amountItem, setAmountItem] = useState(null);

  const isButtonDisabled = !amountItem || !description;

  const amountChanged = (item) => {
    setAmountItem(item);
  };

  const formSubmitted = (ev) => {
    ev.preventDefault();
    onSubmit({ amount: amountItem.value, description });
    setAmountItem(null);
    setDescription('');
  };

  return (
    <div className={styles.form}>
      <div className={styles.header}>Начислить карму</div>
      <form onSubmit={formSubmitted}>
        <div className={styles.fields}>
          <Field label="Количество">
            <AppSelect
              options={AMOUNT_OPTIONS}
              placeholder="Количество баллов кармы"
              onChange={amountChanged}
              value={amountItem}
            />
          </Field>
          <Field label="Комментарий">
            <FormControl fullWidth>
              <TextInput
                value={description}
                onChange={(ev) => setDescription(ev.target.value)}
              />
            </FormControl>
            <div className={styles.note}>
              Добавьте информацию о том, за что вы начисляете карму.
              Пользователь увидит это в своей истории баланса
            </div>
          </Field>
        </div>
        <div className={styles.footer}>
          <AppButton
            color="primary"
            variant="contained"
            disabled={isButtonDisabled}
            type="submit"
          >
            Начислить
          </AppButton>
        </div>
      </form>
    </div>
  );
};
