import { Check, Delete, Edit, FastRewind, MoveUp } from '@mui/icons-material';
import styles from './ResourceItem.module.scss';
import { CheckBox } from '@ui/CheckBox/CheckBox';
import { Chip, FormControl, IconButton, Tooltip } from '@mui/material';
import { useState } from 'react';
import { TextInput } from '../TextInput/TextInput';
import { AppChip } from '../AppChip/AppChip';

export const ResourceItem = ({
  checked,
  onCheck,
  title,
  onUpdate,
  onRemove,
  isNew,
  onMove,
  count,
  isPadded,
  onHover,
  validate,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [editTitle, setEditTitle] = useState(title);
  const [value, setValue] = useState(title);

  const finishEdit = () => {
    if (isError) return;
    setValue(editTitle);
    onUpdate(editTitle);
    setIsEdit(false);
  };

  const keyPressed = (ev) => {
    if (ev.which !== 13) return;
    finishEdit();
  };

  const isError = validate && !validate(editTitle);

  return (
    <div
      className={styles.item}
      data-checkbox={onCheck ? 'checkbox' : ''}
      data-padded={isPadded ? 'padded' : ''}
      onMouseOver={onHover}
      data-new={isNew ? 'new' : ''}
    >
      {onCheck && (
        <div className={styles.checkbox}>
          <CheckBox value={checked} onChange={onCheck} />
        </div>
      )}
      {!isEdit && <div className={styles.title}>{value}</div>}
      {isEdit && (
        <FormControl fullWidth>
          <TextInput
            size="small"
            value={editTitle}
            onChange={(ev) => setEditTitle(ev.target.value)}
            isFocus
            onKeyPress={keyPressed}
            error={isError}
          />
        </FormControl>
      )}
      {isNew && (
        <div className={styles.new}>
          <AppChip title="Новое" color="success" />
        </div>
      )}
      <div className={styles.buttons}>
        {!isEdit && (
          <>
            {onUpdate && (
              <Tooltip title="Редактировать">
                <IconButton size="small" onClick={() => setIsEdit(true)}>
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {onMove && (
              <Tooltip title="Переместить в справочник">
                <IconButton size="small" onClick={onMove}>
                  <MoveUp color="primary" />
                </IconButton>
              </Tooltip>
            )}
            {onRemove && (
              <Tooltip title="Удалить">
                <IconButton size="small" onClick={onRemove}>
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
            )}
          </>
        )}
        {isEdit && (
          <IconButton size="small" onClick={() => finishEdit(true)}>
            <Check />
          </IconButton>
        )}
      </div>
      {typeof count === 'number' && !isEdit && (
        <div className={styles.count}>{count}</div>
      )}
    </div>
  );
};
