import { Checkbox } from '@mui/material';
import styles from './CheckItem.module.scss';

export const CheckItem = ({ title, checked, onCheck, isBlock }) => {
  return (
    <div
      className={styles.item}
      onClick={onCheck}
      data-block={isBlock ? 'block' : ''}
    >
      <div className={styles.checkbox}>
        <Checkbox size="small" checked={checked} onClick={onCheck} />
      </div>
      <div className={styles.title}>{title}</div>
    </div>
  );
};
