import styles from './Field.module.scss';

export const Field = ({ label, children }) => {
  return (
    <div className={styles.field}>
      <div className={styles.label}>{label}</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};
