import { Add } from '@mui/icons-material';
import styles from './AddMoreFooter.module.scss';

export const AddMoreFooter = ({
  label = 'Добавить еще',
  Icon = Add,
  onClick,
}) => {
  return (
    <div className={styles.add} onClick={onClick}>
      <strong>{label}</strong>
      <i>
        <Icon />
      </i>
    </div>
  );
};
