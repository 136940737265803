import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import styles from './SuccessModal.module.scss';
import { Close } from '@mui/icons-material';
import { AppButton } from '@ui/AppButton/AppButton';

export const SuccessModal = ({ onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} className="wide-dialog">
      <DialogTitle>
        Успешно!
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Close color="disabled" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: '400px', overflowX: 'hidden' }}>
        <div className={styles.modal}>Бонусы кармы отправлены резиденту.</div>
      </DialogContent>
      <DialogActions className={styles.footer}>
        <AppButton color="primary" onClick={onClose}>
          Хорошо
        </AppButton>
      </DialogActions>
    </Dialog>
  );
};
