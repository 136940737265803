import { flatten, sortBy } from 'lodash';
import { matchRate } from './string';

export function filterByKey(items = [], search = '', key = null) {
  return sortBy(
    items
      .map((item) => ({
        item,
        rate: Math.max(
          ...flatten(
            keyValue(item, key)
              .split(' ')
              .map((word) => word.split('-'))
          ).map((word) => matchRate(word, search))
        ),
      }))
      .filter((result) => result.rate >= 0.2),
    'rate'
  )
    .reverse()
    .map((result) => result.item);
}

export function keyValue(item = '', key = null) {
  return key ? item[key] : item;
}

export function filterStrings(items) {
  return items.map((v) => `${v}`.trim()).filter((v) => !!v);
  // .map((v) => v.toLowerCase());
}
