import { Poll, QuestionAnswer } from '@mui/icons-material';
import { Page } from '../../layouts/Page/Page';
import { Heading } from '../../layouts/Heading/Heading';
import styles from './SurveyItem.module.scss';
import { useParams } from 'react-router-dom';
import { SurveyItem } from '../../features/survey/components/SurveyItem/SurveyItem';

export const SurveyItemPage = () => {
  const { cardId } = useParams();

  return (
    <Page
      breadcrumbs={[
        {
          title: 'Опросник резидентов',
          icon: Poll,
          url: '/survey/',
        },
        {
          title: 'Анкета резидента',
          icon: QuestionAnswer,
        },
      ]}
    >
      <div className={styles.survey}>
        <Heading title={`Анкета резидента`} />
        <SurveyItem cardId={cardId} />
      </div>
    </Page>
  );
};
