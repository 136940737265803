import { useState } from 'react';
import { Section } from './Section/Section';
import styles from './TreeDict.module.scss';
import { Input } from '@ui/Input/Input';
import { Search } from '@mui/icons-material';
import { matchSorter } from 'match-sorter';
import { cloneDeep } from 'lodash';

export const TreeDict = ({ dict, selected, onCheck, type }) => {
  const [search, setSearch] = useState('');
  const emptyLabel = {
    spheres: 'Не найдено ни одной сферы деятельности.',
    competences: 'Не найдено ни одной компетенции.',
    lobbies: 'Не найдено ни одного лобби-интереса.',
  }[type];

  const filterItems = (items) => {
    return matchSorter(items, search, {
      keys: ['title'],
    }).filter((item) => !selected.includes(item.title));
  };

  const sections = dict
    .map((section) => {
      const s = cloneDeep(section);
      s.items = filterItems(section.items);
      return s;
    })
    .filter((section) => !!section.items.length);

  return (
    <div className={styles.tree}>
      <div className={styles.search}>
        <Input
          placeholder="Поиск"
          icon={Search}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
      </div>
      <div className={styles.sections}>
        {sections.map((section) => (
          <Section
            key={section.id}
            title={section.title}
            color={section.color}
            items={section.items}
            onCheck={onCheck}
            isExpanded={!!search.length}
          />
        ))}
        {!sections.length && <div className={styles.empty}>{emptyLabel}</div>}
      </div>
    </div>
  );
};
