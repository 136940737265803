import { useEffect, useState } from 'react';
import { AppSelect } from '../AppSelect/AppSelect';
import { api } from '@lib/api';
import { sortBy } from 'lodash';

export const CountrySelect = ({ value, onChange, error = false, disabled }) => {
  // const [selected, setSelected] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const changed = (item) => {
    if (!item) {
      return onChange(null);
    }
    onChange && onChange(`${item.label}|${item.value}`);
  };

  const fetchDefaultOptions = async () => {
    const options = await fetchOptions('');
    setDefaultOptions(options);
  };

  const fetchOptions = async (q = '') => {
    setIsLoading(true);
    const response = await api.get(`/profile/places/countries/`, {
      params: {
        q,
      },
    });
    setIsLoading(false);
    return sortBy(response.data.countries, 'id').map((val) => ({
      label: val.split('|')[0],
      value: val.split('|')[1],
    }));
  };

  useEffect(() => {
    fetchDefaultOptions();
  }, []);

  return (
    <AppSelect
      isAsync
      value={{ label: value?.split('|')[0], value: value?.split('|')[1] }}
      onChange={changed}
      placeholder={'Выберите страну'}
      defaultOptions={defaultOptions}
      loadOptions={fetchOptions}
      isLoading={isLoading}
      isClearable={true}
      error={error}
      aria-invalid={true}
      isDisabled={disabled}
    />
  );
};
