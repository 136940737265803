import { useContext, useState } from 'react';
import styles from './Resource.module.scss';
import { SelectResourcesModal } from '../../SelectResourcesModal/SelectResourcesModal';
import { Tab, Tabs } from '@mui/material';
import { DictResource } from './DictResource/DictResource';
import { OtherResource } from './OtherResource/OtherResource';
import { Input } from '@ui/Input/Input';
import { Search } from '@mui/icons-material';
import { SectionHeader } from '../SectionHeader/SectionHeader';

export const Resource = ({ type }) => {
  const [tab, setTab] = useState(0);

  return (
    <div className={styles.resource}>
      <SectionHeader title="Ресурсы" description="Данные резидента для сервиса «Ресурсная карта»" smallPadding />
      <div className={styles.tabs}>
        <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)}>
          <Tab label="Справочник сообщества" />
          <Tab label="Добавлено пользователем" />
        </Tabs>
      </div>
      {/* <div className={styles.search}>
        <Input
          placeholder="Поиск"
          icon={Search}
          value={search}
          onChange={(ev) => setSearch(ev.target.value)}
        />
      </div> */}
      <div className={styles.content}>
        {tab === 0 && <DictResource type={type} />}
        {tab === 1 && <OtherResource type={type} />}
      </div>
    </div>
  );
};
