import { useContext, useState } from 'react';
import { SectionHeader } from '../SectionHeader/SectionHeader';
import styles from './Profile.module.scss';
import { Tab, Tabs } from '@mui/material';
import { Personal } from './Personal/Personal';
import { Education } from './Education/Education';
import { Projects } from './Projects/Projects';
import { Job } from './Job/Job';
import { ValidationContext } from '../../../context/ValidationContext';
import { Error } from '@mui/icons-material';

export const Profile = ({ data = {}, onChange }) => {
  const [tab, setTab] = useState(0);
  const { invalidTabs } = useContext(ValidationContext);

  const getErrorProps = (name) => {
    if (!invalidTabs[name]) return {};
    return {
      className: styles.invalidTab,
      icon: <Error fontSize="small" color="error" />,
      iconPosition: 'end',
    };
  };

  return (
    <div className={styles.profile}>
      <SectionHeader
        title="Общее"
        description="Персональная информация резидента, синхронизируется с данными его профиля на платформе"
        smallPadding
      />
      <div className={styles.tabs}>
        <Tabs value={tab} onChange={(ev, newValue) => setTab(newValue)}>
          <Tab label="Личная информация" {...getErrorProps('personal')} />
          <Tab label="Деятельность" {...getErrorProps('jobs')} />
          <Tab label="Образование" {...getErrorProps('educations')} />
          <Tab label="Проекты" {...getErrorProps('projects')} />
        </Tabs>
      </div>

      <div className={styles.content}>
        {tab === 0 && <Personal />}
        {tab === 1 && <Job />}
        {tab === 2 && <Education />}
        {tab === 3 && <Projects />}
      </div>
    </div>
  );
};
