import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import styles from './DropdownButton.module.scss';

export const DropdownButton = ({ icon, title, expanded, onChange }) => {
  const Icon = icon;

  const buttonClicked = () => {
    onChange(!expanded);
  };

  return (
    <div
      className={styles.button}
      onClick={buttonClicked}
      data-expanded={expanded ? 'expanded' : ''}
    >
      {Icon && (
        <div className={styles.icon}>
          <Icon color="primary" />
        </div>
      )}
      <div className={styles.title}>{title}</div>
      <div className={styles.expandedIcon}>
        {expanded ? (
          <KeyboardArrowUp color="primary" />
        ) : (
          <KeyboardArrowDown color="primary" />
        )}
      </div>
    </div>
  );
};
