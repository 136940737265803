export const ResidentAvatar = ({ avatar }) => {
  return (
    <img
      src={
        avatar.startsWith('http')
          ? avatar
          : process.env.REACT_APP_API_URL + 'profile/upload/' + avatar
      }
      alt=""
    />
  );
};
