export const DEFAULT_RESIDENT_DATA = {
  statuses: [],
  common: {
    about: {
      firstName: '',
      lastName: '',
      position: '',
      company: '',
      spheres: [],
      actualRequest: '',
    },
    contacts: {
      phone: '',
      email: '',
      telegram: '',
    },
    personal: {
      bdate: '',
      city: '',
      visiting: [],
      about: '',
    },
    jobs: [],
    educations: [],
    projects: [],
  },
  resources: {
    spheres: {
      regular: [],
      other: [],
    },
    competences: {
      regular: [],
      other: [],
    },
    lobbies: {
      regular: [],
      other: [],
    },
  },
};
